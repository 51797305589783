<div class="row simulator-container" [ngClass]="{'detalle-api': detalle==1}">
  <div class="col-md-8 form-content">
    <div class="simulator">
      <form [formGroup]="form" >
        <div class="input-container" *ngFor="let input of messages?.inputs">
          <label>{{input.title}}</label>
          <div class="input-error">
            <input 
              *ngIf="!input.currency"
              [formControlName]="input.name"   
              [ngClass]="{'bad-border': form.controls[input.name].invalid && form.controls[input.name].touched}"  
              class="input-style" 
              [type]="input.type" 
              >
            <input 
              *ngIf="input.currency"
              [formControlName]="input.name"   
              [ngClass]="{'bad-border': form.controls[input.name].invalid && form.controls[input.name].touched}"  
              class="input-style" 
              [type]="input.type" 
              currencyMask              
              >
          </div>
        </div>
        <div class="simulator-btns">
          <button type="reset" (click)="simulator('clean')" class="white-button left-btn">Borrar</button>
          <button type="submit" (click)="simulator('calcular')" class="green-button rigth-btn">Calcular</button>
        </div>
      </form>
      <div class="simulator-result">
        <div>
          <label>{{messages?.result.title1}}</label>
          <p>$
            <span>{{simulatorResult.totalSale}}</span>
          </p>
        </div>
        <div>
          <label>{{messages?.result.title2}}</label>
          <p>$
            <span>{{simulatorResult.commissionNequi}}</span>
          </p>
        </div>
        <div>
          <label>{{messages?.result.title3}}</label>
          <p>$
            <span>{{simulatorResult.toBancolombia}}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="conditions" *ngIf="detalle!=1">
      <p>{{messages?.comision.item1}}</p>
      <p>{{messages?.comision.item2}}</p>
    </div>
  </div>
</div>