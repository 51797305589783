import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './public/dashboard/dashboard.component';
import { AuthService, JsonProvider } from './services';
import { BotonComponent } from 'src/app/public/boton/boton.component';
import { CobrosComponent } from 'src/app/public/cobros/cobros.component';
import { DetalleApiComponent } from 'src/app/public/boton/detalle-api/detalle-api.component';
import { StickerComponent } from './public/sticker/sticker.component';
import { RegistroComponent } from './public/registro/registro.component';
import { LoginComponent } from './public/login/login.component';
import { ForgetPassComponent } from './public/forget-pass/forget-pass.component';
import { ArmarioComponent } from './public/armario/armario.component';

const routes: Routes = [
  {path: '', component: DashboardComponent},
  {path: 'cobros', component: CobrosComponent},
  {path: 'boton', component: BotonComponent},
  {path: 'qr', component: StickerComponent},
  {path: 'registro/:product', component: RegistroComponent},
  {path: 'boton/detalle-api', component: DetalleApiComponent},
  {path: 'login', component: LoginComponent},
  {path: 'olvidar-contrasena', component: ForgetPassComponent},
  {path: 'armario', component: ArmarioComponent},
  {path: 'privado', loadChildren: './private/private.module#PrivateModule', canActivate: [AuthService]},
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  
exports: [RouterModule]
})
export class AppRoutingModule { 

  constructor(private JsonProvider: JsonProvider ){
  }
  
}
