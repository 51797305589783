import { Component, OnInit } from '@angular/core';
import { JsonProvider, ObservablesService } from '../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-boton',
  templateUrl: './boton.component.html',
  styleUrls: ['./styles/boton.component.scss',
              './styles/boton.media.767.scss',
              './styles/boton.media.990.scss',
              './styles/boton.media.1300.scss']
})
export class BotonComponent implements OnInit {

  public messages: any;

  private infoValidationRegister = {
    nequi: 1,
    ahorroApi: 1,

    otp: 0,
    countOtp: 0,

    otpReport: 0,
    countOtpReport: 0,

    otpEmail: 0,
    countOtpEmail: 0,

    otpNequi: 0,
    countOtpNequi: 0,
    tryOtpNequi: 0,

    bloqueo: 0,
    list: 0,
    comercio: {
      list: 0,
      name: 0
    },
    representante: {
      ccValid: 1,
      list: 0,
      dateEx: 1
    },
    cuenta: {
      valid: 0
    },

    location: {
      department: [],
      city: []
    },

    flow: []
  }

  constructor(private jsonProvider: JsonProvider, private router: Router, private observablesService: ObservablesService) { 
    this.jsonProvider.getMessages('boton').subscribe(data => {
      this.messages = data
      window.location.href = this.messages.redirect;
    });
  }

  ngOnInit() {
  }

  routerOption(url) {
    this.observablesService.setLocalStorage('infoRegister', []);
    this.observablesService.setLocalStorage('infoValidate', this.infoValidationRegister)
    this.router.navigate([url]);
  }
 

}
