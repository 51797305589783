import { Component, OnInit } from '@angular/core';
import { JsonProvider, GoogleAnalyticsService } from '../../../../services';
import { Router } from '@angular/router';
@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./styles/options.component.scss']
})
export class OptionsComponent implements OnInit {

  public items: Array<object> = [];

  constructor(private jsonProvider: JsonProvider, 
              private router: Router,
              private googleAnalyticsService: GoogleAnalyticsService) { 
    this.jsonProvider.getMessages('optionsBoton').subscribe(data => this.items = data);

  }

  ngOnInit() {
  }

  routerDetailApi(type) {
    localStorage.setItem("apiName", type);
    this.router.navigate(['boton/detalle-api']);
  }

  eventClick(text){
    let action = 'Clic documentacion' + text.toLowerCase();
    this.googleAnalyticsService.emitEvent('Boton Nequi', action);
  }

}
