import { Component, OnInit, Input } from '@angular/core';
import { JsonProvider, GoogleAnalyticsService, ObservablesService } from '../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banner-landings',
  templateUrl: './banner-landings.component.html',
  styleUrls: [
    './styles/banner-landings.component.scss',
    './styles/banner-landings.media.990.scss',
    './styles/banner-landings.media.767.scss',
    './styles/banner-landings.media.576.scss'
]
})
export class BannerLandingsComponent implements OnInit {

  @Input() public product: any;
  public messages: any;

  private infoValidationRegister = {
    nequi: 1,
    ahorroApi: 1,

    otp: 0,
    countOtp: 0,

    otpReport: 0,
    countOtpReport: 0,

    otpEmail: 0,
    countOtpEmail: 0,

    otpNequi: 0,
    countOtpNequi: 0,
    tryOtpNequi: 0,

    bloqueo: 0,
    list: 0,
    comercio: {
      list: 0,
      name: 0
    },
    representante: {
      ccValid: 1,
      list: 0,
      dateEx: 1
    },
    cuenta: {
      valid: 0
    },

    location: {
      department: [],
      city: []
    },

    flow: []
  }

  constructor(private JsonProvider: JsonProvider,
              private router: Router,
              private googleAnalyticsService: GoogleAnalyticsService,
              private observablesService: ObservablesService) {
    this.JsonProvider.getMessages('banner-landings').subscribe((data) => { this.messages = data[this.product] });
  }

  ngOnInit() {
    
  }

  scroll(target) {
    if(target.src.indexOf('/') == -1){
      document.getElementById(target.src).scrollIntoView({behavior:"smooth", block: "start"});
    } else {
      this.router.navigate([target.src]);
    }

    if(target.title != 'Qué tengo que hacer' && this.product != 'api'){
      this.eventClick(target.title);
    }else{
      this.eventClick(target.title);
    }
  }

  eventRegister(text){
    this.eventClick(text.title);
    this.observablesService.setLocalStorage('infoRegister', []);
    this.observablesService.setLocalStorage('infoValidate', this.infoValidationRegister)
    this.router.navigate([text.src]);
  }

  eventClick(text){
    let action = 'Clic ' + text.toLowerCase(); 
    let product;
    let label;
    if(this.product == 'qr'){
      product = "Sticker";
      label = "Sticker Nequi - Cobros Nequi - Botón Nequi";
    }else if(this.product == 'api'){
      product = "Boton";
      label = "";
    }else if (this.product == 'cobros'){
      product = "Cobros";
      label = "Sticker Nequi - Cobros Nequi - Botón Nequi";
    }
    this.googleAnalyticsService.emitEvent(product + ' Nequi', action, label)
  }

}
