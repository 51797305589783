<section>
  <app-banner-landings product="cobros"></app-banner-landings>

  <div id="section-steps" class="contain-icon-info" >
      <app-iconos-info product="cobros"></app-iconos-info>
  </div>

  <div id="section-much" class="contain-carousel">
      <app-carousel-cobros></app-carousel-cobros>
  </div>
  <div id="section-simulator" class="contain-simulator">
    <div class="simulator-title">¿Cuánto me cuesta?</div>
    <app-simulator techo="2900" tarifa="1.5"></app-simulator>
  </div>
</section>
