import { Component, OnInit, ViewChild } from '@angular/core';
import { OwlCarousel } from 'ngx-owl-carousel';
import { JsonProvider, clevertapService } from '../../../../services';

@Component({
  selector: 'app-shapes',
  templateUrl: './shapes.component.html',
  styleUrls: [
              './styles/shapes.component.scss',
              './styles/shapes.media.576.scss'
            ]
})
export class ShapesComponent implements OnInit {

  public items: any;

  public options = {items: 3, dots: true, navigation: true};

  constructor(private jsonProvider: JsonProvider, private clevertapService: clevertapService) {
    this.jsonProvider.getMessages('slideShapes').subscribe((data) => {this.items = data; });
    const mq = window.matchMedia( "(max-width: 700px)" );
    const mq5 = window.matchMedia( "(max-width: 500px)" );

    if (mq.matches && !mq5.matches ) {
      this.options.items = 2;
    }

    if(mq5.matches) {
      this.options.items = 1;
    }
  }

  ngOnInit() {
  }

  sendEvent(name, properties) {
   this.clevertapService.sendEvent(name,properties);
  }

  @ViewChild('owlElement') owlElement: OwlCarousel;

    fun() {
      this.owlElement.next([200]);
      //duration 200ms
    }

}
