import { Injectable } from '@angular/core';
import { NullInjector } from '@angular/core/src/di/injector';
import * as clevertap from 'clevertap-web-sdk'
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class clevertapService {
  constructor() {
    clevertap.privacy.push({optOut: false}) // Set the flag to true, if the user of the device opts out of sharing their data
    clevertap.privacy.push({useIP: false})  // Set the flag to true, if the user agrees to share their IP data
    clevertap.init(environment.clevertapId,'us1')
  }

  public sendEvent(name, properties): any {
    if(!!properties){
      clevertap.event.push(name, properties);
    } else {
      clevertap.event.push(name);
    }
  }
}
