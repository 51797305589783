import { Component, OnInit, SimpleChanges } from '@angular/core';
import { UtilsService, JsonProvider } from '../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sticker',
  templateUrl: './sticker.component.html',
  styleUrls: ['./styles/sticker.component.scss']
})
export class StickerComponent implements OnInit {

  public messages;

  constructor(private jsonProvider: JsonProvider, private router: Router) {
    this.jsonProvider.getMessages('qr').subscribe(data => {
      this.messages = data
      window.location.href = this.messages.redirect
    });
  }

  ngOnInit() {
  }

}
