<section *ngIf="items">
  <div class="content-table">

    <div class="content-sub">
      <div class="title">
        <b>sub</b>
      </div>
      <div class="content-options">
        <div class="option"  [ngClass]="{'end': iOptionSub+1 === items[0].options.length}" *ngFor="let option of items[0].options;let iOptionSub = index">
          <b [innerHtml]="option?.body"> </b>
          <hr>
        </div>
      </div>
    </div>

    <div class="contain-toggle  contain-toggle-{{i}}" *ngFor="let item of items; let i = index">
      <div *ngIf="i > 0">
        <div class="title" (click)="toggle(i)">
          <b>{{item.title}}</b>
        </div>

        <div class="content-options" [ngClass]="{'options-active': optionActive === i }">

          <div class="option"               
               *ngFor="let option of item.options; let iOption = index"
                [ngClass]="{'end': iOption+1 === item.options.length, 'successMobile': !option.success}" >

            
            <p class="textMobile" [innerHtml]="option.body"> </p>
            <div class="help toast" *ngIf="option.help" (click)="toast = !toast"><img alt="" src="assets/img/iconHelp.png"></div>
            <div *ngIf="option.success != 'not'" >
                <img *ngIf="option.success" src="assets/img/dashboard/positivo.png" alt="">
                <img *ngIf="!option.success" src="assets/img/dashboard/negativo.png" alt="">
            </div>
            <hr>
          </div>
          <div *ngFor="let option of item.options" [ngClass]="{'toast-container': option.help}">
            <div class="toast-msg" *ngIf="option.help && !toast">
              {{option.help}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>