<section>
  <app-banner-landings id="banner-landing" product="api"></app-banner-landings>

  <div id="steps" class="contain-icon-info">
    <app-iconos-info product="boton"></app-iconos-info>
  </div>

  <div id="own-site" class="contain-options">
    <div>
      <div class="contain-text container">
        <h1 class="font-bold">{{messages?.sectionOptions.title}}</h1>
        <p>{{messages?.sectionOptions.body}}</p>
      </div>
      <div class="container">
        <app-options></app-options>
      </div>
    </div>
  </div>

  <div id="other-site" class="contain-options contain-like">
    <div>
      <div class="contain-text container">
        <h1 class="font-bold">{{messages?.sectionSite.title}}</h1>
        <p>{{messages?.sectionSite.body}}</p>
      </div>
      <div class="container container-sites" padding >
        <div *ngFor="let site of messages?.sites">
          <a (click)="routerOption(site.src)" >
            <img src="{{site.img}}" alt="" class="{{site.class}}">
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- {
      "img": "assets/img/boton/rotalo.png",
      "src": "registro/rotalo",
      "class": "rotalo"
  }, -->
  
  <!-- <div class="contain-options contain-case">
    <div class="contain-text">
      <h1 class="font-bold">{{messages?.sectionCaseSuccess.title}}</h1>
    </div>
    <div class="content-body-case" >
      <div class="contain-body">       
        <div class="text">
          <div class="body">
            <h2>{{messages?.sectionCaseSuccess.subTitle}}</h2>
            <p>{{messages?.sectionCaseSuccess.body}}</p>
          </div>
        </div>
        <div class="img">
          <img src="{{messages?.sectionCaseSuccess.img}}" alt="">
        </div>
      </div>
    </div>
  </div> -->

</section>