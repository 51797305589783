import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { JsonProvider } from '../../services';
import { SimulatorConfig } from './simulator.config';

@Component({
  selector: 'app-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: [
    './styles/simulator.component.scss',
    './styles/simulator.media.990.scss',
    './styles/simulator.media.767.scss',
    './styles/simulator.media.576.scss',
  ]
})

export class SimulatorComponent implements OnInit {

  @Input() public techo: number;
  @Input() public tarifa: number;
  @Input() public detalle: number;
  public messages: any;
  public form: FormGroup;
  public simulatorResult = {
    "totalSale": "0",
    "commissionNequi": "0",
    "toBancolombia": "0"
  };


  constructor(private JsonProvider: JsonProvider, private formBuilder: FormBuilder) {
    this.JsonProvider.getMessages('simulator').subscribe((data) => {
      this.messages = data;
      this.messages.comision.item1 = this.messages.comision.item1.replace('{{tarifa}}', this.tarifa);
      this.messages.comision.item1 = this.messages.comision.item1.replace('{{techo}}', ((this.techo).toString().replace(/\d(?=(\d{3})+)/g, '$&.')));
    });
  }


  ngOnInit() {

    this.form = new FormGroup({
      nameCompany: new FormControl(),
      transactions: new FormControl('', Validators.required),
      promTransactions: new FormControl('', Validators.required)
    });
  }

  simulator(fn) {
    if (fn == "clean") {
      this.form.value.transactions = "";
      this.form.value.promTransactions = "";
      this.simulatorResult = {
        "totalSale": "0",
        "commissionNequi": "0",
        "toBancolombia": "0"
      }
    }
    else if (fn == "calcular") {
      if (!this.form.value.transactions) {
        this.simulatorResult = {
          "totalSale": "0",
          "commissionNequi": "0",
          "toBancolombia": "0"
        }
      } else if (!this.form.value.promTransactions) {
        this.simulatorResult = {
          "totalSale": "0",
          "commissionNequi": "0",
          "toBancolombia": "0"
        }
      } else {
        var numTransactions = this.form.value.transactions;
        var promTransactions = this.form.value.promTransactions;
        var techo = Number(this.techo);
        techo = techo + Number((techo * SimulatorConfig.iva).toFixed(4));
        var tarifa = Number(this.tarifa);
        tarifa = tarifa + Number((tarifa * SimulatorConfig.iva).toFixed(4));
        var toBancolombia = 0;
        var total = 0;
        var comision = promTransactions * tarifa / 100;

        if (comision > techo) {
          comision = techo;
        }
        total = numTransactions * promTransactions;
        comision = numTransactions * comision;
        toBancolombia = total - comision;
        this.simulatorResult.totalSale = String(Math.round(total)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.simulatorResult.commissionNequi = String(Math.round(comision)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.simulatorResult.toBancolombia = String(Math.round(toBancolombia)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  }
}
