<div class="banner-landings prod-{{product}}">
  <div class="container-banner-landings container">
    <div class="row">
      <div class="col-md-7">
        <h1>{{messages?.title}}</h1>
        <p [innerHtml]="messages?.body"></p>
        <button *ngIf="messages?.button1" class="blue-button" (click)="eventRegister(messages?.button1)">{{messages?.button1.title}}</button>
        <a *ngIf="messages?.button2" href="{{messages?.button2.src}}">
          <button (click)="eventClick(messages?.button2.title)" class="green-button">{{messages?.button2.title}}</button>
        </a>
      </div>
    </div>
  </div>
  <div class="nav-landings">
    <ul>
      <a *ngFor="let info of messages?.navBarMessages" (click)="scroll(info)">
        <li>{{info.title}}</li>
      </a>
    </ul>
  </div>
</div>