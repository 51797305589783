import { Component, ViewChild } from '@angular/core';
import { JsonProvider, GoogleAnalyticsService } from '../../services';
import { Router } from '@angular/router';
import { Subscription } from "rxjs";
@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [
              './styles/dashboard.component.scss',
              './styles/dashboard.media.768.scss',
              './styles/dashboard.media.576.scss'
            ]
})
export class DashboardComponent {

  public messages: any;
  private subsDashboard: Subscription;

  constructor(private jsonProvider: JsonProvider, 
              private router: Router,
              private googleAnalyticsService: GoogleAnalyticsService){
  }

  ngOnInit() {
    this.getMessagesDashboard()
  }

  ngOnDestroy() {
    this.subsDashboard.unsubscribe();
  }

  eventProfile(){
    this.googleAnalyticsService.emitEvent('Home', 'Clic tu perfil');
  }

  scroll(target) {
    if(target.indexOf('/') == -1){
      document.getElementById(target).scrollIntoView({behavior:"smooth"});
    } else {
      this.router.navigate([target]);
    }
  }

  private async getMessagesDashboard() {
    this.subsDashboard = this.jsonProvider.getMessages('dashboard').subscribe((data) => {
      this.messages = data
      window.location.href = this.messages.redirect;
    });
  }

}
