/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./styles/compare-shapes.component.scss.shim.ngstyle";
import * as i1 from "./styles/compare-shapes.media.690.scss.shim.ngstyle";
import * as i2 from "./styles/compare-shapes.media.576.scss.shim.ngstyle";
import * as i3 from "@angular/core";
import * as i4 from "@angular/common";
import * as i5 from "./compare-shapes.component";
import * as i6 from "../../../../services/providers/json.provider";
var styles_CompareShapesComponent = [i0.styles, i1.styles, i2.styles];
var RenderType_CompareShapesComponent = i3.ɵcrt({ encapsulation: 0, styles: styles_CompareShapesComponent, data: {} });
export { RenderType_CompareShapesComponent as RenderType_CompareShapesComponent };
function View_CompareShapesComponent_2(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 4, "div", [["class", "option"]], null, null, null, null, null)), i3.ɵdid(1, 278528, null, 0, i4.NgClass, [i3.IterableDiffers, i3.KeyValueDiffers, i3.ElementRef, i3.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i3.ɵpod(2, { "end": 0 }), (_l()(), i3.ɵeld(3, 0, null, null, 0, "b", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i3.ɵeld(4, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "option"; var currVal_1 = _ck(_v, 2, 0, ((_v.context.index + 1) === _co.items[0].options.length)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.body); _ck(_v, 3, 0, currVal_2); }); }
function View_CompareShapesComponent_6(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 1, "div", [["class", "help toast"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.toast = !_co.toast) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i3.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/img/iconHelp.png"]], null, null, null, null, null))], null, null); }
function View_CompareShapesComponent_8(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/img/dashboard/positivo.png"]], null, null, null, null, null))], null, null); }
function View_CompareShapesComponent_9(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/img/dashboard/negativo.png"]], null, null, null, null, null))], null, null); }
function View_CompareShapesComponent_7(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i3.ɵand(16777216, null, null, 1, null, View_CompareShapesComponent_8)), i3.ɵdid(2, 16384, null, 0, i4.NgIf, [i3.ViewContainerRef, i3.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i3.ɵand(16777216, null, null, 1, null, View_CompareShapesComponent_9)), i3.ɵdid(4, 16384, null, 0, i4.NgIf, [i3.ViewContainerRef, i3.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.success; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.parent.context.$implicit.success; _ck(_v, 4, 0, currVal_1); }, null); }
function View_CompareShapesComponent_5(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 8, "div", [["class", "option"]], null, null, null, null, null)), i3.ɵdid(1, 278528, null, 0, i4.NgClass, [i3.IterableDiffers, i3.KeyValueDiffers, i3.ElementRef, i3.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i3.ɵpod(2, { "end": 0, "successMobile": 1 }), (_l()(), i3.ɵeld(3, 0, null, null, 0, "p", [["class", "textMobile"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i3.ɵand(16777216, null, null, 1, null, View_CompareShapesComponent_6)), i3.ɵdid(5, 16384, null, 0, i4.NgIf, [i3.ViewContainerRef, i3.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i3.ɵand(16777216, null, null, 1, null, View_CompareShapesComponent_7)), i3.ɵdid(7, 16384, null, 0, i4.NgIf, [i3.ViewContainerRef, i3.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i3.ɵeld(8, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "option"; var currVal_1 = _ck(_v, 2, 0, ((_v.context.index + 1) === _v.parent.parent.context.$implicit.options.length), !_v.context.$implicit.success); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.help; _ck(_v, 5, 0, currVal_3); var currVal_4 = (_v.context.$implicit.success != "not"); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.body; _ck(_v, 3, 0, currVal_2); }); }
function View_CompareShapesComponent_11(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 1, "div", [["class", "toast-msg"]], null, null, null, null, null)), (_l()(), i3.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.help; _ck(_v, 1, 0, currVal_0); }); }
function View_CompareShapesComponent_10(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), i3.ɵdid(1, 278528, null, 0, i4.NgClass, [i3.IterableDiffers, i3.KeyValueDiffers, i3.ElementRef, i3.Renderer2], { ngClass: [0, "ngClass"] }, null), i3.ɵpod(2, { "toast-container": 0 }), (_l()(), i3.ɵand(16777216, null, null, 1, null, View_CompareShapesComponent_11)), i3.ɵdid(4, 16384, null, 0, i4.NgIf, [i3.ViewContainerRef, i3.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _v.context.$implicit.help); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.$implicit.help && !_co.toast); _ck(_v, 4, 0, currVal_1); }, null); }
function View_CompareShapesComponent_4(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i3.ɵeld(1, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i3.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i3.ɵted(3, null, ["", ""])), (_l()(), i3.ɵeld(4, 0, null, null, 6, "div", [["class", "content-options"]], null, null, null, null, null)), i3.ɵdid(5, 278528, null, 0, i4.NgClass, [i3.IterableDiffers, i3.KeyValueDiffers, i3.ElementRef, i3.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i3.ɵpod(6, { "options-active": 0 }), (_l()(), i3.ɵand(16777216, null, null, 1, null, View_CompareShapesComponent_5)), i3.ɵdid(8, 278528, null, 0, i4.NgForOf, [i3.ViewContainerRef, i3.TemplateRef, i3.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i3.ɵand(16777216, null, null, 1, null, View_CompareShapesComponent_10)), i3.ɵdid(10, 278528, null, 0, i4.NgForOf, [i3.ViewContainerRef, i3.TemplateRef, i3.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "content-options"; var currVal_2 = _ck(_v, 6, 0, (_co.optionActive === _v.parent.context.index)); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.context.$implicit.options; _ck(_v, 8, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.options; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 3, 0, currVal_0); }); }
function View_CompareShapesComponent_3(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i3.ɵand(16777216, null, null, 1, null, View_CompareShapesComponent_4)), i3.ɵdid(2, 16384, null, 0, i4.NgIf, [i3.ViewContainerRef, i3.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (_v.context.index > 0); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i3.ɵinlineInterpolate(1, "contain-toggle  contain-toggle-", _v.context.index, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_CompareShapesComponent_1(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 10, "section", [], null, null, null, null, null)), (_l()(), i3.ɵeld(1, 0, null, null, 9, "div", [["class", "content-table"]], null, null, null, null, null)), (_l()(), i3.ɵeld(2, 0, null, null, 6, "div", [["class", "content-sub"]], null, null, null, null, null)), (_l()(), i3.ɵeld(3, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i3.ɵeld(4, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i3.ɵted(-1, null, ["sub"])), (_l()(), i3.ɵeld(6, 0, null, null, 2, "div", [["class", "content-options"]], null, null, null, null, null)), (_l()(), i3.ɵand(16777216, null, null, 1, null, View_CompareShapesComponent_2)), i3.ɵdid(8, 278528, null, 0, i4.NgForOf, [i3.ViewContainerRef, i3.TemplateRef, i3.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i3.ɵand(16777216, null, null, 1, null, View_CompareShapesComponent_3)), i3.ɵdid(10, 278528, null, 0, i4.NgForOf, [i3.ViewContainerRef, i3.TemplateRef, i3.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items[0].options; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.items; _ck(_v, 10, 0, currVal_1); }, null); }
export function View_CompareShapesComponent_0(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵand(16777216, null, null, 1, null, View_CompareShapesComponent_1)), i3.ɵdid(1, 16384, null, 0, i4.NgIf, [i3.ViewContainerRef, i3.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CompareShapesComponent_Host_0(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 1, "app-compare-shapes", [], null, null, null, View_CompareShapesComponent_0, RenderType_CompareShapesComponent)), i3.ɵdid(1, 114688, null, 0, i5.CompareShapesComponent, [i6.JsonProvider], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompareShapesComponentNgFactory = i3.ɵccf("app-compare-shapes", i5.CompareShapesComponent, View_CompareShapesComponent_Host_0, {}, {}, []);
export { CompareShapesComponentNgFactory as CompareShapesComponentNgFactory };
