<section>
  <div class="da-banner">
    <div class="container">
      <div class="da-banner-img"><img src="{{messages?.srcImage}}"/></div>
      <div class="da-boton-regitro"><a (click)="eventRegister(messages?.src)">Registrarme</a></div>
    </div>
  </div>
  <div class="container da-container">
    <h1 class="da-title font-bold ">{{messages?.title}}</h1>
    <div class="da-text-container" [innerHTML]="messages?.text"></div>
  </div>
  <div class="da-simulator container"><h2 class="font-bold">¿Cuánto te cuesta?</h2>
    <div class="da-simulator-container">
      <app-simulator techo="2900" detalle="1" [tarifa]="messages?.tarifa"></app-simulator>
    </div>
  </div>
  <div class="container da-container lp">
      <div class="da-legal-container" [innerHTML]="messages?.legal"></div>
      <a class="da-document" href="{{messages?.linkDoc}}" target="_black">Documentación</a>
    </div>
</section>
