<section [ngClass]="{'newService': modelo?.type, 'errorModal': modelo?.error, 'successModal': modelo?.success}"> 
  <div class="modal-header">
    <button *ngIf="!modelo?.error" type="button" class="modal-nequi-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">X</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="body">
      <h3 class="modal-title font-bold">{{modelo.titulo}}</h3>

      <div *ngFor="let texto of modelo.textos; index as i; even as isEven; odd as isOdd">
        <p class="content-modal__modal-text" [innerHtml]="texto.text"></p>
      </div>

      <strong *ngIf="modelo.extraData.typeModal === 'modalOtp'" class="seg"><p>00:{{second}} seg</p></strong>

      <form [formGroup]="form" action="">
          <div *ngFor="let input of modelo.inputs; index as i; even as isEven; odd as isOdd" style="text-align:center;">
              <textarea 
                class={{input.classStyle}} 
                [formControlName]="input.name" 
                *ngIf="isTextArea(input)">
              </textarea>
              <input 
                value={{input.value}} 
                *ngIf="!isTextArea(input)" 
                class="input-style {{input.classStyle}}"
                [formControlName]="input.name" 
                type={{input.type}}
                pattern ="{{input.type == 'number' ? '\\d*':''}}"
                placeholder={{input.placeholder}} />
                <!-- {{form.controls[input.name].invalid}} -->
            </div>
           
      </form>    
     
      <!-- {{form.value | json}} -->
    </div>
  </div>  
  <div class="modal-footer">
  
    <div *ngIf="modelo?.otherCode?.text" class="other-code">
      <button type="button" class="red-border-button"  [disabled]="second != '00'" (click)="clickButton(modelo?.otherCode?.text)">{{modelo.otherCode.text}}</button>
    </div>

    <div *ngFor="let button of modelo.botones; index as i; even as isEven; odd as isOdd">
      <button type="button" class="blue-border-button" [disabled]="form.invalid" (click)="clickButton(button)">{{button.text}}</button>
    </div>
  
  </div>
</section>