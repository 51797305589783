<div class="loading">
    <svg
      class="loading__spinner"
      width="30"
      height="30"
      viewBox="0 0 59 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="layer-down"
        d="M27.2746 25H4.72539C2.11563 25 0 27.1301 0 29.7578V49.0393C0 51.667 2.11563 53.7971 4.72539 53.7971H27.2746C29.8844 53.7971 32 51.667 32 49.0393V29.7578C32 27.1301 29.8844 25 27.2746 25Z"
        fill="#5A9EFB"
      />
      <path
        class="layer-up"
        d="M54.2746 1H31.7254C29.1156 1 27 3.13013 27 5.75778V25.0393C27 27.667 29.1156 29.7971 31.7254 29.7971H54.2746C56.8844 29.7971 59 27.667 59 25.0393V5.75778C59 3.13013 56.8844 1 54.2746 1Z"
        fill="#5A9EFB"
      />
    </svg>
    <div class="loading__feedback"></div>
</div>