<section>
  <div class="title">
    <h1 class="font-bold">
      {{dataPerfil?.title}}
    </h1>
  </div>
  <div class="img-close"  aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <img src="assets/img/close.png" alt="">
  </div>
  <div class="content">

    <form [formGroup]="form" (submit)="onSubmit()" (keyup.enter)="onSubmit()">

      <input formControlName="passwordActual" [ngClass]="{'bad-border': form.controls.passwordActual.invalid && form.controls.passwordActual.touched}" class=" input-style "
        type="password" [placeholder]="dataPerfil?.form.passwordActual.placeholder">
      <div class="pushError" [ngClass]="{'pushErrorShow': form.controls.passwordActual.invalid && form.controls.passwordActual.touched}">
        <span>{{dataPerfil?.form.passwordActual.error}}</span>
      </div>
      <br>

      <input formControlName="password" [ngClass]="{'bad-border': form.controls.password.invalid && form.controls.password.touched}"
        class=" input-style " type="password" [placeholder]="dataPerfil?.form.pass.placeholder">
      <div class="pushError" [ngClass]="{'pushErrorShow': form.controls.password.invalid && form.controls.password.touched}">
        <span>{{dataPerfil?.form.pass.error}}</span>
      </div>


      <input formControlName="passwordConfirm" [ngClass]="{'bad-border': form.controls.passwordConfirm.invalid && form.controls.passwordConfirm.touched || form.controls.passwordConfirm.touched && form.value.passwordConfirm != form.value.password}"
        class=" input-style " type="password" [placeholder]="dataPerfil?.form.passConfirm.placeholder">
      <div class="pushError" [ngClass]="{'pushErrorShow': form.controls.passwordConfirm.invalid && form.controls.passwordConfirm.touched || form.controls.passwordConfirm.touched && form.value.passwordConfirm != form.value.password}">
        <span>{{dataPerfil?.form.passConfirm.error}}</span>
      </div>

      <br>
      <br>
      <br>
      <button type="submit" [disabled]="form.invalid || form.value.passwordConfirm != form.value.password" class="green-button"> {{dataPerfil?.button}} </button>
    </form>

  </div>
</section>