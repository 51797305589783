/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./styles/options.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./options.component";
import * as i4 from "../../../../services/providers/json.provider";
import * as i5 from "@angular/router";
import * as i6 from "../../../../services/providers/google-analytics.service";
var styles_OptionsComponent = [i0.styles];
var RenderType_OptionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OptionsComponent, data: {} });
export { RenderType_OptionsComponent as RenderType_OptionsComponent };
function View_OptionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "contain-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.routerDetailApi(_v.context.$implicit.type) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.eventClick(((_v.context.$implicit == null) ? null : _v.context.$implicit.title)) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "contain-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "contain-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_v.context.$implicit == null) ? null : _v.context.$implicit.img), ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.title); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.body); _ck(_v, 7, 0, currVal_2); }); }
export function View_OptionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OptionsComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_OptionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-options", [], null, null, null, View_OptionsComponent_0, RenderType_OptionsComponent)), i1.ɵdid(1, 114688, null, 0, i3.OptionsComponent, [i4.JsonProvider, i5.Router, i6.GoogleAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OptionsComponentNgFactory = i1.ɵccf("app-options", i3.OptionsComponent, View_OptionsComponent_Host_0, {}, {}, []);
export { OptionsComponentNgFactory as OptionsComponentNgFactory };
