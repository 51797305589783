var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { map, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { ObservablesService } from './observables.service';
import { environment } from '../../../environments/environment';
import * as AWS from "aws-sdk";
import * as i0 from "@angular/core";
import * as i1 from "./observables.service";
/* declare var apigClientPartnersFactory; */
var UtilsService = /** @class */ (function () {
    function UtilsService(observablesService) {
        var _this = this;
        this.observablesService = observablesService;
        var messageID = Date.now().toString();
        messageID = messageID.substring(messageID.length - 9);
        this.params = {
            RequestMessage: {
                RequestHeader: {
                    Channel: "",
                    RequestDate: new Date().toJSON(),
                    MessageID: messageID,
                    ClientID: "",
                    Destination: {}
                },
                RequestBody: {
                    any: {}
                }
            }
        };
        //console.log(apigClientPartners);
        if (!this.observablesService.getLocalStorage('currentUser') && !this.observablesService.getCookie('parameters')) {
            this.createNewClient();
            this.getParams();
        }
        else {
            var dataLogin = this.observablesService.getCookie('parameters');
            this.createNewClient({
                accessKey: dataLogin.accessKey,
                secretKey: dataLogin.secretKey,
                sessionToken: dataLogin.sessionToken,
                accessToken: dataLogin.accessToken,
                apiKey: dataLogin.apiKey
            });
        }
        setInterval(function () {
            if (_this.observablesService.getLocalStorage('currentUser') && _this.observablesService.getCookie('parameters')) {
                _this.keyLoginInterval();
            }
            else {
                _this.eventKey();
            }
        }, 3000000);
    }
    UtilsService.prototype.getParams = function () {
        var _this = this;
        var body = {
            Channel: "PN01-C001",
            ClientID: "3999999999",
            Destination: {
                "ServiceName": "GeneralServices",
                "ServiceOperation": "getParameters",
                "ServiceRegion": "C001",
                "ServiceVersion": "1.0.0"
            },
            Body: {
                "getParametersRQ": {}
            }
        };
        return this.consumeEvent('servicesGeneralservicesGetparametersPost', body).toPromise().then(function (data) {
            if (!data.error) {
                _this.observablesService.setLocalStorage('parameters', data);
            }
        });
    };
    UtilsService.prototype.createNewClient = function (config) {
        if (config === void 0) { config = undefined; }
        this.apiClient = environment.apigClientPartners.newClient(config);
    };
    UtilsService.prototype.createNewClientDispersions = function (config) {
        if (config === void 0) { config = undefined; }
        this.apiClientDispersion = environment.apigClientDispersions.newClient(config);
    };
    UtilsService.prototype.getRequest = function (body) {
        /* this.params.RequestMessage.RequestHeader.Channel = body.Channel; */
        this.params.RequestMessage.RequestHeader.Channel = 'PN01-C001';
        if (this.observablesService.getLocalStorage('currentUser') && this.observablesService.getCookie('parameters')) {
            var user = this.observablesService.getLocalStorage('currentUser');
            this.params.RequestMessage.RequestHeader.ClientID = user.username;
        }
        else {
            /* this.params.RequestMessage.RequestHeader.ClientID = body.ClientID; */
            this.params.RequestMessage.RequestHeader.ClientID = 'PN01-C001';
        }
        this.params.RequestMessage.RequestHeader.Destination = body.Destination;
        this.params.RequestMessage.RequestBody.any = body.Body;
        return this.params;
    };
    UtilsService.prototype.consumeEvent = function (event, body, sdk) {
        var _this = this;
        if (sdk === void 0) { sdk = "negocios"; }
        this.observablesService.showSpinner(true);
        var request = this.getRequest(body);
        var observable;
        if (sdk == "dispersions") {
            observable = from(this.apiClientDispersion[event]('', request, ''))
                .pipe(map(function (res) {
                return _this.validateData(res);
            }), catchError(function (error) {
                return _this.validateData(error);
            }));
        }
        else if (sdk == "negocios") {
            observable = from(this.apiClient[event]('', request, ''))
                .pipe(map(function (res) {
                return _this.validateData(res);
            }), catchError(function (error) {
                return _this.validateData(error);
            }));
        }
        return observable;
    };
    UtilsService.prototype.validateData = function (res) {
        try {
            if (res.status == 200 && res.data.ResponseMessage.ResponseHeader.Status.StatusCode == "0") {
                this.observablesService.showSpinner(false);
                return res.data.ResponseMessage.ResponseBody.any;
            }
            else {
                /* console.error(res.data.ResponseMessage.ResponseHeader.Status.StatusDesc); */
                if (res.data.message === "The security token included in the request is expired") {
                    this.observablesService.setCookie('parameters', {}, 0, true);
                    this.observablesService.removeItem('currentUser');
                }
                this.observablesService.showSpinner(false);
                if (res.data.ResponseMessage) {
                    if (res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-05A' || res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-07A') {
                        /* alert(res.data.ResponseMessage.ResponseHeader.Status.StatusDesc); */
                        this.showModalCallback('ErrorTecnico', this.reload);
                    }
                }
                else {
                    this.showModalCallback('ErrorTecnico', this.reload);
                }
                return { error: true, res: res };
            }
        }
        catch (error) {
            this.showModalCallback('ErrorTecnico', this.reload);
            /* alert(error); */
        }
    };
    UtilsService.prototype.reload = function () {
        location.reload();
    };
    UtilsService.prototype.eventKey = function () {
        var _this = this;
        var data = this.observablesService.getLocalStorage('parameters');
        this.setCognitoTemporalKeys(data.getParametersRS).subscribe(function (creds) {
            _this.createNewClient({
                accessKey: creds.accessKeyId,
                secretKey: creds.secretAccessKey,
                sessionToken: creds.sessionToken,
                apiKey: data.getParametersRS.apiKey
            });
        }, function (error) {
        });
    };
    UtilsService.prototype.setCognitoTemporalKeys = function (params) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            AWS.config.region = 'us-east-1';
            var creds = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: params.cognitoIdentityPool
            });
            AWS.config.credentials = creds;
            creds.get(function (error) {
                if (!!creds.accessKeyId && !!creds.secretAccessKey && !!creds.sessionToken) {
                    resolve(creds);
                }
                else {
                    _this.showModalCallback('ErrorTecnico', _this.reload);
                    reject(false);
                }
            });
        });
        var observable = from(promise);
        return observable;
    };
    UtilsService.prototype.encryptString = function (string) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var promise;
            return __generator(this, function (_a) {
                promise = new Promise(function (resolve, reject) {
                    _this.getParams();
                    var data = _this.observablesService.getLocalStorage('parameters');
                    _this.setCognitoTemporalKeys(data.getParametersRS).subscribe(function () {
                        AWS.config.region = 'us-east-1';
                        var kms = new AWS.KMS();
                        var params = {
                            KeyId: data.getParametersRS.kmsKey,
                            Plaintext: string
                        };
                        kms.encrypt(params, function (err, data) {
                            if (err) {
                                reject({ error: true, data: err });
                            }
                            else {
                                resolve(data.CiphertextBlob.toString('base64'));
                            }
                        });
                    });
                });
                return [2 /*return*/, promise];
            });
        });
    };
    UtilsService.prototype.keyLogin = function () {
        var dataLogin = this.observablesService.getCookie('parameters');
        this.createNewClient({
            accessKey: dataLogin.accessKey,
            secretKey: dataLogin.secretKey,
            sessionToken: dataLogin.sessionToken,
            accessToken: dataLogin.accessToken,
            apiKey: dataLogin.apiKey
        });
    };
    UtilsService.prototype.logout = function () {
        var _this = this;
        var dataLocal = this.observablesService.getLocalStorage('currentUser');
        var body = {
            Channel: "NegociosNequi",
            ClientID: "3999999999",
            Destination: {
                "ServiceName": "PartnerService",
                "ServiceOperation": "logout",
                "ServiceRegion": "C001",
                "ServiceVersion": "1.0.0"
            },
            Body: {
                "logoutRQ": {
                    "username": dataLocal.username,
                    "accessToken": dataLocal.accessToken
                }
            }
        };
        this.consumeEvent('servicesSessionservicesLogoutPost', body).subscribe(function (data) {
            if (!data.error) {
                _this.observablesService.removeItem('currentUser');
                _this.observablesService.setCookie('parameters', {}, 0, true);
            }
        });
    };
    UtilsService.prototype.keyLoginInterval = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var dataLocal, body;
            return __generator(this, function (_a) {
                dataLocal = this.observablesService.getCookie('parameters');
                body = {
                    Channel: "NegociosNequi",
                    ClientID: "3999999999",
                    Destination: {
                        "ServiceName": "PartnerService",
                        "ServiceOperation": "refreshLogin",
                        "ServiceRegion": "C001",
                        "ServiceVersion": "1.0.0"
                    },
                    Body: {
                        "refreshLoginRQ": {
                            "username": dataLocal.username,
                            "accessToken": dataLocal.accessToken
                        }
                    }
                };
                return [2 /*return*/, this.consumeEvent('servicesSessionservicesRefreshloginPost', body).toPromise().then(function (data) {
                        if (!data.error) {
                            _this.observablesService.setCookie('parameters', data.refreshLoginRS, 480, true);
                            /* if(data.refreshLoginRS.accessToken){
                              dataLocal.accessToken = data.refreshLoginRS.accessToken;
                            }
                    
                            this.observablesService.setLocalStorage('currentUser', dataLocal); */
                            _this.createNewClient({
                                accessKey: data.refreshLoginRS.accessKey,
                                secretKey: data.refreshLoginRS.secretKey,
                                sessionToken: data.refreshLoginRS.sessionToken,
                                accessToken: data.refreshLoginRS.accessToken,
                                apiKey: data.refreshLoginRS.apiKey
                            });
                        }
                        else {
                            _this.observablesService.setCookie('parameters', data.refreshLoginRS, 0, true);
                            _this.observablesService.removeItem('currentUser');
                            window.location.reload();
                        }
                    })];
            });
        });
    };
    /**
    * modal que retorna callback
    * @param modelo nombre del tipo de modal
    * @param form datos del tab (registro) de donde salio el modal
    * @param fn callback que retorna el modal
    */
    UtilsService.prototype.showModalCallback = function (modelo, fn) {
        this.observablesService.showModal({
            modelo: modelo,
            extraData: {},
            clicFunc: fn
        });
    };
    UtilsService.ngInjectableDef = i0.defineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(i0.inject(i1.ObservablesService)); }, token: UtilsService, providedIn: "root" });
    return UtilsService;
}());
export { UtilsService };
