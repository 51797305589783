<section class="container-carousel">
  <owl-carousel [options]="options" [items]="items" [carouselClasses]="['owl-theme', 'sliding']">
    <div class="item" *ngFor="let item of items; let i = index">
      <div class="content-item row">
        <div class="col-md-7 col-sm-7 col-xs-12 contain-text">
          <div>
            <h1 class="font-bold">{{item.title}}</h1>
            <p>{{item.body}}</p>
            <button class="white-border-button" (click)="eventRegister(item.button)">{{item.button}}</button>
          </div>
        </div>
        <div class="col-md-5 col-sm-5 col-xs-12">
          <img src="{{item.img}}" alt="">
        </div>

      </div>
    </div>
  </owl-carousel>
</section>