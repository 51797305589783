<section>

  <div class="form" *ngIf="form && formEmail">
    <div class="title">
      <h1 class="font-bold">
        {{dataLogin?.title.text}}
      </h1>

      <p  *ngIf="!dataFlow?.emailExist && !dataFlow?.emailExist">
        {{ dataLogin?.body.email }}
      </p>

      <p *ngIf="dataFlow?.emailExist && !dataFlow?.success">
        {{ dataLogin?.body.password }}
      </p>

      <p *ngIf="dataFlow?.success">
        {{ dataLogin?.body.success }}
      </p>
    </div>


    <form [formGroup]="formEmail" (submit)="captchaRef.execute()" (keyup.enter)="captchaRef.execute()" *ngIf="!dataFlow?.emailExist && !dataFlow?.success">

      <input formControlName="email" [ngClass]="{'bad-border': formEmail.controls.email.invalid && formEmail.controls.email.touched || !dataFlow?.emailNotExist}"
        class=" input-style " type="email" [placeholder]="dataLogin?.form.email.placeholder">
      <div class="pushError" [ngClass]="{'pushErrorShow': formEmail.controls.email.invalid && formEmail.controls.email.touched}">
        <span>{{dataLogin?.form.email.error}}</span>
      </div>
      <div class="pushError" [ngClass]="{'pushErrorShow': !dataFlow?.emailNotExist }">
        <span>{{dataLogin?.form.email.errorUser}}</span>
      </div>
      <br>
      <br>
      <div class="captcha">
        <re-captcha size="invisible" (resolved)="$event && onSubmitEmail($event)" formControlName="recaptchaReactive" #captchaRef="reCaptcha" class="reCaptcha"></re-captcha>
      </div>
      <button type="submit" [disabled]="formEmail.invalid" class="green-button"> {{dataLogin?.button.email}} </button>
    </form>


    <form [formGroup]="form" (submit)="captchaRef.execute()" (keyup.enter)="captchaRef.execute()" *ngIf="dataFlow.emailExist && !dataFlow?.success">

      <input formControlName="code" [ngClass]="{'bad-border': form.controls.code.invalid && form.controls.code.touched}" class=" input-style "
        type="text" [placeholder]="dataLogin?.form.code.placeholder">
      <div class="pushError" [ngClass]="{'pushErrorShow': form.controls.code.invalid && form.controls.code.touched}">
        <span>{{dataLogin?.form.code.error}}</span>
      </div>
      <br>

      <div class="content-form">
        <input formControlName="password" [ngClass]="{'bad-border': form.controls.password.invalid && form.controls.password.touched}"
          class=" input-style " type="password" [placeholder]="dataLogin?.form.pass.placeholder">
        <div class="pushError" [ngClass]="{'pushErrorShow': form.controls.password.invalid && form.controls.password.touched}">
          <span>{{dataLogin?.form.pass.error}}</span>
        </div>
        <div>
          <div class="help-data">
            <span [innerHtml]="dataLogin?.form.pass.help"></span>
          </div>
        </div>
      </div>

      <div class="content-form">
        <input formControlName="passwordConfirm" [ngClass]="{'bad-border': form.controls.passwordConfirm.invalid && form.controls.passwordConfirm.touched || form.controls.passwordConfirm.touched && form.value.passwordConfirm != form.value.password}"
          class=" input-style " type="password" [placeholder]="dataLogin?.form.passConfirm.placeholder">
        <div class="pushError" [ngClass]="{'pushErrorShow': form.controls.passwordConfirm.invalid && form.controls.passwordConfirm.touched || form.controls.passwordConfirm.touched && form.value.passwordConfirm != form.value.password}">
          <span>{{dataLogin?.form.passConfirm.error}}</span>
        </div>
      </div>

      <br>
      <br>
      <div class="captcha">
        <re-captcha size="invisible" (resolved)="$event && onSubmit($event)" formControlName="recaptchaReactive" #captchaRef="reCaptcha" class="reCaptcha"></re-captcha>
      </div>
      <button type="submit" [disabled]="form.invalid || form.value.passwordConfirm != form.value.password || sendService" class="green-button"> {{dataLogin?.button.password}} </button>
    </form>

    <form *ngIf="dataFlow?.success">
      <button [routerLink]="['/login']"  [disabled]="" class="green-button"> {{dataLogin?.button.success}} </button>
    </form>

  </div>

</section>