<section class="dashborad" >
  <div class="banner">

      <div class="contain-dash">
          <h1 class="font-bold">{{messages?.banner.title}}</h1>
          <p [innerHtml]="messages?.banner.body"></p>
          <button (click)="eventProfile()" [routerLink]="[messages?.banner.src]" class="blue-button button-banner" >{{messages?.banner.button}}</button>
          <button  class="blue-border-button button-banner button-banner-registrarme" (click)="scroll(messages?.banner.buttonR.src)">{{messages?.banner.buttonR.text}}</button>
      </div>

      <img  src="{{messages?.banner.img}}" alt="inicio">
  </div> 
  <div class="contain-shapes" id="contain-shapes">
      <div class="contain-dash">
          <div class="text">
              <h1 class="font-bold">{{messages?.shapes.title}}</h1>
              <p>{{messages?.shapes.body}}</p>
          </div>          
      </div>
      <app-shapes></app-shapes>
      
  </div>

  <div class="contain-compare">

     <div class="contain-dash">
          <div class="text">
            <h1 class="font-bold">{{messages?.shapesCompare.title}}</h1>
          </div>          
          <app-compare-shapes></app-compare-shapes>
      </div>

  </div>    
      
  
</section>