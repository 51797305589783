/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/header/header.component.ngfactory";
import * as i3 from "./components/header/header.component";
import * as i4 from "./services/providers/json.provider";
import * as i5 from "./services/providers/observables.service";
import * as i6 from "./services/providers/google-analytics.service";
import * as i7 from "@ng-bootstrap/ng-bootstrap/modal/modal";
import * as i8 from "./services/providers/utils.service";
import * as i9 from "@angular/router";
import * as i10 from "./components/spinner/spinner.component.ngfactory";
import * as i11 from "./components/spinner/spinner.component";
import * as i12 from "@angular/common";
import * as i13 from "./components/footer/footer.component.ngfactory";
import * as i14 from "./components/footer/footer.component";
import * as i15 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.JsonProvider, i5.ObservablesService, i6.GoogleAnalyticsService, i7.NgbModal, i8.UtilsService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["widgetParentDiv", 1]], null, 2, "div", [["class", "content-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-spinner", [], null, null, null, i10.View_SpinnerComponent_0, i10.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i11.SpinnerComponent, [], null, null)], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["aria-label", "Ayuda"], ["class", "blue-button button-contact"], ["href", "https://ayuda.negocios.nequi.co/hc/es-419/requests/new?ticket_form_id=8945894074509"], ["role", "button"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "Imagen de ayuda"], ["src", "/assets/img/help-circle.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ayuda"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-footer", [], null, null, null, i13.View_FooterComponent_0, i13.RenderType_FooterComponent)), i1.ɵdid(8, 49152, null, 0, i14.FooterComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(10, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showHeader; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); var currVal_1 = _co.showSpinner; _ck(_v, 10, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i15.AppComponent, [i9.Router, i5.ObservablesService, i6.GoogleAnalyticsService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i15.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
