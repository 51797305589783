var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { JsonProvider, ObservablesService, UtilsService, GoogleAnalyticsService, clevertapService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDatepickerI18n, NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';
import { RegistryConfig } from './registry.config';
var I18N_VALUES = {
    'es-CO': {
        weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
        months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    }
    // other languages you would support
};
// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
var I18n = /** @class */ (function () {
    function I18n() {
        this.language = 'es-CO';
    }
    return I18n;
}());
export { I18n };
// Define custom service providing the months and weekdays translations
var CustomDatepickerI18n = /** @class */ (function (_super) {
    __extends(CustomDatepickerI18n, _super);
    function CustomDatepickerI18n(_i18n) {
        var _this = _super.call(this) || this;
        _this._i18n = _i18n;
        return _this;
    }
    CustomDatepickerI18n.prototype.getWeekdayShortName = function (weekday) {
        return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
    };
    CustomDatepickerI18n.prototype.getMonthShortName = function (month) {
        return I18N_VALUES[this._i18n.language].months[month - 1];
    };
    CustomDatepickerI18n.prototype.getMonthFullName = function (month) {
        return this.getMonthShortName(month);
    };
    CustomDatepickerI18n.prototype.getDayAriaLabel = function (date) {
        return date.day + "-" + date.month + "-" + date.year;
    };
    return CustomDatepickerI18n;
}(NgbDatepickerI18n));
export { CustomDatepickerI18n };
var ɵ0 = {
    siteKey: environment.googleCaptchaKey,
};
var RegistroComponent = /** @class */ (function () {
    function RegistroComponent(JsonProvider, formBuilder, observablesService, router, activatedRoute, config, ngbDateParserFormatter, modalService, sanitizer, utilsService, googleAnalyticsService, clevertapService) {
        var _this = this;
        this.JsonProvider = JsonProvider;
        this.formBuilder = formBuilder;
        this.observablesService = observablesService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.config = config;
        this.ngbDateParserFormatter = ngbDateParserFormatter;
        this.modalService = modalService;
        this.sanitizer = sanitizer;
        this.utilsService = utilsService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.clevertapService = clevertapService;
        this.forms = [];
        this.tabShow = 0;
        this.registerSuccess = false;
        this.registerSuccessConfirmation = false;
        this.btnNextDisabled = false;
        this.dataFlow = {
            nequi: 1,
            ahorroApi: 1,
            otp: 0,
            countOtp: 0,
            otpReport: 0,
            countOtpReport: 0,
            otpEmail: 0,
            countOtpEmail: 0,
            otpNequi: 0,
            countOtpNequi: 0,
            tryOtpNequi: 0,
            bloqueo: 0,
            list: 0,
            comercio: {
                list: 0,
                name: 0
            },
            representante: {
                ccValid: 1,
                list: 0,
                dateEx: 1
            },
            cuenta: {
                valid: 0
            },
            location: {
                department: [],
                city: []
            },
            flow: []
        };
        this.toast = false;
        var validators = [];
        var d = new Date();
        this.config.minDate = { year: 1900, month: 1, day: 1 };
        this.config.maxDate = { year: d.getFullYear(), month: d.getMonth(), day: d.getDate() + 1 };
        this.JsonProvider.getMessages('welcome-register').subscribe(function (data) { return _this.welcomeMessages = data; });
        this.JsonProvider.getMessages('welcome-register-confirmation').subscribe(function (data) { return _this.welcomeMessagesConfirmation = data; });
        this.infoForms = !!this.observablesService.getLocalStorage('infoRegister') ? this.observablesService.getLocalStorage('infoRegister') : undefined;
        this.dataFlow = !!this.observablesService.getLocalStorage('infoValidate') ? this.observablesService.getLocalStorage('infoValidate') : this.dataFlow;
        this.getProduct();
        this.createForms();
    }
    RegistroComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var data_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.activatedRoute.queryParams.subscribe(function (params) {
                            _this.externalPartner = params.ea;
                        });
                        this.eventClick('Informacion personal');
                        if (!(!this.observablesService.getLocalStorage('currentUser') || !this.observablesService.getCookie('parameters'))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.utilsService.getParams()];
                    case 1:
                        _a.sent();
                        data_1 = this.observablesService.getLocalStorage('parameters');
                        this.utilsService.setCognitoTemporalKeys(data_1.getParametersRS).subscribe(function (creds) {
                            _this.utilsService.createNewClient({
                                accessKey: creds.accessKeyId,
                                secretKey: creds.secretAccessKey,
                                sessionToken: creds.sessionToken,
                                apiKey: data_1.getParametersRS.apiKey
                            });
                            _this.getDepartment();
                        }, function (error) {
                        });
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.utilsService.keyLogin()];
                    case 3:
                        _a.sent();
                        this.getDepartment();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    RegistroComponent.prototype.getDepartment = function () {
        var _this = this;
        var body = {
            Channel: "PN01-C001",
            ClientID: "3999999999",
            Destination: {
                "ServiceName": "LocationServices",
                "ServiceOperation": "getLocationsByParent",
                "ServiceRegion": "C001",
                "ServiceVersion": "1.0.0"
            },
            Body: {
                "getLocationsByParentRQ": {
                    "parentId": "C001"
                }
            }
        };
        this.utilsService.consumeEvent('servicesLocationservicesGetlocationsbyparentPost', body).subscribe(function (location) {
            if (!location.error) {
                _this.dataFlow.location.department = location.getLocationsByParentRS.locations;
            }
        });
    };
    RegistroComponent.prototype.eventLocation = function (event) {
        var _this = this;
        var id = event.target.id;
        if (id == 'department') {
            var index = event.target.selectedIndex - 1;
            var code = this.dataFlow.location.department[index];
            this.forms[1].get('city').setValue('');
            var body = {
                Channel: "PN01-C001",
                ClientID: "3999999999",
                Destination: {
                    "ServiceName": "LocationServices",
                    "ServiceOperation": "getLocationsByParent",
                    "ServiceRegion": "C001",
                    "ServiceVersion": "1.0.0"
                },
                Body: {
                    "getLocationsByParentRQ": {
                        "parentId": code.code
                    }
                }
            };
            this.utilsService.consumeEvent('servicesLocationservicesGetlocationsbyparentPost', body).subscribe(function (locationCity) {
                if (!locationCity.error) {
                    _this.dataFlow.location.city = locationCity.getLocationsByParentRS.locations;
                }
            });
        }
    };
    RegistroComponent.prototype.getProduct = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            if (params.product == 'api_debito' ||
                params.product == 'api_qr' ||
                params.product == 'api_push') {
                _this.register_type = 'api';
            }
            else if (!!params.product) {
                _this.register_type = params.product;
            }
            if (!_this.register_type) {
                _this.router.navigate(['/']);
            }
        });
    };
    RegistroComponent.prototype.change = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            _this.type_api = params.product;
            var obj = {};
            obj[params.product] = 1;
            _this.observablesService.observableRegistro(obj);
        });
    };
    RegistroComponent.prototype.createForms = function () {
        var _this = this;
        this.JsonProvider.getMessages('registro').subscribe(function (data) {
            /*get the specific json*/
            _this.messages = data[_this.register_type];
            /* Redirigir al home si el parametro no se encuentra en la lista */
            if (_this.messages === undefined)
                _this.router.navigate(['/']);
            _this.messages.map(function (elements, index) {
                _this.forms[index] = _this.formBuilder.group({});
                _this.forms[index].addControl('recaptchaReactive' + index, new FormControl(null));
                //llenar campos con datos guardados
                var infoForm;
                if (!!_this.infoForms && !!_this.infoForms[index]) {
                    infoForm = _this.infoForms[index];
                }
                elements.inputs.map(function (element) {
                    if ('html' !== element.type) {
                        if ('checkbox' !== element.type) {
                            /*create input*/
                            _this.forms[index].addControl(element.name, new FormControl(''));
                            /* llenar inputs */
                            if (!!_this.infoForms && !!_this.infoForms[index])
                                _this.fillInput(element, index, infoForm);
                        }
                        else {
                            _this.forms[index].addControl(element.name, new FormArray([]));
                            /* llenar checked */
                            _this.loadCheck(index, element, !!infoForm ? infoForm[element.name] : []);
                        }
                        /*Add validators*/
                        if ('dependence' in element) {
                            if ("hide" == element.dependence.type) {
                                _this.hideElement(element, index);
                            }
                            else if ("data" == element.dependence.type) {
                                _this.dataElement(element, index);
                            }
                            else if ("change" == element.dependence.type) {
                                _this.changeElement(element, index);
                            }
                            else if ("check" == element.dependence.type) {
                                _this.checkElement(element, index);
                            }
                            else if ("equals" == element.dependence.type) {
                                _this.twoElementsValidation(element, index);
                            }
                            else {
                                console.error('Wrong dependence type in ' + element.name);
                            }
                        }
                        else {
                            var validators = [];
                            var element_control = _this.forms[index].get(element.name);
                            validators = _this.createValidators(element);
                            element_control.setValidators(validators);
                            element_control.updateValueAndValidity();
                        }
                        if ('equal' in element) {
                            _this.equalElement(element, index);
                        }
                    }
                    else {
                        if ('dependence' in element) {
                            if ("changeText" == element.dependence.type) {
                                _this.changeText(element, index);
                            }
                            else {
                                _this.hideElementTitle(element, index);
                            }
                        }
                    }
                    if ("flow" in element) {
                        _this.changeFlow(element, index);
                    }
                });
            });
            _this.change();
        });
    };
    RegistroComponent.prototype.fillInput = function (element, index, infoForm) {
        var _this = this;
        setTimeout(function () {
            var element_control = _this.forms[index].get(element.name);
            element_control.setValue(!!infoForm ? infoForm[element.name] : '');
            element_control.updateValueAndValidity();
        }, 1);
    };
    RegistroComponent.prototype.loadCheck = function (index, element, array) {
        var _this = this;
        var formArray = this.forms[index].get(element.name);
        var indexForm = this.messages[index].inputs.map(function (e) { return e.name; }).indexOf(element.name);
        array.map(function (value, indexArray) {
            formArray.push(new FormControl(value));
            _this.messages[index].inputs[indexForm].options[0].checked = true;
        });
    };
    RegistroComponent.prototype.createValidators = function (element) {
        var validator = [];
        if ('required' in element && !!element.required) {
            validator.push(Validators.required);
        }
        if ("email" == element.type) {
            var regex = /^(([^<>()[\]\.,;:\s@\']+(\.[^<>()[\]\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
            validator.push(Validators.pattern(regex));
        }
        if ('max' in element) {
            validator.push(Validators.maxLength(element.max));
        }
        if ('min' in element) {
            validator.push(Validators.minLength(element.min));
        }
        if ('pattern' in element) {
            var regex = element.pattern;
            validator.push(Validators.pattern(element.pattern));
        }
        return validator;
    };
    /**
     * Cambio de check y show modal TyC
     * @param event
     * @param form
     * @param name
     * @param option
     */
    RegistroComponent.prototype.onCheckChange = function (event, form, name, option) {
        if (option === void 0) { option = undefined; }
        var formArray = form.get(name);
        /*  console.log(event.target.checked); */
        /* Selected */
        if (event.target.checked) {
            // Add a new control in the arrayForm
            if (option) {
                this.showModalTyc(option);
            }
            formArray.push(new FormControl(event.target.value));
        }
        else {
            // find the unselected element
            var i_1 = 0;
            formArray.controls.forEach(function (ctrl) {
                if (ctrl.value == event.target.value) {
                    // Remove the unselected element from the arrayForm
                    formArray.removeAt(i_1);
                    return;
                }
                i_1++;
            });
        }
    };
    /*Dependeces*/
    RegistroComponent.prototype.hideElementTitle = function (element, i) {
        var _this = this;
        if ("hide" == element.dependence.type) {
            this.forms[element.dependence.posForm].get(element.dependence.name).valueChanges.subscribe(function (result) {
                if ("condition" in element.dependence) {
                    if (result == element.dependence.condition) {
                        element.display = true;
                    }
                    else {
                        element.display = false;
                    }
                }
                else {
                    element.display = true;
                }
            });
        }
        else if ("flow" == element.dependence.type) {
            this.forms[element.dependence.posForm].get(element.dependence.name).valueChanges.subscribe(function (result) {
                if ("condition" in element.dependence) {
                    if (result == element.dependence.condition) {
                        _this.changeFlow(element, i);
                    }
                    else {
                        element.display = false;
                    }
                }
                else {
                    _this.changeFlow(element, i);
                }
            });
        }
        else {
            this.observablesService.observableRegistro$.subscribe(function (result) {
                if (1 == result[element.dependence.name]) {
                    element.display = true;
                }
                else if (0 == result[element.dependence.name]) {
                    element.display = false;
                }
            });
        }
    };
    RegistroComponent.prototype.equalElement = function (element, index) {
        var _this = this;
        var element_control = this.forms[index].get(element.name);
        this.forms[element.dependence.posForm].get(element.equal).valueChanges.subscribe(function (result) {
            if (_this.forms[index].value[element.name].length != 5 || !!element_control.invalid)
                element_control.setValue(result);
        });
    };
    RegistroComponent.prototype.hideElement = function (element, index) {
        var _this = this;
        var element_control = this.forms[index].get(element.name);
        var validators = [];
        this.forms[element.dependence.posForm].get(element.dependence.name).valueChanges.subscribe(function (result) {
            if (!!result) {
                if ("condition" in element.dependence) {
                    if (result == element.dependence.condition) {
                        validators = _this.createValidators(element);
                        element.display = true;
                        element_control.setValidators(validators);
                    }
                    else {
                        element_control.clearValidators();
                        if ("controls" in element_control) {
                            element_control.controls = [];
                        }
                        else {
                            element_control.setValue('');
                        }
                        element.display = false;
                    }
                }
                else {
                    validators = _this.createValidators(element);
                    element.display = true;
                    element_control.setValidators(validators);
                }
                element_control.updateValueAndValidity();
            }
            else {
                element.display = false;
                if (element.type != 'checkbox') {
                    element_control.setValue("");
                    element_control.updateValueAndValidity();
                }
            }
        });
    };
    RegistroComponent.prototype.onRadioChange = function (form, name, value) {
        var element_control = form.get(name);
        element_control.setValue(value);
        element_control.updateValueAndValidity();
    };
    RegistroComponent.prototype.dataElement = function (element, index) {
        var _this = this;
        var element_control = this.forms[index].get(element.name);
        var validators = [];
        this.observablesService.observableRegistro$.subscribe(function (result) {
            if (1 == result[element.dependence.name]) {
                validators = _this.createValidators(element);
                element.display = true;
                element_control.setValidators(validators);
                element_control.updateValueAndValidity();
            }
            else if (0 == result[element.dependence.name]) {
                element_control.clearValidators();
                if ("controls" in element_control) {
                    element_control.controls = [];
                }
                else {
                    element_control.setValue('');
                }
                element.display = false;
                element_control.updateValueAndValidity();
            }
        });
    };
    RegistroComponent.prototype.changeElement = function (element, index) {
        var element_control = this.forms[index].get(element.name);
        this.forms[element.dependence.posForm].get(element.dependence.name).valueChanges.subscribe(function (result) {
            if (result in element.dependence.patterns) {
                var regex = element.dependence.patterns[result];
                element_control.setValue('');
                element_control.setValidators([Validators.required, Validators.pattern(regex)]);
                element.display = true;
            }
            else {
                element_control.clearValidators();
                if ("controls" in element_control) {
                    element_control.controls = [];
                }
                else {
                    element_control.setValue('');
                }
                element.display = false;
            }
            element_control.updateValueAndValidity();
        });
    };
    RegistroComponent.prototype.checkElement = function (element, index) {
        var _this = this;
        var element_control = this.forms[index].get(element.name);
        var validators = [];
        this.forms[element.dependence.posForm].get(element.dependence.name).valueChanges.subscribe(function (result) {
            if (result.includes(element.dependence.condition)) {
                validators = _this.createValidators(element);
                element.display = true;
                element_control.setValidators(validators);
            }
            else {
                element_control.clearValidators();
                if ("controls" in element_control) {
                    element_control.controls = [];
                }
                else {
                    element_control.setValue('');
                }
                element.display = false;
            }
            element_control.updateValueAndValidity();
        });
    };
    RegistroComponent.prototype.twoElementsValidation = function (element, index) {
        var _this = this;
        var element_control = this.forms[index].get(element.name);
        var validators = [];
        validators = this.createValidators(element);
        element_control.setValidators(validators);
        element_control.updateValueAndValidity();
        this.forms[element.dependence.posForm].get(element.dependence.name).valueChanges.subscribe(function (result) {
            element.display = true;
            element_control.setValue("");
        });
        element_control.valueChanges.subscribe(function (result) {
            if (result !== _this.forms[element.dependence.posForm].get(element.dependence.name).value) {
                element_control.setErrors({ 'nomatch': true });
            }
            else {
                element_control.setErrors(null);
            }
        });
    };
    RegistroComponent.prototype.changeFlow = function (element, i) {
        var element_control;
        if (element.type != 'html') {
            element_control = this.forms[i].get(element.name);
        }
        this.observablesService.observableRegistro$.subscribe(function (result) {
            if (!!result["flow"]) {
                var i_2 = 0;
                element.flow.forEach(function (element) {
                    if (!result["flow"].includes(element)) {
                        /* i = 0; */
                        return false;
                    }
                    else {
                        i_2++;
                    }
                });
                if (i_2 == element.flow.length) {
                    element.display = true;
                }
                else {
                    element.display = false;
                    if (element.type != 'html') {
                        element_control.setValue("");
                        element_control.updateValueAndValidity();
                        element_control.clearValidators();
                        element_control.updateValueAndValidity();
                    }
                }
            }
        });
    };
    RegistroComponent.prototype.changeText = function (element, index) {
        var _this = this;
        if ("service" in element.dependence) {
            this.observablesService.observableRegistro$.subscribe(function (result) {
                if ("condition" in element.dependence) {
                    if (element.dependence.condition == result[element.dependence.service]) {
                        var res = "<span>" + _this.forms[element.dependence.toMatchPos].get(element.dependence.toMatchName).value + "</span>";
                        var newBody = element.body.replace(/<span>.*<\/span>/, res);
                        element.body = newBody;
                        element.display = true;
                    }
                    else if (element.dependence.condition == result[element.dependence.service]) {
                        element.display = false;
                    }
                }
                else {
                    if (1 == result[element.dependence.service]) {
                        var res = "<span>" + _this.forms[element.dependence.toMatchPos].get(element.dependence.toMatchName).value + "</span>";
                        var newBody = element.body.replace(/<span>.*<\/span>/, res);
                        element.body = newBody;
                        element.display = true;
                    }
                    else if (0 == result[element.dependence.service]) {
                        element.display = false;
                    }
                }
            });
        }
        else {
            this.forms[element.dependence.posForm].get(element.dependence.name).valueChanges.subscribe(function (result) {
                if ("condition" in element.dependence) {
                    if (result == (element.dependence.condition)) {
                        if (element.dependence.toMatchPos === '0') {
                            var res = "<span>" + _this.forms[element.dependence.toMatchPos].get(element.dependence.toMatchName).value + "</span>";
                            var newBody = element.body.replace(/<span>.*<\/span>/, res);
                            element.body = newBody;
                            element.display = true;
                        }
                        else {
                            _this.forms[element.dependence.toMatchPos].get(element.dependence.toMatchName).valueChanges.subscribe(function (result) {
                                var res = "<span>" + result + "</span>";
                                var newBody = element.body.replace(/<span>.*<\/span>/, res);
                                element.body = newBody;
                                element.display = true;
                            });
                        }
                    }
                    else {
                        element.display = false;
                    }
                }
                else {
                    var res = "<span>" + _this.forms[element.dependence.toMatchPos].get(element.dependence.toMatchName).value + "</span>";
                    var newBody = element.body.replace(/<span>.*<\/span>/, res);
                    element.body = newBody;
                    element.display = true;
                }
            });
        }
    };
    /**
     * Submit all form
     * @param form
     * @param indexForm
     */
    RegistroComponent.prototype.onSubmit = function (form, indexForm, captchaResponse) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.btnNextDisabled = true;
                        if (!form.valid) return [3 /*break*/, 11];
                        if (!(indexForm === 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.eventInfoPersonal(form, captchaResponse)];
                    case 1:
                        _a.sent();
                        this.btnNextDisabled = false;
                        return [3 /*break*/, 10];
                    case 2:
                        if (!(indexForm === 1)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.eventInfoCompany(form)];
                    case 3:
                        _a.sent();
                        this.btnNextDisabled = false;
                        return [3 /*break*/, 10];
                    case 4:
                        if (!(indexForm === 2)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.eventAccountCompany(form, captchaResponse)];
                    case 5:
                        _a.sent();
                        this.btnNextDisabled = false;
                        return [3 /*break*/, 10];
                    case 6:
                        if (!(indexForm === 3 || indexForm === 4)) return [3 /*break*/, 10];
                        if (!(this.register_type !== "qr")) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.eventNotification(form)];
                    case 7:
                        _a.sent();
                        this.btnNextDisabled = false;
                        return [3 /*break*/, 10];
                    case 8: return [4 /*yield*/, this.eventNotificationSticker(form, captchaResponse)];
                    case 9:
                        _a.sent();
                        this.btnNextDisabled = false;
                        _a.label = 10;
                    case 10: return [3 /*break*/, 12];
                    case 11:
                        this.showModalInfo('modalError');
                        this.forms[indexForm].reset();
                        return [2 /*return*/, false];
                    case 12: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * evento tab notificaciones si esta en el flujo de sticker
     * @param form
     */
    RegistroComponent.prototype.eventNotificationSticker = function (form, captchaResponse) {
        var _this = this;
        if (form.value['elegirCel']) {
            if (form.value['elegirCel'] === "0") {
                //llamar servicio otp
                /* this.dataFlow.otp = 0;
                this.dataFlow.countOtp = 0;
         */
                var numberLocal = undefined;
                if (this.observablesService.getLocalStorage('number')) {
                    numberLocal = this.observablesService.getLocalStorage('number');
                }
                if (this.dataFlow.otpReport === 0 && numberLocal.number != form.value.phoneNumber) {
                    var number = form.value.phoneNumber;
                    /*  this.observablesService.setLocalStorage('number', { number });
            */
                    var body = {
                        Channel: "NBOT04-C001",
                        ClientID: "3999999999",
                        Destination: {
                            "ServiceName": "PartnerService",
                            "ServiceOperation": "sendSmsOtp",
                            "ServiceRegion": "C001",
                            "ServiceVersion": "1.0.0"
                        },
                        Body: {
                            "sendSmsOtpRQ": {
                                "phoneNumber": number,
                                "captchaResponse": captchaResponse
                            }
                        }
                    };
                    this.utilsService.consumeEvent('servicesValidationservicesSendsmsotpPost', body).subscribe(function (data) {
                        if (!data.error) {
                            _this.showModalCallback('modalOtp', form.value, _this.validatorPhoneReport);
                        }
                        else {
                            _this.showModalInfo('ErrorTecnico');
                        }
                    });
                }
                else {
                    this.nextTab();
                }
            }
            else {
                this.nextTab();
            }
        }
        else {
            this.eventNotification(form);
        }
    };
    /**
     * evento tab notificaciones
     * @param form
     */
    RegistroComponent.prototype.eventNotification = function (form) {
        //llamar servicio
        var infoRegister = this.observablesService.getLocalStorage('infoRegister');
        infoRegister[this.tabShow] = this.forms[this.tabShow].value;
        this.observablesService.setLocalStorage('infoRegister', infoRegister);
        this.observablesService.setLocalStorage('infoValidate', this.dataFlow);
        this.registerSend();
    };
    /**
     * event informacion de la cuenta de tu negocio
     * @param form
     */
    RegistroComponent.prototype.eventAccountCompany = function (form, captchaResponse) {
        var _this = this;
        if (form.value['bank_or_nequi'] && form.value['bank_or_nequi'] === 'NEQUI') {
            //enviar modal otp nequi
            //modalOtpNequi
            /*  setTimeout(() => {
               this.codExpiroNequi();
             }, 5000); */
            if (this.dataFlow.otpNequi === 0) {
                var body = {
                    Channel: "NBOT04-C001",
                    ClientID: "3999999999",
                    Destination: {
                        "ServiceName": "PartnerService",
                        "ServiceOperation": "sendOtpNequiAccount",
                        "ServiceRegion": "C001",
                        "ServiceVersion": "1.0.0"
                    },
                    Body: {
                        "sendOtpNequiAccountRQ": {
                            "phoneNumber": this.forms[0].value.phoneNumber,
                            "captchaResponse": captchaResponse
                        }
                    }
                };
                this.utilsService.consumeEvent('servicesValidationservicesSendotpnequiaccountPost', body).subscribe(function (data) {
                    if (!data.error) {
                        _this.showModalCallback('modalOtpNequi', _this.forms[0].value, _this.validatorNequi);
                    }
                });
            }
            else {
                this.clevertapService.sendEvent('Registro_recaudo_cuenta_nequi', null);
                this.nextTab();
            }
        }
        else {
            //llamar serivicio cuenta valida con cc o nit
            var account = void 0;
            switch (this.dataFlow.flow.join(' ')) {
                case "no_nequi registro_nit":
                    account = {
                        accountNumber: form.value.number_si_nequi,
                        idType: "NIT",
                        idNumber: this.forms[1].value.nit,
                        accountType: form.value.type_si_nequi
                    };
                    this.typeAccount = "NIT";
                    break;
                case "nequi no_registro_nit":
                    account = {
                        accountNumber: form.value.accountNum_nequi,
                        idType: this.forms[0].value.documentType,
                        idNumber: this.forms[0].value.documentNumber,
                        accountType: form.value.type_nequi
                    };
                    this.typeAccount = this.forms[0].value.documentType;
                    break;
                case "no_nequi no_registro_nit":
                    account = {
                        accountNumber: form.value.number_no_nequi,
                        idType: this.forms[0].value.documentType,
                        idNumber: this.forms[0].value.documentNumber,
                        accountType: form.value.type_no_nequi
                    };
                    this.typeAccount = this.forms[0].value.documentType;
                    break;
                case "nequi registro_nit":
                    account = {
                        accountNumber: form.value.number_si_nequi,
                        idType: "NIT",
                        idNumber: this.forms[1].value.nit,
                        accountType: form.value.type_si_nequi
                    };
                    this.typeAccount = "NIT";
                    break;
            }
            account.captchaResponse = captchaResponse;
            var body = {
                Channel: "NBOT04-C001",
                ClientID: "3999999999",
                Destination: {
                    "ServiceName": "PartnerService",
                    "ServiceOperation": "validateBancolombiaAccount",
                    "ServiceRegion": "C001",
                    "ServiceVersion": "1.0.0"
                },
                Body: {
                    "validateBancolombiaAccountsRQ": account,
                }
            };
            this.utilsService.consumeEvent('servicesValidationservicesValidatebancolombiaaccountPost', body).subscribe(function (data) {
                if (!data.error) {
                    _this.dataFlow.cuenta.valid = 1;
                    _this.clevertapService.sendEvent('Registro_recaudo_cuenta_bancolombia', null);
                    _this.nextTab();
                }
                else {
                    if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-09A') {
                        _this.dataFlow.cuenta.valid = 1;
                        _this.nextTab();
                    }
                    else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === 'G-07A') {
                        _this.dataFlow.cuenta.valid = 0;
                        _this.showModalInfo('CaptchaError');
                    }
                    else {
                        _this.dataFlow.cuenta.valid = 0;
                        // validar que caso es
                        if (_this.typeAccount == "NIT") {
                            _this.showModalInfo('modalErrorAccountNit');
                        }
                        else {
                            _this.showModalInfo('modalErrorAccountCc');
                        }
                    }
                }
            });
        }
    };
    /**
     * evento para informacion del negocio funtion: async-await
     * @param form
     */
    RegistroComponent.prototype.eventInfoCompany = function (form) {
        return __awaiter(this, void 0, void 0, function () {
            var commerceExist, listCommerce, listRepresentative, commerceExist;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!((form.value['commercialRegistry'] && form.value['commercialRegistry'] === "1") || (this.register_type === 'armario'))) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.validateCommerce(true, form)];
                    case 1:
                        commerceExist = _a.sent();
                        if (!commerceExist) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.validateRiskCentrals(true, form)];
                    case 2:
                        listCommerce = _a.sent();
                        this.dataFlow.comercio.list = listCommerce;
                        if (!(this.dataFlow.comercio.list === 0)) return [3 /*break*/, 4];
                        if (this.dataFlow.nequi == 1) {
                            this.dataFlow.flow = ["nequi", "registro_nit"];
                            this.observablesService.observableRegistro({ flow: ["nequi", "registro_nit"] });
                        }
                        else {
                            this.dataFlow.flow = ["no_nequi", "registro_nit"];
                            this.observablesService.observableRegistro({ flow: ["no_nequi", "registro_nit"] });
                        }
                        return [4 /*yield*/, this.validateRiskCentrals(false, form)];
                    case 3:
                        listRepresentative = _a.sent();
                        this.dataFlow.representante.list = listRepresentative;
                        if (this.dataFlow.representante.ccValid == 1 && this.dataFlow.representante.list == 0 && this.dataFlow.representante.dateEx == 1) {
                            this.clevertapService.sendEvent('Registro_negocio_con_matricula', { 'Categoria_negocio': form.value.category, 'Ciudad': form.value.city, 'Departamento': form.value.department });
                            this.nextTab();
                        }
                        else {
                            this.showModalInfo('modalErrorRepresentante');
                            return [2 /*return*/, false];
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        this.showModalInfo('modalError');
                        return [2 /*return*/, false];
                    case 5: return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, this.validateCommerce(false, form)];
                    case 7:
                        commerceExist = _a.sent();
                        if (commerceExist) {
                            if (this.dataFlow.nequi == 1) {
                                this.dataFlow.flow = ["nequi", "no_registro_nit"];
                                this.observablesService.observableRegistro({ flow: ["nequi", "no_registro_nit"] });
                            }
                            else {
                                this.dataFlow.flow = ["no_nequi", "no_registro_nit"];
                                this.observablesService.observableRegistro({ flow: ["no_nequi", "no_registro_nit"] });
                            }
                            this.clevertapService.sendEvent('Registro_negocio_sin_matricula', { 'Categoria_negocio': form.value.category, 'Ciudad': form.value.city, 'Departamento': form.value.department });
                            this.nextTab();
                        }
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    RegistroComponent.prototype.pad = function (n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    };
    RegistroComponent.prototype.validateRiskCentrals = function (commerce, form) {
        var _this = this;
        var body = {
            Channel: "PN01-C001",
            ClientID: "3999999999",
            Destination: {
                "ServiceName": "ValidationServices",
                "ServiceOperation": "validatePersonInRiskCentrals",
                "ServiceRegion": "C001",
                "ServiceVersion": "1.0.0"
            },
            Body: {}
        };
        var bodyRepresentative = {
            Channel: "NegociosNequi",
            ClientID: "3999999999",
            Destination: {
                "ServiceName": "PartnerService",
                "ServiceOperation": "validatePersonalInformation",
                "ServiceRegion": "C001",
                "ServiceVersion": "1.0.0"
            },
            Body: {}
        };
        if (commerce) {
            body.Body = {
                "validatePersonInRiskCentralsRQ": {
                    "typeId": "NIT",
                    "idNumber": form.value.nit,
                    "name1": form.value.name
                }
            };
        }
        else {
            var is2d = (this.forms[1].value.expeditionDate.day.toString().match(/^\d{2}$/));
            var is2m = (this.forms[1].value.expeditionDate.month.toString().match(/^\d{2}$/));
            body.Body = {
                "validatePersonInRiskCentralsRQ": {
                    "typeId": form.value.documentType,
                    "idNumber": form.value["document" + form.value.documentType],
                    "name1": form.value.representativeName,
                    "expeditionDate": form.value.expeditionDate.year + '-' + (is2m != null ? this.forms[1].value.expeditionDate.month : '0' + this.forms[1].value.expeditionDate.month) + '-' + (is2d != null ? this.forms[1].value.expeditionDate.day : '0' + this.forms[1].value.expeditionDate.day),
                }
            };
            bodyRepresentative.Body = {
                "validatePersonalInformationRQ": {
                    "representative": true,
                    "documentType": form.value.documentType,
                    "documentNumber": form.value["document" + form.value.documentType],
                    "expeditionDate": form.value.expeditionDate.year + '-' + (is2m != null ? this.forms[1].value.expeditionDate.month : '0' + this.forms[1].value.expeditionDate.month) + '-' + (is2d != null ? this.forms[1].value.expeditionDate.day : '0' + this.forms[1].value.expeditionDate.day),
                    "name": form.value.representativeName
                }
            };
        }
        return this.utilsService.consumeEvent('servicesValidationservicesValidatepersoninriskcentralsPost', body).toPromise().then(function (data) { return __awaiter(_this, void 0, void 0, function () {
            var dataRepresentative;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!data.error) return [3 /*break*/, 4];
                        if (!commerce) return [3 /*break*/, 1];
                        return [2 /*return*/, 0];
                    case 1: return [4 /*yield*/, this.utilsService.consumeEvent('servicesValidationservicesValidatepersonalinformationPost', bodyRepresentative).toPromise()];
                    case 2:
                        dataRepresentative = _a.sent();
                        if (!dataRepresentative.error) {
                            return [2 /*return*/, 0];
                        }
                        else {
                            return [2 /*return*/, 1];
                        }
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4: return [2 /*return*/, 1];
                    case 5: return [2 /*return*/];
                }
            });
        }); });
    };
    /**
     * Evento para validar si un comercio esta registrado
     * @param nit <boolean> especifica si el comercio se verifica con nit
     * @param form <object> formulario con los datos necesarios para la verificacion
     */
    RegistroComponent.prototype.validateCommerce = function (nit, form) {
        var _this = this;
        var body = {
            Channel: "PN01-C001",
            ClientID: "3999999999",
            Destination: {
                "ServiceName": "PartnerService",
                "ServiceOperation": "validateUnregisteredCommerce",
                "ServiceRegion": "C001",
                "ServiceVersion": "1.0.0"
            },
            Body: {}
        };
        if (nit) {
            body.Body = {
                "validateUnregisteredCommerceRQ": {
                    "documentType": "NIT",
                    "documentNumber": form.value.nit,
                    "name": form.value.name,
                    "socialReason": form.value.socialReason
                }
            };
        }
        else {
            body.Body = {
                "validateUnregisteredCommerceRQ": {
                    "documentType": this.forms[0].value.documentType,
                    "documentNumber": this.forms[0].value.documentNumber,
                    "name": form.value.name,
                    "socialReason": form.value.socialReason
                }
            };
        }
        return this.utilsService.consumeEvent('servicesValidationservicesValidateunregisteredcommercePost', body).toPromise().then(function (data) {
            if (!data.error) {
                return true;
            }
            else {
                if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-09A') {
                    _this.showModalCallback('commerceExist', form, function (evento) {
                        var thisClass = evento.modelo.extraData.this;
                        thisClass.router.navigate(['/privado/perfil']);
                    });
                }
                return false;
            }
        });
    };
    /**
     * evento para informacion personal
     * @param form
     */
    RegistroComponent.prototype.eventInfoPersonal = function (form, captchaResponse) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var numberLocal, number, body, number;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    //validar nequi
                    // validar cc and ccEx
                    return [4 /*yield*/, this.validateNequiCentralsRisk(form)];
                    case 1:
                        //validar nequi
                        // validar cc and ccEx
                        _a.sent();
                        if (this.dataFlow.nequi === 0 && this.dataFlow.list === 1) {
                            /* this.forms[0].reset(); */
                            return [2 /*return*/, false];
                        }
                        numberLocal = undefined;
                        if (this.observablesService.getLocalStorage('number')) {
                            numberLocal = this.observablesService.getLocalStorage('number');
                        }
                        if (this.dataFlow.otp === 0 || numberLocal.number != form.value.phoneNumber) {
                            number = form.value.phoneNumber;
                            this.observablesService.setLocalStorage('number', { number: number });
                            body = {
                                Channel: "NBOT04-C001",
                                ClientID: "3999999999",
                                Destination: {
                                    "ServiceName": "PartnerService",
                                    "ServiceOperation": "sendSmsOtp",
                                    "ServiceRegion": "C001",
                                    "ServiceVersion": "1.0.0"
                                },
                                Body: {
                                    "sendSmsOtpRQ": {
                                        "phoneNumber": number,
                                        "captchaResponse": captchaResponse
                                    }
                                }
                            };
                            this.utilsService.consumeEvent('servicesValidationservicesSendsmsotpPost', body).subscribe(function (data) {
                                if (!data.error) {
                                    _this.clevertapService.sendEvent('Inicio_registro_negocios', { 'Tipo_documento': form.value.documentType.toUpperCase() });
                                    _this.showModalCallback('modalOtp', form.value, _this.validatorPhone);
                                }
                                else {
                                    if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === 'G-07A') {
                                        form.reset();
                                        _this.showModalCallback('modalOtp', form.value, _this.validatorPhone);
                                        //this.showModalInfo('CaptchaError');
                                    }
                                    else {
                                        _this.showModalInfo('ErrorTecnico');
                                    }
                                }
                            });
                        }
                        else {
                            number = form.value.phoneNumber;
                            this.observablesService.setLocalStorage('number', { number: number });
                            this.clevertapService.sendEvent('Inicio_registro_negocios', { 'Tipo_documento': form.value.documentType.toUpperCase() });
                            this.nextTab();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    RegistroComponent.prototype.validateNequiCentralsRisk = function (form) {
        var _this = this;
        var is2d = (this.forms[0].value.documentExpeditionDate.day.toString().match(/^\d{2}$/));
        var is2m = (this.forms[0].value.documentExpeditionDate.month.toString().match(/^\d{2}$/));
        var body = {
            Channel: "NegociosNequi",
            ClientID: "3999999999",
            Destination: {
                "ServiceName": "PartnerService",
                "ServiceOperation": "validatePersonalInformation",
                "ServiceRegion": "C001",
                "ServiceVersion": "1.0.0"
            },
            Body: {
                "validatePersonalInformationRQ": {
                    "phoneNumber": form.value.phoneNumber,
                    "documentType": form.value.documentType.toUpperCase(),
                    "documentNumber": form.value.documentNumber,
                    "expeditionDate": form.value.documentExpeditionDate.year + '-' + (is2m != null ? this.forms[0].value.documentExpeditionDate.month : '0' + this.forms[0].value.documentExpeditionDate.month) + '-' + (is2d != null ? this.forms[0].value.documentExpeditionDate.day : '0' + this.forms[0].value.documentExpeditionDate.day),
                }
            }
        };
        return this.utilsService.consumeEvent('servicesValidationservicesValidatepersonalinformationPost', body).toPromise().then(function (data) {
            if (!data.error) {
                if (data.validatePersonalInformationRQ.nequi) {
                    _this.dataFlow.nequi = 1;
                }
                else {
                    _this.dataFlow.nequi = 0;
                }
                _this.dataFlow.list = 0;
            }
            else {
                _this.dataFlow.nequi = 0;
                _this.dataFlow.list = 1;
                if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode != '20-07A') {
                    _this.showModalInfo('modalError');
                }
            }
        });
    };
    /**
     * @description - Valida si en el formulario actual existe un input del tipo terminos_product
     */
    RegistroComponent.prototype.getTycProductInput = function () {
        var _this = this;
        var messages = this.messages[this.tabShow];
        var inputs = messages ? messages.inputs : [];
        var inputsTyC = inputs.filter(function (input) { return input.name === RegistryConfig.controlTyC; });
        if (inputsTyC.length === 1) {
            return inputsTyC[0];
        }
        return inputsTyC.find(function (input) { return input.dependence.name === _this.type_api; });
    };
    /**
   * @description - Valida si en las opciones configuradas del producto de registro se encuentra la propiedad pdf
   */
    RegistroComponent.prototype.getProductPdf = function (tycProductInput) {
        var options = tycProductInput.options || [];
        var product = options.find(function (option) { return option.hasOwnProperty(RegistryConfig.propertyPdf); });
        return product ? product.pdf : undefined;
    };
    RegistroComponent.prototype.removeElementsFromArray = function (array) {
        while (array.length !== 0) {
            array.removeAt(0);
        }
    };
    RegistroComponent.prototype.getProductName = function () {
        var form = this.forms[this.tabShow];
        if (!form.value.hasOwnProperty(RegistryConfig.controlTyC)) {
            return;
        }
        var productInput = this.getTycProductInput();
        if (!productInput) {
            return;
        }
        var product = this.getProductPdf(productInput);
        if (product) {
            var tycInput = form.get(RegistryConfig.controlTyC);
            this.removeElementsFromArray(tycInput);
            var control = new FormControl(product);
            tycInput.push(control);
        }
    };
    /**
     * Evento siguiente registro
     */
    RegistroComponent.prototype.nextTab = function () {
        var infoRegister = this.observablesService.getLocalStorage('infoRegister');
        if (!infoRegister) {
            var array = [];
            array[this.tabShow] = this.forms[this.tabShow].value;
            this.observablesService.setLocalStorage('infoRegister', array);
        }
        else {
            infoRegister[this.tabShow] = this.forms[this.tabShow].value;
            this.observablesService.setLocalStorage('infoRegister', infoRegister);
        }
        this.observablesService.setLocalStorage('infoValidate', this.dataFlow);
        this.tabShow++;
        window.scrollTo(0, 0);
        var url;
        if (this.tabShow == 1) {
            url = "Informacion del negocio";
        }
        else if (this.tabShow == 2) {
            url = "la cuenta de tu negocio";
        }
        else if (this.tabShow == 3 || this.tabShow == 4) {
            this.getProductName();
            url = "Notificaciones y reportes";
        }
        this.eventClick(url);
        /* console.log(this.dataFlow.flow);
        console.log(this.forms); */
    };
    RegistroComponent.prototype.eventClick = function (url) {
        this.googleAnalyticsService.emitPage(url);
    };
    /**
     * Evento atras registro
     */
    RegistroComponent.prototype.backTab = function () {
        /* if(this.tabShow == 2){
          this.messages[this.tabShow].inputs.map((input)=>{
            input.display = false;
          });
        } */
        this.tabShow--;
    };
    /**
    * Callback que devuelve el modal otp nequi
    * @param evento
    */
    RegistroComponent.prototype.validatorNequi = function (evento) {
        var _this = this;
        /* console.log("seleccionado en boton header: " + evento.source.text); */
        //servicio validador de OTP
        var codOtp = evento.modelo.dataModal.otpcodigo;
        var thisClass = evento.modelo.extraData.this;
        var form = evento.modelo.extraData.form;
        if (evento.modelo) {
            //llamar servicio otp app nequi
            var body = {
                Channel: "NBOT04-C001",
                ClientID: "3999999999",
                Destination: {
                    "ServiceName": "PartnerService",
                    "ServiceOperation": "validateOtpNequiAccount",
                    "ServiceRegion": "C001",
                    "ServiceVersion": "1.0.0"
                },
                Body: {
                    "validateOtpNequiAccountRQ": {
                        "phoneNumber": form.phoneNumber,
                        "otp": codOtp
                    }
                }
            };
            thisClass.utilsService.consumeEvent('servicesValidationservicesValidateotpnequiaccountPost', body).subscribe(function (data) {
                if (!data.error) {
                    thisClass.dataFlow.otpNequi = 1;
                }
                else {
                    thisClass.dataFlow.otpNequi = 0;
                }
                if (thisClass.dataFlow.otpNequi == 0 && thisClass.dataFlow.countOtpNequi < 3) {
                    if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'BAD_CODE') {
                        thisClass.dataFlow.countOtpNequi++;
                        thisClass.noValidOtpNequi(form);
                    }
                    else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'EXPIRED_CODE') {
                        thisClass.codExpiroNequi();
                    }
                }
                else if (thisClass.dataFlow.countOtpNequi == 3) {
                    if (thisClass.dataFlow.tryOtpNequi < 1) {
                        thisClass.countOtpNequi(form);
                    }
                    else {
                        thisClass.tryTwoNequi(form);
                    }
                }
                else if (thisClass.dataFlow.otpNequi == 1) {
                    _this.clevertapService.sendEvent('Registro_recaudo_cuenta_nequi', null);
                    thisClass.nextTab();
                }
            });
        }
    };
    RegistroComponent.prototype.codExpiroNequi = function () {
        if (this.dataFlow.otpNequi === 0) {
            if (this.dataFlow.tryOtpNequi < 1) {
                this.showModalInfo('errorOtpNequiTime');
                this.dataFlow.countOtpNequi = 0;
                this.dataFlow.tryOtpNequi++;
                return false;
            }
            else {
                this.tryTwoNequi();
            }
            // reset forms
        }
    };
    RegistroComponent.prototype.tryTwoNequi = function () {
        this.showModalInfo('errorOtpNequiTryTwo');
        this.dataFlow = {
            nequi: 1,
            ahorroApi: 1,
            otp: 0,
            countOtp: 0,
            otpReport: 0,
            countOtpReport: 0,
            otpEmail: 0,
            countOtpEmail: 0,
            otpNequi: 0,
            countOtpNequi: 0,
            tryOtpNequi: 0,
            bloqueo: 0,
            list: 0,
            comercio: {
                list: 0,
                name: 0
            },
            representante: {
                ccValid: 1,
                list: 0,
                dateEx: 1
            },
            cuenta: {
                valid: 1
            },
            location: {
                department: [],
                city: []
            },
            flow: []
        };
        // reset all forms
        /*  this.forms.map((form) => {
           form.reset();
         }); */
        this.tabShow = 0;
        // reset forms
        return false;
    };
    RegistroComponent.prototype.countOtpNequi = function () {
        this.showModalInfo('errorCountOtpNequi');
        this.dataFlow.countOtpNequi = 0;
        this.dataFlow.tryOtpNequi++;
        // reset forms
        return false;
    };
    RegistroComponent.prototype.noValidOtpNequi = function (form) {
        this.showModalCallback('modalOtpErrorNequi', form, this.validatorNequi);
    };
    /**
     * Callback que devuelve el modal otp
     * @param evento
     */
    RegistroComponent.prototype.validatorPhone = function (evento, captchaResponse) {
        var _this = this;
        /* console.log("seleccionado en boton header: " + evento.source.text); */
        //servicio validador de OTP
        var codOtp = evento.modelo.dataModal.otpcodigo;
        var thisClass = evento.modelo.extraData.this;
        var form = evento.modelo.extraData.form;
        if (evento.source == 'Pedir otro codigo') {
            var body = {
                Channel: "NBOT04-C001",
                ClientID: "3999999999",
                Destination: {
                    "ServiceName": "PartnerService",
                    "ServiceOperation": "sendSmsOtp",
                    "ServiceRegion": "C001",
                    "ServiceVersion": "1.0.0"
                },
                Body: {
                    "sendSmsOtpRQ": {
                        "phoneNumber": form.phoneNumber,
                        "captchaResponse": captchaResponse
                    }
                }
            };
            thisClass.utilsService.consumeEvent('servicesValidationservicesSendsmsotpPost', body).subscribe(function (data) {
                if (!data.error) {
                }
                else {
                    _this.showModalInfo('ErrorTecnico');
                }
            });
        }
        else {
            if (evento.modelo) {
                //llamar servicio  validar
                var body = {
                    Channel: "NBOT04-C001",
                    ClientID: "3999999999",
                    Destination: {
                        "ServiceName": "PartnerService",
                        "ServiceOperation": "validateSmsOtp",
                        "ServiceRegion": "C001",
                        "ServiceVersion": "1.0.0"
                    },
                    Body: {
                        "validateSmsOtpRQ": {
                            "phoneNumber": form.phoneNumber,
                            "otp": codOtp
                        }
                    }
                };
                thisClass.utilsService.consumeEvent('servicesValidationservicesValidatesmsotpPost', body).subscribe(function (data) {
                    if (!data.error) {
                        thisClass.dataFlow.otp = 1;
                        thisClass.clevertapService.sendEvent('Confirmacion_registro_otp', null);
                    }
                    else {
                        thisClass.dataFlow.otp = 0;
                    }
                    if (thisClass.dataFlow.otp == 0 && thisClass.dataFlow.countOtp < 3) {
                        if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'BAD_CODE') {
                            thisClass.dataFlow.countOtp++;
                            thisClass.noValidOtp(form);
                        }
                        else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'EXPIRED_CODE') {
                            thisClass.expiroOtpPhone(form);
                        }
                    }
                    else if (thisClass.dataFlow.countOtp == 3) {
                        thisClass.countOtp(form);
                    }
                    else if (thisClass.dataFlow.otp == 1) {
                        thisClass.nextTab();
                    }
                });
            }
        }
    };
    /**
     * Callback que devuelve el modal otp
     * @param evento
     */
    RegistroComponent.prototype.validatorPhoneReport = function (evento) {
        /* console.log("seleccionado en boton header: " + evento.source.text); */
        //servicio validador de OTP
        var codOtp = evento.modelo.dataModal.otpcodigo;
        var thisClass = evento.modelo.extraData.this;
        var form = evento.modelo.extraData.form;
        if (evento.modelo) {
            //llamar servicio  validar
            var body = {
                Channel: "NBOT04-C001",
                ClientID: "3999999999",
                Destination: {
                    "ServiceName": "PartnerService",
                    "ServiceOperation": "validateSmsOtp",
                    "ServiceRegion": "C001",
                    "ServiceVersion": "1.0.0"
                },
                Body: {
                    "validateSmsOtpRQ": {
                        "phoneNumber": form.phoneNumber,
                        "otp": codOtp
                    }
                }
            };
            thisClass.utilsService.consumeEvent('servicesValidationservicesValidatesmsotpPost', body).subscribe(function (data) {
                if (!data.error) {
                    thisClass.dataFlow.otpReport = 1;
                }
                else {
                    thisClass.dataFlow.otpReport = 0;
                }
                if (thisClass.dataFlow.otpReport == 0 && thisClass.dataFlow.countOtpReport < 3) {
                    if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'BAD_CODE') {
                        thisClass.dataFlow.countOtpReport++;
                        thisClass.noValidOtp(form);
                    }
                    else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'EXPIRED_CODE') {
                        thisClass.expiroOtpPhone(form);
                    }
                }
                else if (thisClass.dataFlow.countOtpReport == 3) {
                    thisClass.countOtp(form);
                }
                else if (thisClass.dataFlow.otpReport == 1) {
                    thisClass.nextTab();
                }
            });
        }
    };
    RegistroComponent.prototype.expiroOtpPhone = function () {
        this.dataFlow.otp == 0;
        this.dataFlow.countOtp == 0;
        this.dataFlow.otpReport == 0;
        this.dataFlow.countOtpReport == 0;
        this.showModalInfo('codigoExpiroPhone');
    };
    RegistroComponent.prototype.countOtp = function () {
        this.showModalInfo('errorCountOtp');
        this.dataFlow.countOtp = 0;
        this.dataFlow.countOtpReport == 0;
        // reset forms
        return false;
    };
    RegistroComponent.prototype.noValidOtp = function (form) {
        this.showModalCallback('modalOtpError', form, this.validatorPhone);
    };
    /**
     * Callback que devuelve el modal otp email
     * @param evento
     */
    RegistroComponent.prototype.validatorEmail = function (evento) {
        var _this = this;
        /* console.log("seleccionado en boton header: " + evento.source.text); */
        //servicio validador de OTP email
        var codOtp = evento.modelo.dataModal.otpcodigo;
        var thisClass = evento.modelo.extraData.this;
        var form = evento.modelo.extraData.form;
        if (evento.modelo) {
            //llamar servicio otp email          this.observablesService.setLocalStorage('infoRegister', []);
            var body = {
                Channel: "NBOT04-C001",
                ClientID: "3999999999",
                Destination: {
                    "ServiceName": "PartnerService",
                    "ServiceOperation": "validateEmail",
                    "ServiceRegion": "C001",
                    "ServiceVersion": "1.0.0"
                },
                Body: {
                    "validateEmailRQ": {
                        "username": form.username,
                        "confirmationCode": codOtp
                    }
                }
            };
            thisClass.utilsService.consumeEvent('servicesValidationservicesValidateemailPost', body).subscribe(function (data) {
                if (!data.error) {
                    thisClass.dataFlow.otpEmail = 1;
                    thisClass.clevertapService.sendEvent('Finalizacion_registro_negocios', { 'Email_registro': form.email });
                }
                else {
                    thisClass.dataFlow.otpEmail = 0;
                }
                if (thisClass.dataFlow.otpEmail == 0 && thisClass.dataFlow.countOtpEmail < 3) {
                    if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'NOT_VALID_CODE' || data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'NOT_VALID_STATUS') {
                        thisClass.dataFlow.countOtpEmail++;
                        thisClass.noValidOtpEmail(form);
                    }
                    else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'EXPIRED_CODE') {
                        thisClass.expiroOtpEmail(form);
                    }
                }
                else if (thisClass.dataFlow.countOtpEmail == 3) {
                    thisClass.countOtpEmail(form);
                }
                else if (thisClass.dataFlow.otpEmail == 1) {
                    if (data.validateEmailRS.validated == "true") {
                        thisClass.registerSuccess = true;
                    }
                    else {
                        thisClass.registerSuccessConfirmation = true;
                    }
                    var dataFlow = {
                        nequi: 1,
                        ahorroApi: 1,
                        otp: 0,
                        countOtp: 0,
                        otpReport: 0,
                        countOtpReport: 0,
                        otpEmail: 0,
                        countOtpEmail: 0,
                        otpNequi: 0,
                        countOtpNequi: 0,
                        tryOtpNequi: 0,
                        bloqueo: 0,
                        list: 0,
                        comercio: {
                            list: 0,
                            name: 0
                        },
                        representante: {
                            ccValid: 1,
                            list: 0,
                            dateEx: 1
                        },
                        cuenta: {
                            valid: 1
                        },
                        location: {
                            department: [],
                            city: []
                        },
                        flow: []
                    };
                    _this.observablesService.setLocalStorage('infoRegister', {});
                    _this.observablesService.setLocalStorage('infoValidate', dataFlow);
                    window.scrollTo(0, 0);
                }
            });
        }
    };
    RegistroComponent.prototype.expiroOtpEmail = function (form) {
        var _this = this;
        var body = {
            Channel: "NegociosNequi",
            ClientID: "3999999999",
            Destination: {
                "ServiceName": "PartnerService",
                "ServiceOperation": "deletePartner",
                "ServiceRegion": "C001",
                "ServiceVersion": "1.0.0"
            },
            Body: {
                "deletePartnerRQ": {
                    "username": form.username
                }
            }
        };
        this.utilsService.consumeEvent('servicesPartnerservicesDeletepartnerPost', body).subscribe(function (data) {
            if (!data.error) {
                _this.showModalInfo('codigoExpiro');
                _this.dataFlow = {
                    nequi: 1,
                    ahorroApi: 1,
                    otp: 0,
                    countOtp: 0,
                    otpReport: 0,
                    countOtpReport: 0,
                    otpEmail: 0,
                    countOtpEmail: 0,
                    otpNequi: 0,
                    countOtpNequi: 0,
                    tryOtpNequi: 0,
                    bloqueo: 0,
                    list: 0,
                    comercio: {
                        list: 0,
                        name: 0
                    },
                    representante: {
                        ccValid: 1,
                        list: 0,
                        dateEx: 1
                    },
                    cuenta: {
                        valid: 1
                    },
                    location: {
                        department: [],
                        city: []
                    },
                    flow: []
                };
                /* this.forms[0].reset();
                this.forms[1].reset();
                this.forms[2].reset();
                this.forms[3].reset();
        
        
                if (this.forms.length === 5) {
                  this.forms[4].reset();
                }
         */
                _this.tabShow = 0;
                return false;
            }
            else {
                if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'USER_NOT_FOUND' || data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'NOT_VALID_STATUS') {
                    _this.showModalInfo('ErrorTecnico');
                }
            }
        });
    };
    RegistroComponent.prototype.countOtpEmail = function (form) {
        var _this = this;
        var body = {
            Channel: "NegociosNequi",
            ClientID: "3999999999",
            Destination: {
                "ServiceName": "PartnerService",
                "ServiceOperation": "deletePartner",
                "ServiceRegion": "C001",
                "ServiceVersion": "1.0.0"
            },
            Body: {
                "deletePartnerRQ": {
                    "username": form.username
                }
            }
        };
        this.utilsService.consumeEvent('servicesPartnerservicesDeletepartnerPost', body).subscribe(function (data) {
            if (!data.error) {
                _this.showModalInfo('errorCountOtpEmail');
                _this.dataFlow = {
                    nequi: 1,
                    ahorroApi: 1,
                    otp: 0,
                    countOtp: 0,
                    otpReport: 0,
                    countOtpReport: 0,
                    otpEmail: 0,
                    countOtpEmail: 0,
                    otpNequi: 0,
                    countOtpNequi: 0,
                    tryOtpNequi: 0,
                    bloqueo: 0,
                    list: 0,
                    comercio: {
                        list: 0,
                        name: 0
                    },
                    representante: {
                        ccValid: 1,
                        list: 0,
                        dateEx: 1
                    },
                    cuenta: {
                        valid: 1
                    },
                    location: {
                        department: [],
                        city: []
                    },
                    flow: []
                };
                /* this.forms[0].reset();
                this.forms[1].reset();
                this.forms[2].reset();
                this.forms[3].reset();
        
        
                if (this.forms.length === 5) {
                  this.forms[4].reset();
                } */
                _this.tabShow = 0;
                return false;
            }
            else {
                if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'USER_NOT_FOUND' || data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'NOT_VALID_STATUS') {
                    _this.showModalInfo('ErrorTecnico');
                }
            }
        });
    };
    RegistroComponent.prototype.noValidOtpEmail = function (form) {
        this.showModalCallback('modalOtpErrorEmail', form, this.validatorEmail);
    };
    /**
     * Evento que finaliza el registro
     */
    RegistroComponent.prototype.registerSend = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var business, schedule, account, notificationsAndReports, product, formOtp, is2d_1, is2m_1, category, category, days, key, sche, password, password, _a, is2d, is2m, body;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        business = {};
                        schedule = [];
                        account = {};
                        notificationsAndReports = {};
                        product = {};
                        if ((this.forms[1].value.commercialRegistry && this.forms[1].value.commercialRegistry === "1") || (this.register_type === 'armario')) {
                            is2d_1 = (this.forms[1].value.expeditionDate.day.toString().match(/^\d{2}$/));
                            is2m_1 = (this.forms[1].value.expeditionDate.month.toString().match(/^\d{2}$/));
                            category = void 0;
                            if (!!this.forms[1].value.category) {
                                category = this.forms[1].value.category;
                            }
                            business = {
                                commercialRegistry: "true",
                                socialReason: this.forms[1].value.socialReason,
                                name: this.forms[1].value.name.trim(),
                                category: category,
                                nit: this.forms[1].value.nit,
                                legalRepresentative: {
                                    name: this.forms[1].value.representativeName,
                                    documentType: this.forms[1].value.documentType,
                                    document: this.forms[1].value["document" + this.forms[1].value.documentType],
                                    expeditionDate: this.forms[1].value.expeditionDate.year + '-' + (is2m_1 != null ? this.forms[1].value.expeditionDate.month : '0' + this.forms[1].value.expeditionDate.month) + '-' + (is2d_1 != null ? this.forms[1].value.expeditionDate.day : '0' + this.forms[1].value.expeditionDate.day),
                                },
                                department: this.forms[1].value.department,
                                city: this.forms[1].value.city,
                                address: this.forms[1].value.d_tipo_direccion + ' ' + this.forms[1].value.d_direccion.trim() + ' # ' + this.forms[1].value.d_num_direccion_1.trim() + ' - ' + this.forms[1].value.d_num_direccion_2.trim() + ' ' + this.forms[1].value.info_extra.trim(),
                                webPage: this.forms[1].value.webPage,
                                physicalStore: (this.forms[1].value.physicalStore === '1') ? 'true' : 'false'
                            };
                            if (this.register_type != 'armario')
                                business.legalRepresentative.name = this.forms[1].value.representativeName;
                        }
                        else if (this.forms[1].value.commercialRegistry && this.forms[1].value.commercialRegistry === "0") {
                            category = void 0;
                            if (!!this.forms[1].value.category) {
                                category = this.forms[1].value.category;
                            }
                            business = {
                                commercialRegistry: "false",
                                name: this.forms[1].value.name.trim(),
                                category: category,
                                department: this.forms[1].value.department,
                                city: this.forms[1].value.city,
                                address: this.forms[1].value.d_tipo_direccion + ' ' + this.forms[1].value.d_direccion.trim() + ' # ' + this.forms[1].value.d_num_direccion_1.trim() + ' - ' + this.forms[1].value.d_num_direccion_2.trim() + ' ' + this.forms[1].value.info_extra.trim(),
                                physicalStore: (this.forms[1].value.physicalStore === '1') ? 'true' : 'false'
                            };
                            business.webPage = this.forms[1].value.webPage;
                        }
                        if (!!this.forms[1].value.physicalStore && this.forms[1].value.physicalStore === '1') {
                            days = { 'Lunes': 'MONDAY', 'Martes': 'TUESDAY', 'Miercoles': 'WEDNESDAY', 'Jueves': 'THRUSDAY', 'Viernes': 'FRIDAY', 'Sabado': 'SATURDAY', 'Domingo': 'SUNDAY', 'Festivos': 'HOLIDAYS' };
                            for (key in days) {
                                if (this.forms[1].value[key] == '1') {
                                    sche = {
                                        "day": days[key],
                                        "am": {
                                            "since": this.forms[1].value['desdeAm' + key],
                                            "to": this.forms[1].value['hastaAm' + key]
                                        },
                                        "pm": {
                                            "since": this.forms[1].value['desdePm' + key],
                                            "to": this.forms[1].value['hastaPm' + key]
                                        }
                                    };
                                    schedule.push(sche);
                                }
                            }
                            if (schedule.length === 0) {
                                schedule = [{}];
                            }
                            business.schedule = schedule;
                        }
                        switch (this.dataFlow.flow.join(' ')) {
                            case "nequi registro_nit":
                                account = {
                                    bank: "BANCOLOMBIA",
                                    type: this.forms[2].value.type_si_nequi,
                                    number: this.forms[2].value.number_si_nequi
                                };
                                break;
                            case "no_nequi registro_nit":
                                account = {
                                    bank: "BANCOLOMBIA",
                                    type: this.forms[2].value.type_si_nequi,
                                    number: this.forms[2].value.number_si_nequi
                                };
                                break;
                            case "nequi no_registro_nit":
                                if (this.forms[2].value.bank_or_nequi == 'NEQUI') {
                                    account = {
                                        bank: this.forms[2].value.bank_or_nequi,
                                        type: "37",
                                        number: this.forms[0].value.phoneNumber
                                    };
                                }
                                else {
                                    account = {
                                        bank: this.forms[2].value.bank_or_nequi,
                                        type: this.forms[2].value.type_nequi,
                                        number: this.forms[2].value.accountNum_nequi
                                    };
                                }
                                break;
                            case "no_nequi no_registro_nit":
                                account = {
                                    bank: "BANCOLOMBIA",
                                    type: this.forms[2].value.type_no_nequi,
                                    number: this.forms[2].value.number_no_nequi
                                };
                                break;
                        }
                        if (!(this.register_type === "qr")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.utilsService.encryptString(this.forms[4].value.password)];
                    case 1:
                        password = _b.sent();
                        notificationsAndReports = {
                            email: this.forms[4].value.email,
                            username: this.forms[4].value.username,
                            password: password,
                            monthlyReports: "true"
                        };
                        if (this.forms[3].value.elegirCel == '0') {
                            notificationsAndReports.phoneNumber = this.forms[3].value.phoneNumber;
                        }
                        product = {
                            productName: this.forms[4].value.terminos_product.length > 0 ? this.forms[4].value.terminos_product[0] : 'APIS_DISPERSIONES'
                        };
                        formOtp = this.forms[4].value;
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.utilsService.encryptString(this.forms[3].value.password)];
                    case 3:
                        password = _b.sent();
                        notificationsAndReports = {
                            email: this.forms[3].value.email,
                            username: this.forms[3].value.username,
                            password: password,
                            dailyReports: ((!!this.forms[3].value.dailyReports && this.forms[3].value.dailyReports.length == 1) || (this.register_type == 'armario')) ? 'true' : 'false',
                            monthlyReports: ((!!this.forms[3].value.monthlyReports && this.forms[3].value.monthlyReports.length == 1) || (this.register_type == 'armario')) ? 'true' : 'false'
                        };
                        if (this.forms[3].value.terminos_product.length > 0) {
                            product['productName'] = this.forms[3].value.terminos_product[0];
                        }
                        else if (this.register_type === 'armario' || this.register_type === 'dispersiones') {
                            product['productName'] = this.register_type === 'armario' ? 'ARMARIO' : 'APIS_DISPERSIONES';
                        }
                        formOtp = this.forms[3].value;
                        _b.label = 4;
                    case 4:
                        if (!(product.productName != 'APIS_DISPERSIONES')) return [3 /*break*/, 6];
                        _a = product;
                        return [4 /*yield*/, this.getIdContract(product.productName)];
                    case 5:
                        _a.contractId = _b.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        product.contractId = 'N/A';
                        _b.label = 7;
                    case 7:
                        is2d = (this.forms[0].value.documentExpeditionDate.day.toString().match(/^\d{2}$/));
                        is2m = (this.forms[0].value.documentExpeditionDate.month.toString().match(/^\d{2}$/));
                        if (product.productName === 'QR_ESTATICO' && !!this.externalPartner) {
                            product.externalPartner = this.externalPartner;
                        }
                        body = {
                            Channel: "NegociosNequi",
                            ClientID: "3999999999",
                            Destination: {
                                ServiceName: "PartnerService",
                                ServiceOperation: "createPartner",
                                ServiceRegion: "C001",
                                ServiceVersion: "1.0.0"
                            },
                            Body: {
                                createPartnerRQ: {
                                    personal: {
                                        name: this.forms[0].value.name.trim(),
                                        documentType: this.forms[0].value.documentType,
                                        documentNumber: this.forms[0].value.documentNumber,
                                        documentExpeditionDate: this.forms[0].value.documentExpeditionDate.year + '-' + (is2m != null ? this.forms[0].value.documentExpeditionDate.month : '0' + this.forms[0].value.documentExpeditionDate.month) + '-' + (is2d != null ? this.forms[0].value.documentExpeditionDate.day : '0' + this.forms[0].value.documentExpeditionDate.day),
                                        phoneNumber: this.forms[0].value.phoneNumber,
                                        acceptHabeasData: (this.forms[0].value.acceptHabeasData.length == 1) ? 'true' : 'false'
                                    },
                                    business: business,
                                    account: account,
                                    notificationsAndReports: notificationsAndReports,
                                    product: product
                                }
                            }
                        };
                        this.utilsService.consumeEvent('servicesPartnerservicesCreatepartnerPost', body).subscribe(function (data) {
                            if (!data.error) {
                                if (_this.dataFlow.otpEmail === 0) {
                                    _this.showModalCallback('modalOtpEmail', formOtp, _this.validatorEmail);
                                }
                            }
                            else {
                                if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc === "USERNAME_ALREADY_EXISTS") {
                                    _this.showModalInfo('errorUserExist');
                                }
                                else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc === "EMAIL_ALREADY_EXISTS") {
                                    _this.showModalInfo('errorEmailExist');
                                }
                                else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-08A') {
                                    if (_this.typeAccount == "NIT") {
                                        _this.showModalInfo('modalErrorAccountNit');
                                    }
                                    else {
                                        _this.showModalInfo('modalErrorAccountCc');
                                    }
                                }
                                else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-09A') {
                                    _this.showModalInfo('modalErrorDataBad');
                                }
                                else {
                                    _this.showModalInfo('ErrorTecnico');
                                }
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    RegistroComponent.prototype.getIdContract = function (contract) {
        var body = {
            Channel: "NegociosNequi-Nequi",
            ClientID: "3999999999",
            Destination: {
                "ServiceName": "GeneralServices",
                "ServiceOperation": "getContractInfoByProduct",
                "ServiceRegion": "C001",
                "ServiceVersion": "1.0.0"
            },
            Body: {
                "getContractInfoByProductRQ": {
                    "product": contract
                }
            }
        };
        return this.utilsService.consumeEvent('servicesGeneralservicesGetcontractinfobyproductPost', body).toPromise().then(function (data) {
            return data.getContractInfoByProductRS[contract].id;
        });
    };
    /**
     * modal de solo informacion
     * @param modelo
     */
    RegistroComponent.prototype.showModalInfo = function (modelo) {
        this.observablesService.showModal({
            modelo: modelo,
            extraData: {},
        });
    };
    /**
     * modal que retorna callback
     * @param modelo nombre del tipo de modal
     * @param form datos del tab (registro) de donde salio el modal
     * @param fn callback que retorna el modal
     */
    RegistroComponent.prototype.showModalCallback = function (modelo, form, fn) {
        this.observablesService.showModal({
            modelo: modelo,
            extraData: {
                form: form,
                this: this
            },
            clicFunc: fn
        });
    };
    RegistroComponent.prototype.eventSoyNequi = function () {
        if (this.register_type == 'api') {
            this.router.navigate(['/privado/agregar-producto/' + this.type_api]);
        }
        else if (this.register_type != 'armario') {
            this.router.navigate(['/privado/agregar-producto/' + this.register_type]);
        }
        else {
            this.router.navigate(['/privado/perfil']);
        }
    };
    /**
     * show TyC for checkbox
     * @param option
     */
    RegistroComponent.prototype.showModalTyc = function (option) {
        var _this = this;
        //servicio contrato
        if (option.option.pdf === 'TERMS_CONDITIONS') {
            window.open(RegistryConfig.clauseURL, '_blank');
        }
        else {
            var body = {
                Channel: "NegociosNequi-Nequi",
                ClientID: "3999999999",
                Destination: {
                    "ServiceName": "GeneralServices",
                    "ServiceOperation": "getContractInfoByProduct",
                    "ServiceRegion": "C001",
                    "ServiceVersion": "1.0.0"
                },
                Body: {
                    "getContractInfoByProductRQ": {
                        "product": option.option.pdf
                    }
                }
            };
            this.utilsService.consumeEvent('servicesGeneralservicesGetcontractinfobyproductPost', body).subscribe(function (data) {
                _this.dataPdf = data.getContractInfoByProductRS[option.option.pdf].url;
                window.open(_this.dataPdf, "_blank", "noopener,noreferrer");
                /* this.modalService.open(option.content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'modalTyc' }).result.then((result) => {
                  this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }); */
            });
        }
    };
    RegistroComponent.prototype.getDismissReason = function (reason) {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    return RegistroComponent;
}());
export { RegistroComponent };
export { ɵ0 };
