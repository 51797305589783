var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { JsonProvider, AuthService, GoogleAnalyticsService, UtilsService, ObservablesService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';
var ɵ0 = {
    siteKey: environment.googleCaptchaKey,
};
var LoginComponent = /** @class */ (function () {
    function LoginComponent(jsonProvider, formBuilder, requestAuth, router, route, googleAnalyticsService, utilsService, observablesService) {
        var _this = this;
        this.jsonProvider = jsonProvider;
        this.formBuilder = formBuilder;
        this.requestAuth = requestAuth;
        this.router = router;
        this.route = route;
        this.googleAnalyticsService = googleAnalyticsService;
        this.utilsService = utilsService;
        this.observablesService = observablesService;
        this.submitted = false;
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        if (this.observablesService.getLocalStorage('currentUser') && this.observablesService.getCookie('parameters')) {
            if (this.returnUrl == 'cobros') {
                window.location.href = 'https://cobros.nequi.co';
                /* window.location.href = 'http://localhost:4300'; */
            }
            else {
                this.router.navigate([this.returnUrl]);
            }
        }
        this.jsonProvider.getMessages('login').subscribe(function (data) { return _this.dataLogin = data; });
    }
    LoginComponent.prototype.resolved = function (captchaResponse) {
    };
    LoginComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.utilsService.getParams()];
                    case 1:
                        _a.sent();
                        data = this.observablesService.getLocalStorage('parameters');
                        this.utilsService.setCognitoTemporalKeys(data.getParametersRS).subscribe(function (creds) {
                            _this.utilsService.createNewClient({
                                accessKey: creds.accessKeyId,
                                secretKey: creds.secretAccessKey,
                                sessionToken: creds.sessionToken,
                                apiKey: data.getParametersRS.apiKey
                            });
                        }, function (error) {
                            console.log(error);
                        });
                        this.form = this.formBuilder.group({
                            email: [
                                '',
                                [Validators.required]
                            ],
                            password: [
                                '',
                                [Validators.required /* , Validators.pattern(/^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/) */]
                            ]
                        });
                        this.activeCaptcha();
                        return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.onSubmit = function (captchaResponse) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var body, _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!this.form.valid) return [3 /*break*/, 2];
                        _a = {
                            Channel: "NegociosNequi",
                            ClientID: "3999999999",
                            Destination: {
                                "ServiceName": "PartnerService",
                                "ServiceOperation": "login",
                                "ServiceRegion": "C001",
                                "ServiceVersion": "1.0.0"
                            }
                        };
                        _b = {};
                        _c = "loginRQ";
                        _d = {
                            "username": this.form.value.email
                        };
                        _e = "password";
                        return [4 /*yield*/, this.utilsService.encryptString(this.form.value.password)];
                    case 1:
                        body = (_a.Body = (_b[_c] = (_d[_e] = _f.sent(),
                            _d["captchaResponse"] = this.form.value.recaptchaReactive,
                            _d),
                            _b),
                            _a);
                        this.utilsService.consumeEvent('servicesSessionservicesLoginPost', body).subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (!data.error) {
                                    this.utilsService.createNewClient({
                                        accessKey: data.loginRS.accessKey,
                                        secretKey: data.loginRS.secretKey,
                                        sessionToken: data.loginRS.sessionToken,
                                        accessToken: data.loginRS.accessToken,
                                        apiKey: data.loginRS.apiKey
                                    });
                                    this.observablesService.setLocalStorage('currentUser', { username: this.form.value.email, accessToken: data.loginRS.accessToken });
                                    this.observablesService.setCookie('parameters', data.loginRS, 480, true);
                                    if (this.returnUrl == 'cobros') {
                                        window.location.href = 'https://cobros.nequi.co';
                                        /* window.location.href = 'http://localhost:4300'; */
                                    }
                                    else {
                                        if (this.returnUrl === '/') {
                                            this.router.navigate(['/privado/perfil']);
                                        }
                                        else {
                                            this.router.navigate([this.returnUrl]);
                                        }
                                    }
                                    this.eventClick();
                                }
                                else {
                                    this.activeCaptcha();
                                    if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode == "G-07A") {
                                        this.showModalInfo('CaptchaError');
                                    }
                                    else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == "NOT_VALID_STATUS") {
                                        this.showModalInfo('registerNoActive');
                                    }
                                    else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == "USER_NOT_FOUND") {
                                        this.showModalInfo('loginUserNotExist');
                                    }
                                    else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == "NOT_VALID_PASSWORD") {
                                        this.showModalInfo('loginPasswordNotValid');
                                    }
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        return [3 /*break*/, 3];
                    case 2:
                        this.activeCaptcha();
                        _f.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.eventClick = function () {
        this.googleAnalyticsService.emitEvent('Perfil', 'Clic entrar');
    };
    /**
    * modal de solo informacion
    * @param modelo
    */
    LoginComponent.prototype.showModalInfo = function (modelo) {
        this.observablesService.showModal({
            modelo: modelo,
            extraData: {},
        });
    };
    LoginComponent.prototype.activeCaptcha = function () {
        this.form.reset();
        this.form.addControl('recaptchaReactive', new FormControl(null));
    };
    return LoginComponent;
}());
export { LoginComponent };
export { ɵ0 };
