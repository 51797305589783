import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cobros',
  templateUrl: './cobros.component.html',
  styleUrls: ['./styles/cobros.component.scss']
})
export class CobrosComponent implements OnInit {

  private redirect: string = 'https://www.nequi.com.co/negocios/cobros-nequi';

  constructor() { }

  ngOnInit() {
    window.location.href = this.redirect
  }

}
