import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule, FormArray } from '@angular/forms';
import { JsonProvider, AuthService, GoogleAnalyticsService, UtilsService, ObservablesService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./styles/login.component.scss',
    './styles/login.media.768.scss',
    './styles/login.media.450.scss'],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.googleCaptchaKey,
      } as RecaptchaSettings,
    }
  ]
})

export class LoginComponent implements OnInit {

  resolved(captchaResponse: string) {
  }

  /* private b64Cookie = btoa('captcha'); */
  public form: FormGroup;
  public dataLogin: any;
  public submitted = false;
  public returnUrl: string;


  constructor(
    private jsonProvider: JsonProvider,
    private formBuilder: FormBuilder,
    private requestAuth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private googleAnalyticsService: GoogleAnalyticsService,
    private utilsService: UtilsService,
    private observablesService: ObservablesService
  ) {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    if (this.observablesService.getLocalStorage('currentUser') && this.observablesService.getCookie('parameters')) {
      if (this.returnUrl == 'cobros') {
        window.location.href = 'https://cobros.nequi.co';
        /* window.location.href = 'http://localhost:4300'; */
      } else {
        this.router.navigate([this.returnUrl]);
      }

    }

    this.jsonProvider.getMessages('login').subscribe(data => this.dataLogin = data);
  }

  async ngOnInit() {


    await this.utilsService.getParams();

    let data: any = this.observablesService.getLocalStorage('parameters');

    this.utilsService.setCognitoTemporalKeys(data.getParametersRS).subscribe((creds: any) => {
      this.utilsService.createNewClient({
        accessKey: creds.accessKeyId,
        secretKey: creds.secretAccessKey,
        sessionToken: creds.sessionToken,
        apiKey: data.getParametersRS.apiKey
      });

    }, (error) => {
      console.log(error);
    });

    this.form = this.formBuilder.group({
      email: [
        '',
        [Validators.required]
      ],
      password: [
        '',
        [Validators.required/* , Validators.pattern(/^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/) */]
      ]
    });

    this.activeCaptcha();


    // reset login status


  }

  async onSubmit(captchaResponse: string) {
    if (this.form.valid) {
      let body = {
        Channel: "NegociosNequi",
        ClientID: "3999999999",
        Destination: {
          "ServiceName": "PartnerService",
          "ServiceOperation": "login",
          "ServiceRegion": "C001",
          "ServiceVersion": "1.0.0"
        },
        Body: {
          "loginRQ": {
            "username": this.form.value.email,
            "password": await this.utilsService.encryptString(this.form.value.password),
            "captchaResponse": this.form.value.recaptchaReactive
          }
        }
      }

      this.utilsService.consumeEvent('servicesSessionservicesLoginPost', body).subscribe(async (data) => {

        if (!data.error) {

          this.utilsService.createNewClient({
            accessKey: data.loginRS.accessKey,
            secretKey: data.loginRS.secretKey,
            sessionToken: data.loginRS.sessionToken,
            accessToken: data.loginRS.accessToken,
            apiKey: data.loginRS.apiKey
          });


          this.observablesService.setLocalStorage('currentUser', { username: this.form.value.email, accessToken: data.loginRS.accessToken });

          this.observablesService.setCookie('parameters', data.loginRS, 480, true);

          if (this.returnUrl == 'cobros') {
            window.location.href = 'https://cobros.nequi.co';
            /* window.location.href = 'http://localhost:4300'; */
          } else {
            if (this.returnUrl === '/') {
              this.router.navigate(['/privado/perfil']);
            } else {
              this.router.navigate([this.returnUrl]);
            }
          }

          this.eventClick();
        } else {
          this.activeCaptcha();
          if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode == "G-07A") {
            this.showModalInfo('CaptchaError');
          } else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == "NOT_VALID_STATUS") {
            this.showModalInfo('registerNoActive');
          } else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == "USER_NOT_FOUND") {
            this.showModalInfo('loginUserNotExist');
          } else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == "NOT_VALID_PASSWORD") {
            this.showModalInfo('loginPasswordNotValid');
          }
        }
      })
    } else {
      this.activeCaptcha();
    }
  }



  eventClick() {
    this.googleAnalyticsService.emitEvent('Perfil', 'Clic entrar');
  }

  /**
  * modal de solo informacion
  * @param modelo
  */
  showModalInfo(modelo) {
    this.observablesService.showModal(
      {
        modelo,
        extraData: {
        },
      }
    );
  }

  activeCaptcha() {
    this.form.reset();
    this.form.addControl('recaptchaReactive', new FormControl(null));
  }
}