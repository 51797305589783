var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JsonProvider, UtilsService, ObservablesService } from '../../services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
var ChangePasswordComponent = /** @class */ (function () {
    function ChangePasswordComponent(formBuilder, jsonProvider, activeModal, utilsService, observablesService) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.jsonProvider = jsonProvider;
        this.activeModal = activeModal;
        this.utilsService = utilsService;
        this.observablesService = observablesService;
        this.jsonProvider.getMessages('changePassword').subscribe(function (data) { _this.dataPerfil = data; });
    }
    ChangePasswordComponent.prototype.ngOnInit = function () {
        //console.log( this.dataPerfil);
        this.form = this.formBuilder.group({
            passwordActual: [
                '',
                [Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}')]
            ],
            password: [
                '',
                [Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}')]
            ],
            passwordConfirm: [
                '',
                [Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}')]
            ]
        });
    };
    ChangePasswordComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var dataLogin, oldPassword, newPassword, body;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dataLogin = this.observablesService.getCookie('parameters');
                        return [4 /*yield*/, this.utilsService.encryptString(this.form.value.passwordActual)];
                    case 1:
                        oldPassword = _a.sent();
                        return [4 /*yield*/, this.utilsService.encryptString(this.form.value.password)];
                    case 2:
                        newPassword = _a.sent();
                        if (this.form.valid) {
                            body = {
                                Channel: "NegociosNequi",
                                ClientID: "3999999999",
                                Destination: {
                                    "ServiceName": "PartnerService",
                                    "ServiceOperation": "changePassword",
                                    "ServiceRegion": "C001",
                                    "ServiceVersion": "1.0.0"
                                },
                                Body: {
                                    "changePasswordRQ": {
                                        "username": dataLogin.username,
                                        "oldPassword": oldPassword,
                                        "newPassword": newPassword,
                                        "accessToken": dataLogin.accessToken
                                    }
                                }
                            };
                            this.utilsService.consumeEvent('servicesUserservicesChangepasswordPost', body).subscribe(function (data) {
                                if (!data.error) {
                                    //console.log('TCL: ChangePasswordComponent -> asynconSubmit -> data', data);
                                    _this.activeModal.close('Close click');
                                }
                                else {
                                    if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === "20-09A") {
                                        _this.showModalInfo('ErrorTecnico');
                                    }
                                }
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * modal de solo informacion
     * @param modelo
     */
    ChangePasswordComponent.prototype.showModalInfo = function (modelo) {
        this.observablesService.showModal({
            modelo: modelo
        });
    };
    return ChangePasswordComponent;
}());
export { ChangePasswordComponent };
