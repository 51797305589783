/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./styles/carousel-cobros.component.scss.shim.ngstyle";
import * as i1 from "./styles/carousel-cobros.media.576.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../../../../../node_modules/ngx-owl-carousel/src/owl-carousel.component.ngfactory";
import * as i4 from "ngx-owl-carousel/src/owl-carousel.component";
import * as i5 from "@angular/common";
import * as i6 from "./carousel-cobros.component";
import * as i7 from "../../../../services/providers/json.provider";
import * as i8 from "@angular/router";
import * as i9 from "../../../../services/providers/google-analytics.service";
var styles_CarouselCobrosComponent = [i0.styles, i1.styles];
var RenderType_CarouselCobrosComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_CarouselCobrosComponent, data: {} });
export { RenderType_CarouselCobrosComponent as RenderType_CarouselCobrosComponent };
function View_CarouselCobrosComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 11, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 10, "div", [["class", "content-item row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 7, "div", [["class", "col-md-7 col-sm-7 col-xs-12 contain-text"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 1, "h1", [["class", "font-bold"]], null, null, null, null, null)), (_l()(), i2.ɵted(5, null, ["", ""])), (_l()(), i2.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i2.ɵted(7, null, ["", ""])), (_l()(), i2.ɵeld(8, 0, null, null, 1, "button", [["class", "white-border-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.eventRegister(_v.context.$implicit.button) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(9, null, ["", ""])), (_l()(), i2.ɵeld(10, 0, null, null, 1, "div", [["class", "col-md-5 col-sm-5 col-xs-12"]], null, null, null, null, null)), (_l()(), i2.ɵeld(11, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_0); var currVal_1 = _v.context.$implicit.body; _ck(_v, 7, 0, currVal_1); var currVal_2 = _v.context.$implicit.button; _ck(_v, 9, 0, currVal_2); var currVal_3 = i2.ɵinlineInterpolate(1, "", _v.context.$implicit.img, ""); _ck(_v, 11, 0, currVal_3); }); }
export function View_CarouselCobrosComponent_0(_l) { return i2.ɵvid(0, [i2.ɵqud(402653184, 1, { owlElement: 0 }), (_l()(), i2.ɵeld(1, 0, null, null, 5, "section", [["class", "container-carousel"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 4, "owl-carousel", [], null, null, null, i3.View_OwlCarousel_0, i3.RenderType_OwlCarousel)), i2.ɵdid(3, 311296, null, 0, i4.OwlCarousel, [i2.IterableDiffers], { carouselClasses: [0, "carouselClasses"], options: [1, "options"], items: [2, "items"] }, null), i2.ɵpad(4, 2), (_l()(), i2.ɵand(16777216, null, 0, 1, null, View_CarouselCobrosComponent_1)), i2.ɵdid(6, 278528, null, 0, i5.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, "owl-theme", "sliding"); var currVal_1 = _co.options; var currVal_2 = _co.items; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.items; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_CarouselCobrosComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-carousel-cobros", [], null, null, null, View_CarouselCobrosComponent_0, RenderType_CarouselCobrosComponent)), i2.ɵdid(1, 114688, null, 0, i6.CarouselCobrosComponent, [i7.JsonProvider, i8.Router, i9.GoogleAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarouselCobrosComponentNgFactory = i2.ɵccf("app-carousel-cobros", i6.CarouselCobrosComponent, View_CarouselCobrosComponent_Host_0, {}, {}, []);
export { CarouselCobrosComponentNgFactory as CarouselCobrosComponentNgFactory };
