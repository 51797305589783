<section>
  <app-banner-landings id="banner-landing" product="qr"></app-banner-landings>

  <div id="section-step" class="contain-icon-info">
    <app-iconos-info product="qr"></app-iconos-info>
  </div>

  <div id="section-video" class="with-function">
    <div class="text container font-bold">
      <h1>{{messages?.title}}</h1>
    </div>
    <div class="content-video container">
      <iframe  src="https://www.youtube.com/embed/Mh6lGGXGBMY" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
    </div>
  </div>
  <div id="section-sim" class="contain-simulator">
    <div class="sticker-conditions">
      <p>La comisión de Nequi es del 0%</p>
      <p>Si vas a recibir los pagos en tu cuenta Nequi la plata llega de una, si los recibes en tu cuenta Bancolombia la plata llega al día siguiente.</p>
    </div>
  </div>
</section>
