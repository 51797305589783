/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./styles/iconos-info.component.scss.shim.ngstyle";
import * as i1 from "./styles/iconos-info.media.960.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./iconos-info.component";
import * as i5 from "../../services/providers/json.provider";
var styles_IconosInfoComponent = [i0.styles, i1.styles];
var RenderType_IconosInfoComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_IconosInfoComponent, data: {} });
export { RenderType_IconosInfoComponent as RenderType_IconosInfoComponent };
function View_IconosInfoComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "a", [["class", "icon-info-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scroll(_v.parent.context.$implicit.link) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.lintText; _ck(_v, 1, 0, currVal_0); }); }
function View_IconosInfoComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 6, "div", [["class", "icon-info-container"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 0, "img", [["class", "icon-info-image"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "div", [["class", "icon-info-title"]], null, null, null, null, null)), (_l()(), i2.ɵted(3, null, ["", ""])), (_l()(), i2.ɵeld(4, 0, null, null, 0, "div", [["class", "icon-info-body"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_IconosInfoComponent_3)), i2.ɵdid(6, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = (0 != _v.context.$implicit.hasLink); _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i2.ɵinlineInterpolate(1, "", _v.context.$implicit.image, ""); var currVal_1 = i2.ɵinlineInterpolate(1, "Imagen alusiva al paso ", _v.context.$implicit.title, ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.$implicit.body; _ck(_v, 4, 0, currVal_3); }); }
function View_IconosInfoComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 2, "div", [["class", "col-xs-12 col-sm-12"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_IconosInfoComponent_2)), i2.ɵdid(4, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.messages; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵinlineInterpolate(1, "landing-iconos-info container product-", _co.product, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_IconosInfoComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_IconosInfoComponent_1)), i2.ɵdid(1, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_IconosInfoComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-iconos-info", [], null, null, null, View_IconosInfoComponent_0, RenderType_IconosInfoComponent)), i2.ɵdid(1, 114688, null, 0, i4.IconosInfoComponent, [i5.JsonProvider], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconosInfoComponentNgFactory = i2.ɵccf("app-iconos-info", i4.IconosInfoComponent, View_IconosInfoComponent_Host_0, { product: "product" }, {}, []);
export { IconosInfoComponentNgFactory as IconosInfoComponentNgFactory };
