import * as clevertap from 'clevertap-web-sdk';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
var clevertapService = /** @class */ (function () {
    function clevertapService() {
        clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
        clevertap.privacy.push({ useIP: false }); // Set the flag to true, if the user agrees to share their IP data
        clevertap.init(environment.clevertapId, 'us1');
    }
    clevertapService.prototype.sendEvent = function (name, properties) {
        if (!!properties) {
            clevertap.event.push(name, properties);
        }
        else {
            clevertap.event.push(name);
        }
    };
    clevertapService.ngInjectableDef = i0.defineInjectable({ factory: function clevertapService_Factory() { return new clevertapService(); }, token: clevertapService, providedIn: "root" });
    return clevertapService;
}());
export { clevertapService };
