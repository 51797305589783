import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { from, throwError, of } from 'rxjs';
import { ObservablesService } from './observables.service';
import { environment } from '../../../environments/environment';
/* import * as AWS from "../../../lib/aws/aws-sdk.min.js" */

import { CognitoUserPool } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk";
import * as awsservice from "aws-sdk/lib/service";
import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";

export interface data {
  RequestMessage: {
    RequestHeader: {
      Channel: string,
      RequestDate: string,
      MessageID: string,
      ClientID: string,
      Destination: object
    },
    RequestBody: {
      any: object
    }
  }
}

/* declare var apigClientPartnersFactory; */

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private params: data;
  private apiClient: any;
  private apiClientDispersion: any;

  constructor(private observablesService: ObservablesService) {

    let messageID = Date.now().toString();
    messageID = messageID.substring(messageID.length - 9);

    this.params = {
      RequestMessage: {
        RequestHeader: {
          Channel: "",
          RequestDate: new Date().toJSON(),
          MessageID: messageID,
          ClientID: "",
          Destination: {}
        },
        RequestBody: {
          any: {}
        }
      }
    }
    
    //console.log(apigClientPartners);
    
    if(!this.observablesService.getLocalStorage('currentUser') && !this.observablesService.getCookie('parameters')){
      this.createNewClient();
      this.getParams();  
    }else{
      let dataLogin: any = this.observablesService.getCookie('parameters');
      this.createNewClient({
        accessKey: dataLogin.accessKey,
        secretKey: dataLogin.secretKey,
        sessionToken: dataLogin.sessionToken,
        accessToken: dataLogin.accessToken,
        apiKey: dataLogin.apiKey
      });  
    }
    

    setInterval(()=>{
     if(this.observablesService.getLocalStorage('currentUser') && this.observablesService.getCookie('parameters')){
      this.keyLoginInterval();
     }else{
      this.eventKey();
     }      
    },  3000000 );

  }

  getParams() {
    let body = {
      Channel: "PN01-C001",
      ClientID: "3999999999",
      Destination: {
        "ServiceName": "GeneralServices",
        "ServiceOperation": "getParameters",
        "ServiceRegion": "C001",
        "ServiceVersion": "1.0.0"
      },
      Body: {
        "getParametersRQ": {}
      }
    }
    return this.consumeEvent('servicesGeneralservicesGetparametersPost', body).toPromise().then((data) => {
      if(!data.error){
        this.observablesService.setLocalStorage('parameters', data);       
      }         
    });
  }

  createNewClient(config = undefined) {
    this.apiClient = environment.apigClientPartners.newClient(config);
  }

  createNewClientDispersions(config = undefined) {
    this.apiClientDispersion = environment.apigClientDispersions.newClient(config);
  }

  getRequest(body){
    /* this.params.RequestMessage.RequestHeader.Channel = body.Channel; */
    this.params.RequestMessage.RequestHeader.Channel = 'PN01-C001';

    if(this.observablesService.getLocalStorage('currentUser') && this.observablesService.getCookie('parameters')){
      let user: any = this.observablesService.getLocalStorage('currentUser');
      this.params.RequestMessage.RequestHeader.ClientID = user.username;
    }else{
      /* this.params.RequestMessage.RequestHeader.ClientID = body.ClientID; */
      this.params.RequestMessage.RequestHeader.ClientID = 'PN01-C001';
    }    

    this.params.RequestMessage.RequestHeader.Destination = body.Destination;
    this.params.RequestMessage.RequestBody.any = body.Body;

    return this.params;
  }

  consumeEvent(event, body, sdk = "negocios") {
    this.observablesService.showSpinner(true);
    let request = this.getRequest(body);
    let observable;
    if(sdk == "dispersions"){
      observable = from(this.apiClientDispersion[event]('', request, ''))
      .pipe(
        map((res: any) => {
          return this.validateData(res);
        }),
        catchError((error: any) => {
          return this.validateData(error);
        })
      );
    }else if(sdk == "negocios"){
      observable = from(this.apiClient[event]('', request, ''))
      .pipe(
        map((res: any) => {
          return this.validateData(res);
        }),
        catchError((error: any) => {
          return this.validateData(error);
        })
      );
    }
    
    

    return observable;
  }

  validateData(res) {
    try {
      if (res.status == 200 && res.data.ResponseMessage.ResponseHeader.Status.StatusCode == "0") {
        this.observablesService.showSpinner(false);
        return res.data.ResponseMessage.ResponseBody.any
      } else {
        /* console.error(res.data.ResponseMessage.ResponseHeader.Status.StatusDesc); */
        if(res.data.message === "The security token included in the request is expired"){
          this.observablesService.setCookie('parameters', {}, 0, true);
          this.observablesService.removeItem('currentUser');
        }
        this.observablesService.showSpinner(false);
        if(res.data.ResponseMessage){
          if(res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-05A' || res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-07A'){
            /* alert(res.data.ResponseMessage.ResponseHeader.Status.StatusDesc); */

            this.showModalCallback('ErrorTecnico', this.reload);
          }
        }else{
          this.showModalCallback('ErrorTecnico', this.reload);
        }        
        return {error: true, res};
      }
    } catch (error) {    
      this.showModalCallback('ErrorTecnico', this.reload);
      /* alert(error); */
    }    
  }

  reload(){
    location.reload();
  }

  eventKey(){
    let data: any = this.observablesService.getLocalStorage('parameters');

    this.setCognitoTemporalKeys(data.getParametersRS).subscribe((creds: any) => {
      this.createNewClient({
        accessKey: creds.accessKeyId,
        secretKey: creds.secretAccessKey,
        sessionToken: creds.sessionToken,
        apiKey: data.getParametersRS.apiKey
      });      
    }, (error) => {
    });
  }

  setCognitoTemporalKeys(params) {
    
    let promise = new Promise((resolve, reject) => {
      AWS.config.region = 'us-east-1';
      let creds = new AWS.CognitoIdentityCredentials({        
        IdentityPoolId: params.cognitoIdentityPool
      });

      AWS.config.credentials = creds;

      creds.get((error) => {

        if (!!creds.accessKeyId && !!creds.secretAccessKey && !!creds.sessionToken) {         
          resolve(creds);
        } else {
          this.showModalCallback('ErrorTecnico', this.reload);
          reject(false);
        }
      });
    });
    
    let observable = from(promise);
    return observable;
  }


  async encryptString(string) {

    
    let promise = new Promise((resolve, reject) => {

      this.getParams();  

      let data: any = this.observablesService.getLocalStorage('parameters');

      this.setCognitoTemporalKeys(data.getParametersRS).subscribe(()=>{
        AWS.config.region = 'us-east-1';

        let kms = new AWS.KMS();
        var params = {
            KeyId: data.getParametersRS.kmsKey,
            Plaintext: string
        };
  
        kms.encrypt(params, function (err, data: any) {
            if (err) {
                reject({error: true, data: err});
            } else {
                resolve(data.CiphertextBlob.toString('base64'));
            }
        });
      });
     
    });
    
    return promise;
  }

  keyLogin(){
    let dataLogin: any =  this.observablesService.getCookie('parameters');

    this.createNewClient({
      accessKey: dataLogin.accessKey,
      secretKey: dataLogin.secretKey,
      sessionToken: dataLogin.sessionToken,
      accessToken: dataLogin.accessToken,
      apiKey: dataLogin.apiKey
    });  

  }

  logout(){
    let dataLocal :any = this.observablesService.getLocalStorage('currentUser');

    let body = {
      Channel: "NegociosNequi",
      ClientID: "3999999999",
      Destination: {
        "ServiceName": "PartnerService",
        "ServiceOperation": "logout",
        "ServiceRegion": "C001",
        "ServiceVersion": "1.0.0"
      },
      Body: {
        "logoutRQ": {
          "username": dataLocal.username,
          "accessToken": dataLocal.accessToken
        }
      }
    }
    this.consumeEvent('servicesSessionservicesLogoutPost', body).subscribe((data) => {
      if(!data.error){
        this.observablesService.removeItem('currentUser');
        this.observablesService.setCookie('parameters', {}, 0, true);
      }
    });

  }

  async keyLoginInterval(){


    let dataLocal :any = this.observablesService.getCookie('parameters');

    let body = {
      Channel: "NegociosNequi",
      ClientID: "3999999999",
      Destination: {
        "ServiceName": "PartnerService",
        "ServiceOperation": "refreshLogin",
        "ServiceRegion": "C001",
        "ServiceVersion": "1.0.0"
      },
      Body: {
        "refreshLoginRQ": {
          "username": dataLocal.username,
          "accessToken": dataLocal.accessToken 
        }
      }
    }

    return this.consumeEvent('servicesSessionservicesRefreshloginPost', body).toPromise().then((data) => {
      if(!data.error){
        this.observablesService.setCookie('parameters', data.refreshLoginRS, 480, true);

        /* if(data.refreshLoginRS.accessToken){
          dataLocal.accessToken = data.refreshLoginRS.accessToken;
        }      

        this.observablesService.setLocalStorage('currentUser', dataLocal); */

        this.createNewClient({
          accessKey: data.refreshLoginRS.accessKey,
          secretKey: data.refreshLoginRS.secretKey,
          sessionToken: data.refreshLoginRS.sessionToken,
          accessToken: data.refreshLoginRS.accessToken,
          apiKey: data.refreshLoginRS.apiKey
        });  
      }else{
        this.observablesService.setCookie('parameters', data.refreshLoginRS, 0, true);
        this.observablesService.removeItem('currentUser');
        window.location.reload();
      }      
    });
  }
  
   /**
   * modal que retorna callback 
   * @param modelo nombre del tipo de modal
   * @param form datos del tab (registro) de donde salio el modal
   * @param fn callback que retorna el modal 
   */
  showModalCallback(modelo, fn) {
    this.observablesService.showModal(
      {
        modelo,
        extraData: {
        },
        clicFunc: fn
      }
    );
  }

}
