var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonProvider, AuthService, UtilsService, ObservablesService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';
var ɵ0 = {
    siteKey: environment.googleCaptchaKey,
};
var ForgetPassComponent = /** @class */ (function () {
    function ForgetPassComponent(jsonProvider, formBuilder, requestAuth, router, route, utilsService, observablesService) {
        var _this = this;
        this.jsonProvider = jsonProvider;
        this.formBuilder = formBuilder;
        this.requestAuth = requestAuth;
        this.router = router;
        this.route = route;
        this.utilsService = utilsService;
        this.observablesService = observablesService;
        this.sendService = false;
        this.submitted = false;
        this.dataFlow = {
            emailExist: false,
            success: false,
            emailNotExist: true
        };
        if (localStorage.getItem('currentUser')) {
            // logged in so return true
            this.router.navigate(['/']);
        }
        this.jsonProvider.getMessages('forgetPass').subscribe(function (data) { return _this.dataLogin = data; });
    }
    ForgetPassComponent.prototype.resolved = function (captchaResponse) {
    };
    ForgetPassComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.utilsService.getParams()];
                    case 1:
                        _a.sent();
                        data = this.observablesService.getLocalStorage('parameters');
                        this.utilsService.setCognitoTemporalKeys(data.getParametersRS).subscribe(function (creds) {
                            _this.utilsService.createNewClient({
                                accessKey: creds.accessKeyId,
                                secretKey: creds.secretAccessKey,
                                sessionToken: creds.sessionToken,
                                apiKey: data.getParametersRS.apiKey
                            });
                        }, function (error) {
                        });
                        this.formEmail = this.formBuilder.group({
                            email: [
                                '',
                                [Validators.required, Validators.email]
                            ],
                            recaptchaReactive: []
                        });
                        this.form = this.formBuilder.group({
                            code: [
                                '',
                                [Validators.required]
                            ],
                            password: [
                                '',
                                [Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}')]
                            ],
                            passwordConfirm: [
                                '',
                                [Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}')]
                            ],
                            recaptchaReactive: []
                        });
                        // reset login status
                        // get return url from route parameters or default to '/'
                        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                        return [2 /*return*/];
                }
            });
        });
    };
    ForgetPassComponent.prototype.onSubmitEmail = function (captchaResponse) {
        var _this = this;
        if (this.formEmail.valid) {
            var body = {
                Channel: "NegociosNequi",
                ClientID: "3999999999",
                Destination: {
                    "ServiceName": "PartnerService",
                    "ServiceOperation": "forgotPassword",
                    "ServiceRegion": "C001",
                    "ServiceVersion": "1.0.0"
                },
                Body: {
                    "forgotPasswordRQ": {
                        "email": this.formEmail.value.email,
                        "captchaResponse": this.formEmail.value.recaptchaReactive
                    }
                }
            };
            this.utilsService.consumeEvent('servicesUserservicesForgotpasswordPost', body).subscribe(function (data) {
                if (!data.error) {
                    _this.dataFlow.emailExist = true;
                }
                else {
                    _this.formEmail.reset();
                    if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode == "G-07A") {
                        _this.showModalInfo('CaptchaError');
                    }
                    else {
                        _this.showModalInfo('ErrorTecnico');
                    }
                }
            });
        }
    };
    ForgetPassComponent.prototype.onSubmit = function (captchaResponse) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var body, _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        this.sendService = true;
                        if (!this.form.valid) return [3 /*break*/, 2];
                        _a = {
                            Channel: "NegociosNequi",
                            ClientID: "3999999999",
                            Destination: {
                                "ServiceName": "PartnerService",
                                "ServiceOperation": "resetPassword",
                                "ServiceRegion": "C001",
                                "ServiceVersion": "1.0.0"
                            }
                        };
                        _b = {};
                        _c = "resetPasswordRQ";
                        _d = {
                            "email": this.formEmail.value.email,
                            "confirmationCode": this.form.value.code
                        };
                        _e = "newPassword";
                        return [4 /*yield*/, this.utilsService.encryptString(this.form.value.password)];
                    case 1:
                        body = (_a.Body = (_b[_c] = (_d[_e] = _f.sent(),
                            _d["captchaResponse"] = this.form.value.recaptchaReactive,
                            _d),
                            _b),
                            _a);
                        this.utilsService.consumeEvent('servicesUserservicesResetpasswordPost', body).subscribe(function (data) {
                            console.log("TCL: ForgetPassComponent -> onSubmit -> data", data);
                            if (!data.error) {
                                _this.dataFlow.success = true;
                                _this.sendService = false;
                            }
                            else {
                                _this.sendService = false;
                                _this.form.reset();
                                if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode == "G-07A") {
                                    _this.showModalInfo('CaptchaError');
                                }
                                else {
                                    _this.showModalInfo('modalOtpErrorForgetPass');
                                }
                            }
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        this.sendService = false;
                        _f.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * modal de solo informacion
     * @param modelo
     */
    ForgetPassComponent.prototype.showModalInfo = function (modelo) {
        this.observablesService.showModal({
            modelo: modelo,
            extraData: {},
        });
    };
    return ForgetPassComponent;
}());
export { ForgetPassComponent };
export { ɵ0 };
