import { Component, OnInit } from '@angular/core';
import { JsonProvider, ObservablesService } from '../../services';
import { Router } from '@angular/router';
@Component({
  selector: 'app-armario',
  templateUrl: './armario.component.html',
  styleUrls: ['./armario.component.scss']
})
export class ArmarioComponent implements OnInit {

  public messages;

  constructor(private jsonProvider: JsonProvider, private router: Router) {
    this.jsonProvider.getMessages('armario').subscribe(data => this.messages = data);
   }

  ngOnInit() {
  }

  eventClick(src){
    this.router.navigate([src]);
  }
}
