import { Component, OnInit, ViewChild } from '@angular/core';
import { OwlCarousel } from 'ngx-owl-carousel';
import { JsonProvider, GoogleAnalyticsService  } from '../../../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-carousel-cobros',
  templateUrl: './carousel-cobros.component.html',
  styleUrls: [
              './styles/carousel-cobros.component.scss',
              './styles/carousel-cobros.media.576.scss'
            ]
})
export class CarouselCobrosComponent implements OnInit {

  public items: any;
  public options = {items: 1, dots: true, navigation: true};

  constructor(private jsonProvider: JsonProvider, 
              private router: Router,
              private googleAnalyticsService: GoogleAnalyticsService) {

    this.jsonProvider.getMessages('carousel-cobros').subscribe((data) => {this.items = data;});
   }

  ngOnInit() {
  }

  eventRegister(text){
    this.eventClick(text);
    this.router.navigate(['registro/cobros']);
  }

  eventClick(text){
    let action = 'Clic ' + text.toLowerCase(); 
    this.googleAnalyticsService.emitEvent('Cobros Nequi', action, "Sticker Nequi - Cobros Nequi - Botón Nequi")
  }

  @ViewChild('owlElement') owlElement: OwlCarousel;  
  
    fun() {
      this.owlElement.next([200]);
      //duration 200ms
    }

}
