<section>

  <div class="contain-option" *ngFor="let item of items" (click)="routerDetailApi(item.type)" (click)="eventClick(item?.title)">
    <div class="contain-img">
      <img src="{{item?.img}}" alt="">
    </div>
    <div class="contain-text">
      <h3>{{item?.title}}</h3>
      <p>{{item?.body}}</p>
    </div>
  </div>

</section>