import { Component, OnInit, Injectable, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule, FormArray } from '@angular/forms';
import { JsonProvider, ObservablesService, UtilsService, GoogleAnalyticsService, clevertapService } from '../../services';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EventModalClick } from '../../components/modal/modal.component';
import { NgbDatepickerI18n, NgbDateStruct, NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { Bool } from 'aws-sdk/clients/clouddirectory';
import { environment } from '../../../environments/environment';
import { RegistryConfig } from './registry.config';



const I18N_VALUES = {
  'es-CO': {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'es-CO';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}


@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: [
    './styles/registro.component.scss',
    './styles/registro.media.576.scss',
    './styles/registro.media.767.scss',
    './styles/registro.media.990.scss'
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.googleCaptchaKey,
      } as RecaptchaSettings,
    }],
})
export class RegistroComponent implements OnInit {
  public messages: any;
  public forms: Array<any> = [];
  public tabShow = 0;
  public registerSuccess: boolean = false;
  public registerSuccessConfirmation: boolean = false;
  public register_type: string;
  public welcomeMessages: any;
  public welcomeMessagesConfirmation: any;
  public infoForms: any;
  public externalPartner: string;

  public typeAccount;

  public btnNextDisabled: Boolean = false;

  public dataFlow: any = {
    nequi: 1,
    ahorroApi: 1,

    otp: 0,
    countOtp: 0,

    otpReport: 0,
    countOtpReport: 0,

    otpEmail: 0,
    countOtpEmail: 0,

    otpNequi: 0,
    countOtpNequi: 0,
    tryOtpNequi: 0,

    bloqueo: 0,
    list: 0,
    comercio: {
      list: 0,
      name: 0
    },
    representante: {
      ccValid: 1,
      list: 0,
      dateEx: 1
    },
    cuenta: {
      valid: 0
    },

    location: {
      department: [],
      city: []
    },

    flow: []
  }

  public type_api: string;
  public closeResult: string;
  public dataPdf: any;

  public toast: Boolean = false;

  constructor(
    private JsonProvider: JsonProvider,
    private formBuilder: FormBuilder,
    private observablesService: ObservablesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private config: NgbDatepickerConfig,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private modalService: NgbModal,
    public sanitizer: DomSanitizer,
    private utilsService: UtilsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private clevertapService: clevertapService) {


    let validators = [];

    const d: any = new Date();
    this.config.minDate = { year: 1900, month: 1, day: 1 };
    this.config.maxDate = { year: d.getFullYear(), month: d.getMonth(), day: d.getDate() + 1 };


    this.JsonProvider.getMessages('welcome-register').subscribe(data => this.welcomeMessages = data);

    this.JsonProvider.getMessages('welcome-register-confirmation').subscribe(data => this.welcomeMessagesConfirmation = data);

    this.infoForms = !!this.observablesService.getLocalStorage('infoRegister') ? this.observablesService.getLocalStorage('infoRegister') : undefined

    this.dataFlow = !!this.observablesService.getLocalStorage('infoValidate') ? this.observablesService.getLocalStorage('infoValidate') : this.dataFlow

    this.getProduct();
    this.createForms();

  }

  async ngOnInit() {

    this.activatedRoute.queryParams.subscribe((params) => {
       this.externalPartner = params.ea;
      }
    );
    
    this.eventClick('Informacion personal');

    if (!this.observablesService.getLocalStorage('currentUser') || !this.observablesService.getCookie('parameters')) {
      await this.utilsService.getParams();
      let data: any = this.observablesService.getLocalStorage('parameters');

      this.utilsService.setCognitoTemporalKeys(data.getParametersRS).subscribe((creds: any) => {
        this.utilsService.createNewClient({
          accessKey: creds.accessKeyId,
          secretKey: creds.secretAccessKey,
          sessionToken: creds.sessionToken,
          apiKey: data.getParametersRS.apiKey
        });

        this.getDepartment();

      }, (error) => {
      });
    } else {
      await this.utilsService.keyLogin();
      this.getDepartment();
    }
  }

  getDepartment() {
    let body = {
      Channel: "PN01-C001",
      ClientID: "3999999999",
      Destination: {
        "ServiceName": "LocationServices",
        "ServiceOperation": "getLocationsByParent",
        "ServiceRegion": "C001",
        "ServiceVersion": "1.0.0"
      },
      Body: {
        "getLocationsByParentRQ": {
          "parentId": "C001"
        }
      }
    }

    this.utilsService.consumeEvent('servicesLocationservicesGetlocationsbyparentPost', body).subscribe((location) => {
      if (!location.error) {
        this.dataFlow.location.department = location.getLocationsByParentRS.locations;

      }
    });
  }


  eventLocation(event) {
    let id = event.target.id;

    if (id == 'department') {

      let index = event.target.selectedIndex - 1;
      let code = this.dataFlow.location.department[index];

      this.forms[1].get('city').setValue('');

      let body = {
        Channel: "PN01-C001",
        ClientID: "3999999999",
        Destination: {
          "ServiceName": "LocationServices",
          "ServiceOperation": "getLocationsByParent",
          "ServiceRegion": "C001",
          "ServiceVersion": "1.0.0"
        },
        Body: {
          "getLocationsByParentRQ": {
            "parentId": code.code
          }
        }
      }

      this.utilsService.consumeEvent('servicesLocationservicesGetlocationsbyparentPost', body).subscribe((locationCity) => {
        if (!locationCity.error) {
          this.dataFlow.location.city = locationCity.getLocationsByParentRS.locations;
        }
      });
    }

  }

  getProduct() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.product == 'api_debito' ||
        params.product == 'api_qr' ||
        params.product == 'api_push') {
        this.register_type = 'api';
      } else if (!!params.product) {
        this.register_type = params.product;
      }
      if (!this.register_type) {
        this.router.navigate(['/']);
      }
    });
  }

  change() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.type_api = params.product;
      let obj = {};
      obj[params.product] = 1;
      this.observablesService.observableRegistro(obj);
    });
  }

  createForms() {
    this.JsonProvider.getMessages('registro').subscribe(
      (data) => {
        /*get the specific json*/
        this.messages = data[this.register_type];
        /* Redirigir al home si el parametro no se encuentra en la lista */
        if (this.messages === undefined) this.router.navigate(['/']);
        this.messages.map((elements, index) => {
          this.forms[index] = this.formBuilder.group({});

          this.forms[index].addControl('recaptchaReactive' + index, new FormControl(null));
          //llenar campos con datos guardados

          let infoForm;
          if (!!this.infoForms && !!this.infoForms[index]) {
            infoForm = this.infoForms[index]
          }

          elements.inputs.map(element => {

            if ('html' !== element.type) {
              if ('checkbox' !== element.type) {
                /*create input*/
                this.forms[index].addControl(element.name, new FormControl(''));

                /* llenar inputs */
                if (!!this.infoForms && !!this.infoForms[index])
                  this.fillInput(element, index, infoForm)

              } else {
                this.forms[index].addControl(element.name, new FormArray([]));

                /* llenar checked */
                this.loadCheck(index, element, !!infoForm ? infoForm[element.name] : [])
              }
              /*Add validators*/
              if ('dependence' in element) {
                if ("hide" == element.dependence.type) {
                  this.hideElement(element, index);
                } else if ("data" == element.dependence.type) {
                  this.dataElement(element, index);
                } else if ("change" == element.dependence.type) {
                  this.changeElement(element, index);
                } else if ("check" == element.dependence.type) {
                  this.checkElement(element, index);
                } else if ("equals" == element.dependence.type) {
                  this.twoElementsValidation(element, index);
                } else {
                  console.error('Wrong dependence type in ' + element.name);
                }
              } else {
                let validators = [];
                let element_control = this.forms[index].get(element.name);
                validators = this.createValidators(element);
                element_control.setValidators(validators);
                element_control.updateValueAndValidity();
              }

              if ('equal' in element) {
                this.equalElement(element, index);
              }
            } else {
              if ('dependence' in element) {
                if ("changeText" == element.dependence.type) {
                  this.changeText(element, index);
                } else {
                  this.hideElementTitle(element, index);
                }
              }
            }
            if ("flow" in element) {
              this.changeFlow(element, index);
            }
          });
        });

        this.change();
      }
    )
  }

  fillInput(element, index, infoForm) {
    setTimeout(() => {
      let element_control = this.forms[index].get(element.name);
      element_control.setValue(!!infoForm ? infoForm[element.name] : '')
      element_control.updateValueAndValidity();
    }, 1);
  }

  loadCheck(index, element, array) {
    const formArray: FormArray = this.forms[index].get(element.name) as FormArray;

    let indexForm = this.messages[index].inputs.map(function (e) { return e.name; }).indexOf(element.name);

    array.map((value, indexArray) => {
      formArray.push(new FormControl(value));
      this.messages[index].inputs[indexForm].options[0].checked = true;
    });
  }


  createValidators(element) {/*Create array of validators, depending on the element sent*/
    let validator = [];
    if ('required' in element && !!element.required) {
      validator.push(Validators.required);
    }
    if ("email" == element.type) {
      let regex = /^(([^<>()[\]\.,;:\s@\']+(\.[^<>()[\]\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
      validator.push(Validators.pattern(regex));
    }
    if ('max' in element) {
      validator.push(Validators.maxLength(element.max));
    }
    if ('min' in element) {
      validator.push(Validators.minLength(element.min));
    }
    if ('pattern' in element) {
      let regex = element.pattern;
      validator.push(Validators.pattern(element.pattern));
    }
    return validator;
  }

  /**
   * Cambio de check y show modal TyC
   * @param event
   * @param form
   * @param name
   * @param option
   */
  onCheckChange(event, form, name, option = undefined) {/*Callback activated when changed a checkbox input*/

    const formArray: FormArray = form.get(name) as FormArray;
    /*  console.log(event.target.checked); */
    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      if (option) {
        this.showModalTyc(option);
      }
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;
      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }


  /*Dependeces*/
  hideElementTitle(element, i) {/*Show or hide titles*/
    if ("hide" == element.dependence.type) {
      this.forms[element.dependence.posForm].get(element.dependence.name).valueChanges.subscribe(
        (result) => {
          if ("condition" in element.dependence) {
            if (result == element.dependence.condition) {
              element.display = true;
            } else {
              element.display = false;
            }
          } else {
            element.display = true;
          }
        }
      )
    } else if ("flow" == element.dependence.type) {
      this.forms[element.dependence.posForm].get(element.dependence.name).valueChanges.subscribe(
        (result) => {
          if ("condition" in element.dependence) {
            if (result == element.dependence.condition) {
              this.changeFlow(element, i);
            } else {
              element.display = false;
            }
          } else {
            this.changeFlow(element, i);
          }
        }
      )
    } else {
      this.observablesService.observableRegistro$.subscribe(
        (result) => {
          if (1 == result[element.dependence.name]) {
            element.display = true;
          } else if (0 == result[element.dependence.name]) {
            element.display = false;
          }
        }
      );
    }
  }

  equalElement(element, index) {/*Show or hide elements*/
    let element_control = this.forms[index].get(element.name);
    this.forms[element.dependence.posForm].get(element.equal).valueChanges.subscribe(
      (result) => {
        if (this.forms[index].value[element.name].length != 5 || !!element_control.invalid)
          element_control.setValue(result);
      }
    )
  }

  hideElement(element, index) {/*Show or hide elements*/
    let element_control = this.forms[index].get(element.name);
    let validators = [];
    this.forms[element.dependence.posForm].get(element.dependence.name).valueChanges.subscribe(
      (result) => {
        if (!!result) {
          if ("condition" in element.dependence) {
            if (result == element.dependence.condition) {
              validators = this.createValidators(element);
              element.display = true;
              element_control.setValidators(validators);
            } else {
              element_control.clearValidators();
              if ("controls" in element_control) {
                element_control.controls = [];
              } else {
                element_control.setValue('');
              }
              element.display = false;
            }
          } else {
            validators = this.createValidators(element);
            element.display = true;
            element_control.setValidators(validators);
          }
          element_control.updateValueAndValidity();
        } else {
          element.display = false;
          if (element.type != 'checkbox') {
            element_control.setValue("");
            element_control.updateValueAndValidity();
          }
        }
      }
    )
  }

  onRadioChange(form, name, value) {
    let element_control = form.get(name);
    element_control.setValue(value);
    element_control.updateValueAndValidity();
  }

  dataElement(element, index) {/*Show or hide elements depending on a data value*/
    let element_control = this.forms[index].get(element.name);
    let validators = [];
    this.observablesService.observableRegistro$.subscribe(
      (result) => {
        if (1 == result[element.dependence.name]) {
          validators = this.createValidators(element);
          element.display = true;
          element_control.setValidators(validators);
          element_control.updateValueAndValidity();
        } else if (0 == result[element.dependence.name]) {
          element_control.clearValidators();
          if ("controls" in element_control) {
            element_control.controls = [];
          } else {
            element_control.setValue('');
          }
          element.display = false;
          element_control.updateValueAndValidity();
        }
      }
    );
  }
  changeElement(element, index) {/*Change the pattern validator of the element*/
    let element_control = this.forms[index].get(element.name);
    this.forms[element.dependence.posForm].get(element.dependence.name).valueChanges.subscribe(
      (result: string) => {
        if (result in element.dependence.patterns) {
          let regex = element.dependence.patterns[result];
          element_control.setValue('');
          element_control.setValidators([Validators.required, Validators.pattern(regex)]);
          element.display = true;
        } else {
          element_control.clearValidators();
          if ("controls" in element_control) {
            element_control.controls = [];
          } else {
            element_control.setValue('');
          }
          element.display = false;
        }
        element_control.updateValueAndValidity();
      }
    );
  }
  checkElement(element, index) {/*Show or hide elements depending on the option chosen*/
    let element_control = this.forms[index].get(element.name);
    let validators = [];
    this.forms[element.dependence.posForm].get(element.dependence.name).valueChanges.subscribe(
      (result: string) => {
        if (result.includes(element.dependence.condition)) {
          validators = this.createValidators(element);
          element.display = true;
          element_control.setValidators(validators);
        } else {
          element_control.clearValidators();
          if ("controls" in element_control) {
            element_control.controls = [];
          } else {
            element_control.setValue('');
          }
          element.display = false;
        }
        element_control.updateValueAndValidity();
      }
    );
  }
  twoElementsValidation(element, index) {
    let element_control = this.forms[index].get(element.name);
    let validators = [];
    validators = this.createValidators(element);
    element_control.setValidators(validators);
    element_control.updateValueAndValidity();
    this.forms[element.dependence.posForm].get(element.dependence.name).valueChanges.subscribe(
      (result) => {
        element.display = true;
        element_control.setValue("");
      }
    )
    element_control.valueChanges.subscribe(
      (result) => {
        if (result !== this.forms[element.dependence.posForm].get(element.dependence.name).value) {
          element_control.setErrors({ 'nomatch': true });
        } else {
          element_control.setErrors(null);
        }
      }
    );
  }
  changeFlow(element, i) {
    let element_control;
    if (element.type != 'html') {
      element_control = this.forms[i].get(element.name);
    }

    this.observablesService.observableRegistro$.subscribe(
      (result) => {
        if (!!result["flow"]) {
          let i: number = 0;
          element.flow.forEach(element => {
            if (!result["flow"].includes(element)) {
              /* i = 0; */
              return false;
            } else {
              i++;
            }
          });
          if (i == element.flow.length) {
            element.display = true;
          } else {
            element.display = false;
            if (element.type != 'html') {
              element_control.setValue("");
              element_control.updateValueAndValidity();
              element_control.clearValidators();
              element_control.updateValueAndValidity();
            }
          }
        }
      }
    );
  }


  changeText(element, index) {
    if ("service" in element.dependence) {
      this.observablesService.observableRegistro$.subscribe(
        (result) => {
          if ("condition" in element.dependence) {
            if (element.dependence.condition == result[element.dependence.service]) {
              let res = "<span>" + this.forms[element.dependence.toMatchPos].get(element.dependence.toMatchName).value + "</span>";
              let newBody = element.body.replace(/<span>.*<\/span>/, res);
              element.body = newBody;
              element.display = true;
            } else if (element.dependence.condition == result[element.dependence.service]) {
              element.display = false;
            }
          } else {
            if (1 == result[element.dependence.service]) {
              let res = "<span>" + this.forms[element.dependence.toMatchPos].get(element.dependence.toMatchName).value + "</span>";
              let newBody = element.body.replace(/<span>.*<\/span>/, res);
              element.body = newBody;
              element.display = true;
            } else if (0 == result[element.dependence.service]) {
              element.display = false;
            }
          }
        }
      );
    } else {
      this.forms[element.dependence.posForm].get(element.dependence.name).valueChanges.subscribe(
        (result: string) => {
          if ("condition" in element.dependence) {
            if (result == (element.dependence.condition)) {
              if (element.dependence.toMatchPos === '0') {
                let res = "<span>" + this.forms[element.dependence.toMatchPos].get(element.dependence.toMatchName).value + "</span>";
                let newBody = element.body.replace(/<span>.*<\/span>/, res);
                element.body = newBody;
                element.display = true;
              } else {
                this.forms[element.dependence.toMatchPos].get(element.dependence.toMatchName).valueChanges.subscribe(
                  (result: string) => {
                    let res = "<span>" + result + "</span>";
                    let newBody = element.body.replace(/<span>.*<\/span>/, res);
                    element.body = newBody;
                    element.display = true;
                  }
                )
              }

            } else {
              element.display = false;
            }
          } else {
            let res = "<span>" + this.forms[element.dependence.toMatchPos].get(element.dependence.toMatchName).value + "</span>";
            let newBody = element.body.replace(/<span>.*<\/span>/, res);
            element.body = newBody;
            element.display = true;
          }
        }
      );
    }
  }

  /**
   * Submit all form
   * @param form
   * @param indexForm
   */
  async onSubmit(form, indexForm, captchaResponse) {
    this.btnNextDisabled = true;
    if (form.valid) {

      if (indexForm === 0) {
        await this.eventInfoPersonal(form, captchaResponse);
        this.btnNextDisabled = false;
      } else if (indexForm === 1) {
        await this.eventInfoCompany(form);
        this.btnNextDisabled = false;
      } else if (indexForm === 2) {
        await this.eventAccountCompany(form, captchaResponse);
        this.btnNextDisabled = false;
      } else if (indexForm === 3 || indexForm === 4) {
        if (this.register_type !== "qr") {
          await this.eventNotification(form);
          this.btnNextDisabled = false;
        } else {
          await this.eventNotificationSticker(form, captchaResponse);
          this.btnNextDisabled = false;
        }
      }

    } else {
      this.showModalInfo('modalError');
      this.forms[indexForm].reset();
      return false;
    }
  }

  /**
   * evento tab notificaciones si esta en el flujo de sticker
   * @param form
   */
  eventNotificationSticker(form, captchaResponse) {
    if (form.value['elegirCel']) {
      if (form.value['elegirCel'] === "0") {

        //llamar servicio otp

        /* this.dataFlow.otp = 0;
        this.dataFlow.countOtp = 0;
 */
        let numberLocal = undefined;
        if (this.observablesService.getLocalStorage('number')) {
          numberLocal = this.observablesService.getLocalStorage('number');
        }

        if (this.dataFlow.otpReport === 0 && numberLocal.number != form.value.phoneNumber) {

          let number = form.value.phoneNumber;
          /*  this.observablesService.setLocalStorage('number', { number });
  */
          let body = {
            Channel: "NBOT04-C001",
            ClientID: "3999999999",
            Destination: {
              "ServiceName": "PartnerService",
              "ServiceOperation": "sendSmsOtp",
              "ServiceRegion": "C001",
              "ServiceVersion": "1.0.0"
            },
            Body: {
              "sendSmsOtpRQ": {
                "phoneNumber": number,
                "captchaResponse": captchaResponse
              }
            }
          }

          this.utilsService.consumeEvent('servicesValidationservicesSendsmsotpPost', body).subscribe((data) => {
            if (!data.error) {
              this.showModalCallback('modalOtp', form.value, this.validatorPhoneReport);
            } else {
              this.showModalInfo('ErrorTecnico');
            }
          });
        } else {
          this.nextTab();
        }

      } else {
        this.nextTab();
      }
    } else {
      this.eventNotification(form);
    }

  }

  /**
   * evento tab notificaciones
   * @param form
   */
  eventNotification(form) {
    //llamar servicio

    let infoRegister: any = this.observablesService.getLocalStorage('infoRegister');

    infoRegister[this.tabShow] = this.forms[this.tabShow].value;
    this.observablesService.setLocalStorage('infoRegister', infoRegister)
    this.observablesService.setLocalStorage('infoValidate', this.dataFlow)

    this.registerSend();
  }

  /**
   * event informacion de la cuenta de tu negocio
   * @param form
   */
  eventAccountCompany(form, captchaResponse) {
    if (form.value['bank_or_nequi'] && form.value['bank_or_nequi'] === 'NEQUI') {
      //enviar modal otp nequi
      //modalOtpNequi

      /*  setTimeout(() => {
         this.codExpiroNequi();
       }, 5000); */
      if (this.dataFlow.otpNequi === 0) {

        let body = {
          Channel: "NBOT04-C001",
          ClientID: "3999999999",
          Destination: {
            "ServiceName": "PartnerService",
            "ServiceOperation": "sendOtpNequiAccount",
            "ServiceRegion": "C001",
            "ServiceVersion": "1.0.0"
          },
          Body: {
            "sendOtpNequiAccountRQ": {
              "phoneNumber": this.forms[0].value.phoneNumber,
              "captchaResponse": captchaResponse
            }
          }
        }

        this.utilsService.consumeEvent('servicesValidationservicesSendotpnequiaccountPost', body).subscribe((data) => {
          if (!data.error) {
            this.showModalCallback('modalOtpNequi', this.forms[0].value, this.validatorNequi);
          }
        });
      } else {
        this.clevertapService.sendEvent('Registro_recaudo_cuenta_nequi', null);
        this.nextTab();
      }

    } else {
      //llamar serivicio cuenta valida con cc o nit

      let account;

      switch (this.dataFlow.flow.join(' ')) {
        case "no_nequi registro_nit":
          account = {
            accountNumber: form.value.number_si_nequi,
            idType: "NIT",
            idNumber: this.forms[1].value.nit,
            accountType: form.value.type_si_nequi
          }
          this.typeAccount = "NIT";
          break;
        case "nequi no_registro_nit":
          account = {
            accountNumber: form.value.accountNum_nequi,
            idType: this.forms[0].value.documentType,
            idNumber: this.forms[0].value.documentNumber,
            accountType: form.value.type_nequi
          }
          this.typeAccount = this.forms[0].value.documentType;
          break;
        case "no_nequi no_registro_nit":
          account = {
            accountNumber: form.value.number_no_nequi,
            idType: this.forms[0].value.documentType,
            idNumber: this.forms[0].value.documentNumber,
            accountType: form.value.type_no_nequi
          }
          this.typeAccount = this.forms[0].value.documentType;
          break;
        case "nequi registro_nit":
          account = {
            accountNumber: form.value.number_si_nequi,
            idType: "NIT",
            idNumber: this.forms[1].value.nit,
            accountType: form.value.type_si_nequi
          }
          this.typeAccount = "NIT";
          break;
      }
      account.captchaResponse = captchaResponse;

      let body = {
        Channel: "NBOT04-C001",
        ClientID: "3999999999",
        Destination: {
          "ServiceName": "PartnerService",
          "ServiceOperation": "validateBancolombiaAccount",
          "ServiceRegion": "C001",
          "ServiceVersion": "1.0.0"
        },
        Body: {
          "validateBancolombiaAccountsRQ": account,
        }
      }

      this.utilsService.consumeEvent('servicesValidationservicesValidatebancolombiaaccountPost', body).subscribe((data: any) => {
        if (!data.error) {
          this.dataFlow.cuenta.valid = 1;
          this.clevertapService.sendEvent('Registro_recaudo_cuenta_bancolombia', null);
          this.nextTab();
        } else {
          if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-09A') {
            this.dataFlow.cuenta.valid = 1;
            this.nextTab();
          } else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === 'G-07A') {
            this.dataFlow.cuenta.valid = 0;
            this.showModalInfo('CaptchaError');
          } else {
            this.dataFlow.cuenta.valid = 0;
            // validar que caso es
            if (this.typeAccount == "NIT") {
              this.showModalInfo('modalErrorAccountNit');
            } else {
              this.showModalInfo('modalErrorAccountCc');
            }
          }
        }
      });
    }
  }

  /**
   * evento para informacion del negocio funtion: async-await
   * @param form
   */
  async eventInfoCompany(form) {
    if ((form.value['commercialRegistry'] && form.value['commercialRegistry'] === "1") || (this.register_type === 'armario')) {
      // llamar servicio valid nit
      // llamar servivio razon, name
      let commerceExist = await this.validateCommerce(true, form);
      if (commerceExist) {

        //validar comercio centrales   --------------------------------------------------------------validar
        let listCommerce: any = await this.validateRiskCentrals(true, form);

        this.dataFlow.comercio.list = listCommerce;
        if (this.dataFlow.comercio.list === 0) {

          if (this.dataFlow.nequi == 1) {
            this.dataFlow.flow = ["nequi", "registro_nit"];
            this.observablesService.observableRegistro({ flow: ["nequi", "registro_nit"] });
          } else {
            this.dataFlow.flow = ["no_nequi", "registro_nit"];
            this.observablesService.observableRegistro({ flow: ["no_nequi", "registro_nit"] });
          }

          // llamar servicio representante valid cc // validar fecha expe..
          // llamar servicio representante list cc -------------------------------------------------validar

          let listRepresentative: any = await this.validateRiskCentrals(false, form);

          this.dataFlow.representante.list = listRepresentative;

          if (this.dataFlow.representante.ccValid == 1 && this.dataFlow.representante.list == 0 && this.dataFlow.representante.dateEx == 1) {
            this.clevertapService.sendEvent('Registro_negocio_con_matricula', {'Categoria_negocio':form.value.category, 'Ciudad':form.value.city, 'Departamento':form.value.department});
            this.nextTab();
          } else {
            this.showModalInfo('modalErrorRepresentante');
            return false;
          }

        } else {
          this.showModalInfo('modalError');
          return false;
        }

      }

    } else {

      let commerceExist = await this.validateCommerce(false, form);
      if (commerceExist) {

        if (this.dataFlow.nequi == 1) {
          this.dataFlow.flow = ["nequi", "no_registro_nit"];
          this.observablesService.observableRegistro({ flow: ["nequi", "no_registro_nit"] });
        } else {
          this.dataFlow.flow = ["no_nequi", "no_registro_nit"];
          this.observablesService.observableRegistro({ flow: ["no_nequi", "no_registro_nit"] });
        }
        this.clevertapService.sendEvent('Registro_negocio_sin_matricula', {'Categoria_negocio':form.value.category, 'Ciudad':form.value.city, 'Departamento':form.value.department});
        this.nextTab();

      }

    }

  }

  pad(n, width, z?) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  private validateRiskCentrals(commerce, form) {

    let body = {
      Channel: "PN01-C001",
      ClientID: "3999999999",
      Destination: {
        "ServiceName": "ValidationServices",
        "ServiceOperation": "validatePersonInRiskCentrals",
        "ServiceRegion": "C001",
        "ServiceVersion": "1.0.0"
      },
      Body: {
      }
    }

    let bodyRepresentative = {
      Channel: "NegociosNequi",
      ClientID: "3999999999",
      Destination: {
        "ServiceName": "PartnerService",
        "ServiceOperation": "validatePersonalInformation",
        "ServiceRegion": "C001",
        "ServiceVersion": "1.0.0"
      },
      Body: {}
    }

    if (commerce) {
      body.Body = {
        "validatePersonInRiskCentralsRQ": {
          "typeId": "NIT",
          "idNumber": form.value.nit,
          "name1": form.value.name
        }
      }
    } else {

      let is2d = (this.forms[1].value.expeditionDate.day.toString().match(/^\d{2}$/));
      let is2m = (this.forms[1].value.expeditionDate.month.toString().match(/^\d{2}$/));

      body.Body = {
        "validatePersonInRiskCentralsRQ": {
          "typeId": form.value.documentType,
          "idNumber": form.value[`document${form.value.documentType}`],
          "name1": form.value.representativeName,
          "expeditionDate": form.value.expeditionDate.year + '-' + (is2m != null ? this.forms[1].value.expeditionDate.month : '0' + this.forms[1].value.expeditionDate.month) + '-' + (is2d != null ? this.forms[1].value.expeditionDate.day : '0' + this.forms[1].value.expeditionDate.day),
        }
      }

      bodyRepresentative.Body = {
        "validatePersonalInformationRQ": {
          "representative": true,
          "documentType": form.value.documentType,
          "documentNumber": form.value[`document${form.value.documentType}`],
          "expeditionDate": form.value.expeditionDate.year + '-' + (is2m != null ? this.forms[1].value.expeditionDate.month : '0' + this.forms[1].value.expeditionDate.month) + '-' + (is2d != null ? this.forms[1].value.expeditionDate.day : '0' + this.forms[1].value.expeditionDate.day),
          "name": form.value.representativeName
        }
      }
    }

    return this.utilsService.consumeEvent('servicesValidationservicesValidatepersoninriskcentralsPost', body).toPromise().then(async data => {
      if (!data.error) {
        if (commerce) {
          return 0;
        } else {
          let dataRepresentative = await this.utilsService.consumeEvent('servicesValidationservicesValidatepersonalinformationPost', bodyRepresentative).toPromise();
          if (!dataRepresentative.error) {
            return 0;
          } else {
            return 1;
          }
        }
      } else {
        return 1;
      }
    });
  }

  /**
   * Evento para validar si un comercio esta registrado
   * @param nit <boolean> especifica si el comercio se verifica con nit
   * @param form <object> formulario con los datos necesarios para la verificacion
   */
  private validateCommerce(nit, form) {

    let body = {
      Channel: "PN01-C001",
      ClientID: "3999999999",
      Destination: {
        "ServiceName": "PartnerService",
        "ServiceOperation": "validateUnregisteredCommerce",
        "ServiceRegion": "C001",
        "ServiceVersion": "1.0.0"
      },
      Body: {
      }
    }

    if (nit) {
      body.Body = {
        "validateUnregisteredCommerceRQ": {
          "documentType": "NIT",
          "documentNumber": form.value.nit,
          "name": form.value.name,
          "socialReason": form.value.socialReason
        }
      }
    } else {
      body.Body = {
        "validateUnregisteredCommerceRQ": {
          "documentType": this.forms[0].value.documentType,
          "documentNumber": this.forms[0].value.documentNumber,
          "name": form.value.name,
          "socialReason": form.value.socialReason
        }
      }
    }

    return this.utilsService.consumeEvent('servicesValidationservicesValidateunregisteredcommercePost', body).toPromise().then(data => {

      if (!data.error) {
        return true;
      } else {
        if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-09A') {
          this.showModalCallback('commerceExist', form, (evento: EventModalClick) => {
            let thisClass = evento.modelo.extraData.this;
            thisClass.router.navigate(['/privado/perfil']);
          });
        }
        return false;
      }
    });
  }

  /**
   * evento para informacion personal
   * @param form
   */
  async eventInfoPersonal(form, captchaResponse) {
    //validar nequi

    // validar cc and ccEx
    await this.validateNequiCentralsRisk(form);

    if (this.dataFlow.nequi === 0 && this.dataFlow.list === 1) {

      /* this.forms[0].reset(); */
      return false;
    }

    let numberLocal = undefined;
    if (this.observablesService.getLocalStorage('number')) {
      numberLocal = this.observablesService.getLocalStorage('number');
    }

    if (this.dataFlow.otp === 0 || numberLocal.number != form.value.phoneNumber) {
      let number = form.value.phoneNumber;
      this.observablesService.setLocalStorage('number', { number });

      let body = {
        Channel: "NBOT04-C001",
        ClientID: "3999999999",
        Destination: {
          "ServiceName": "PartnerService",
          "ServiceOperation": "sendSmsOtp",
          "ServiceRegion": "C001",
          "ServiceVersion": "1.0.0"
        },
        Body: {
          "sendSmsOtpRQ": {
            "phoneNumber": number,
            "captchaResponse": captchaResponse
          }
        }
      }


      this.utilsService.consumeEvent('servicesValidationservicesSendsmsotpPost', body).subscribe((data) => {
        if (!data.error) {
          this.clevertapService.sendEvent('Inicio_registro_negocios', {'Tipo_documento':form.value.documentType.toUpperCase()})
          this.showModalCallback('modalOtp', form.value, this.validatorPhone);
        } else {
          if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === 'G-07A') {
            form.reset();
            this.showModalCallback('modalOtp', form.value, this.validatorPhone);
            //this.showModalInfo('CaptchaError');
          } else {
            this.showModalInfo('ErrorTecnico');
          }
        }
      });
    } else {
      let number = form.value.phoneNumber;
      this.observablesService.setLocalStorage('number', { number });
      this.clevertapService.sendEvent('Inicio_registro_negocios', {'Tipo_documento':form.value.documentType.toUpperCase()})
      this.nextTab();
    }

  }

  validateNequiCentralsRisk(form) {

    let is2d = (this.forms[0].value.documentExpeditionDate.day.toString().match(/^\d{2}$/));
    let is2m = (this.forms[0].value.documentExpeditionDate.month.toString().match(/^\d{2}$/));

    let body = {
      Channel: "NegociosNequi",
      ClientID: "3999999999",
      Destination: {
        "ServiceName": "PartnerService",
        "ServiceOperation": "validatePersonalInformation",
        "ServiceRegion": "C001",
        "ServiceVersion": "1.0.0"
      },
      Body: {
        "validatePersonalInformationRQ": {
          "phoneNumber": form.value.phoneNumber,
          "documentType": form.value.documentType.toUpperCase(),
          "documentNumber": form.value.documentNumber,
          "expeditionDate": form.value.documentExpeditionDate.year + '-' + (is2m != null ? this.forms[0].value.documentExpeditionDate.month : '0' + this.forms[0].value.documentExpeditionDate.month) + '-' + (is2d != null ? this.forms[0].value.documentExpeditionDate.day : '0' + this.forms[0].value.documentExpeditionDate.day),
        }
      }
    }

    return this.utilsService.consumeEvent('servicesValidationservicesValidatepersonalinformationPost', body).toPromise().then(data => {
      if (!data.error) {
        if (data.validatePersonalInformationRQ.nequi) {
          this.dataFlow.nequi = 1;
        } else {
          this.dataFlow.nequi = 0;
        }
        this.dataFlow.list = 0;
      } else {
        this.dataFlow.nequi = 0;
        this.dataFlow.list = 1;
        if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode != '20-07A') {
          this.showModalInfo('modalError');
        }
      }
    });

  }

  /**
   * @description - Valida si en el formulario actual existe un input del tipo terminos_product
   */
  getTycProductInput() {
    const messages = this.messages[this.tabShow];
    const inputs = messages ? messages.inputs : [];
    const inputsTyC = inputs.filter(input => input.name === RegistryConfig.controlTyC);

    if (inputsTyC.length === 1) {
      return inputsTyC[0];
    }

    return inputsTyC.find(input => input.dependence.name === this.type_api);
  }

    /**
   * @description - Valida si en las opciones configuradas del producto de registro se encuentra la propiedad pdf
   */
  getProductPdf(tycProductInput) {
    const options = tycProductInput.options || [];
    const product = options.find(option => option.hasOwnProperty(RegistryConfig.propertyPdf));
    return product ? product.pdf : undefined;
  }

  removeElementsFromArray(array) {
    while (array.length !== 0) {
      array.removeAt(0);
    }
  }


  getProductName() {
    const form = this.forms[this.tabShow];

    if (!form.value.hasOwnProperty(RegistryConfig.controlTyC)) {
      return;
    }

    const productInput = this.getTycProductInput();

    if (!productInput) {
      return;
    }

    const product = this.getProductPdf(productInput);

    if (product) {
      const tycInput = form.get(RegistryConfig.controlTyC);
      this.removeElementsFromArray(tycInput);

      const control = new FormControl(product);
      tycInput.push(control);
    }

  }

  /**
   * Evento siguiente registro
   */
  nextTab() {

    let infoRegister: any = this.observablesService.getLocalStorage('infoRegister');

    if (!infoRegister) {
      let array: any = [];
      array[this.tabShow] = this.forms[this.tabShow].value;
      this.observablesService.setLocalStorage('infoRegister', array)
    } else {
      infoRegister[this.tabShow] = this.forms[this.tabShow].value;
      this.observablesService.setLocalStorage('infoRegister', infoRegister)
    }


    this.observablesService.setLocalStorage('infoValidate', this.dataFlow)

    this.tabShow++;
    window.scrollTo(0, 0);
    let url;
    if (this.tabShow == 1) {
      url = "Informacion del negocio";
    } else if (this.tabShow == 2) {
      url = "la cuenta de tu negocio";
    } else if (this.tabShow == 3 || this.tabShow == 4) {
      this.getProductName();
      url = "Notificaciones y reportes";
    }
    this.eventClick(url);
    /* console.log(this.dataFlow.flow);
    console.log(this.forms); */
  }

  eventClick(url) {
    this.googleAnalyticsService.emitPage(url);
  }

  /**
   * Evento atras registro
   */
  backTab() {
    /* if(this.tabShow == 2){
      this.messages[this.tabShow].inputs.map((input)=>{
        input.display = false;
      });
    } */
    this.tabShow--;
  }

  /**
  * Callback que devuelve el modal otp nequi
  * @param evento
  */
  validatorNequi(evento: EventModalClick) {
    /* console.log("seleccionado en boton header: " + evento.source.text); */
    //servicio validador de OTP
    let codOtp = evento.modelo.dataModal.otpcodigo;
    let thisClass = evento.modelo.extraData.this;
    let form = evento.modelo.extraData.form;

    if (evento.modelo) {
      //llamar servicio otp app nequi

      let body = {
        Channel: "NBOT04-C001",
        ClientID: "3999999999",
        Destination: {
          "ServiceName": "PartnerService",
          "ServiceOperation": "validateOtpNequiAccount",
          "ServiceRegion": "C001",
          "ServiceVersion": "1.0.0"
        },
        Body: {
          "validateOtpNequiAccountRQ": {
            "phoneNumber": form.phoneNumber,
            "otp": codOtp
          }
        }
      }

      thisClass.utilsService.consumeEvent('servicesValidationservicesValidateotpnequiaccountPost', body).subscribe((data) => {

        if (!data.error) {
          thisClass.dataFlow.otpNequi = 1;
        } else {
          thisClass.dataFlow.otpNequi = 0;
        }

        if (thisClass.dataFlow.otpNequi == 0 && thisClass.dataFlow.countOtpNequi < 3) {


          if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'BAD_CODE') {
            thisClass.dataFlow.countOtpNequi++;
            thisClass.noValidOtpNequi(form);
          } else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'EXPIRED_CODE') {
            thisClass.codExpiroNequi();
          }

        } else if (thisClass.dataFlow.countOtpNequi == 3) {
          if (thisClass.dataFlow.tryOtpNequi < 1) {
            thisClass.countOtpNequi(form);
          } else {
            thisClass.tryTwoNequi(form);
          }
        } else if (thisClass.dataFlow.otpNequi == 1) {
          this.clevertapService.sendEvent('Registro_recaudo_cuenta_nequi', null);
          thisClass.nextTab();
        }

      });
    }
  }



  codExpiroNequi() {
    if (this.dataFlow.otpNequi === 0) {
      if (this.dataFlow.tryOtpNequi < 1) {
        this.showModalInfo('errorOtpNequiTime');
        this.dataFlow.countOtpNequi = 0;
        this.dataFlow.tryOtpNequi++;
        return false;
      } else {
        this.tryTwoNequi();
      }
      // reset forms

    }
  }


  tryTwoNequi() {
    this.showModalInfo('errorOtpNequiTryTwo');

    this.dataFlow = {
      nequi: 1,
      ahorroApi: 1,

      otp: 0,
      countOtp: 0,

      otpReport: 0,
      countOtpReport: 0,

      otpEmail: 0,
      countOtpEmail: 0,

      otpNequi: 0,
      countOtpNequi: 0,
      tryOtpNequi: 0,

      bloqueo: 0,
      list: 0,
      comercio: {
        list: 0,
        name: 0
      },
      representante: {
        ccValid: 1,
        list: 0,
        dateEx: 1
      },
      cuenta: {
        valid: 1
      },

      location: {
        department: [],
        city: []
      },

      flow: []
    }
    // reset all forms
    /*  this.forms.map((form) => {
       form.reset();
     }); */
    this.tabShow = 0;
    // reset forms
    return false;
  }

  countOtpNequi() {
    this.showModalInfo('errorCountOtpNequi');
    this.dataFlow.countOtpNequi = 0;
    this.dataFlow.tryOtpNequi++;
    // reset forms
    return false;
  }

  noValidOtpNequi(form) {
    this.showModalCallback('modalOtpErrorNequi', form, this.validatorNequi);
  }

  /**
   * Callback que devuelve el modal otp
   * @param evento
   */
  validatorPhone(evento: EventModalClick, captchaResponse) {
    /* console.log("seleccionado en boton header: " + evento.source.text); */
    //servicio validador de OTP
    let codOtp = evento.modelo.dataModal.otpcodigo;
    let thisClass = evento.modelo.extraData.this;
    let form = evento.modelo.extraData.form;

    if (evento.source == 'Pedir otro codigo') {
      let body = {
        Channel: "NBOT04-C001",
        ClientID: "3999999999",
        Destination: {
          "ServiceName": "PartnerService",
          "ServiceOperation": "sendSmsOtp",
          "ServiceRegion": "C001",
          "ServiceVersion": "1.0.0"
        },
        Body: {
          "sendSmsOtpRQ": {
            "phoneNumber": form.phoneNumber,
            "captchaResponse": captchaResponse
          }
        }
      }


      thisClass.utilsService.consumeEvent('servicesValidationservicesSendsmsotpPost', body).subscribe((data) => {
        if (!data.error) {

        } else {
          this.showModalInfo('ErrorTecnico');
        }
      });
    } else {
      if (evento.modelo) {
        //llamar servicio  validar

        let body = {
          Channel: "NBOT04-C001",
          ClientID: "3999999999",
          Destination: {
            "ServiceName": "PartnerService",
            "ServiceOperation": "validateSmsOtp",
            "ServiceRegion": "C001",
            "ServiceVersion": "1.0.0"
          },
          Body: {
            "validateSmsOtpRQ": {
              "phoneNumber": form.phoneNumber,
              "otp": codOtp
            }
          }
        }

        thisClass.utilsService.consumeEvent('servicesValidationservicesValidatesmsotpPost', body).subscribe((data) => {

          if (!data.error) {
            thisClass.dataFlow.otp = 1;
            thisClass.clevertapService.sendEvent('Confirmacion_registro_otp', null);
          } else {
            thisClass.dataFlow.otp = 0;
          }

          if (thisClass.dataFlow.otp == 0 && thisClass.dataFlow.countOtp < 3) {
            if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'BAD_CODE') {
              thisClass.dataFlow.countOtp++;
              thisClass.noValidOtp(form);
            } else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'EXPIRED_CODE') {
              thisClass.expiroOtpPhone(form);
            }
          } else if (thisClass.dataFlow.countOtp == 3) {
            thisClass.countOtp(form);
          } else if (thisClass.dataFlow.otp == 1) {
            thisClass.nextTab();
          }
        });
      }
    }


  }

  /**
   * Callback que devuelve el modal otp
   * @param evento
   */
  validatorPhoneReport(evento: EventModalClick) {
    /* console.log("seleccionado en boton header: " + evento.source.text); */
    //servicio validador de OTP
    let codOtp = evento.modelo.dataModal.otpcodigo;
    let thisClass = evento.modelo.extraData.this;
    let form = evento.modelo.extraData.form;


    if (evento.modelo) {
      //llamar servicio  validar

      let body = {
        Channel: "NBOT04-C001",
        ClientID: "3999999999",
        Destination: {
          "ServiceName": "PartnerService",
          "ServiceOperation": "validateSmsOtp",
          "ServiceRegion": "C001",
          "ServiceVersion": "1.0.0"
        },
        Body: {
          "validateSmsOtpRQ": {
            "phoneNumber": form.phoneNumber,
            "otp": codOtp
          }
        }
      }

      thisClass.utilsService.consumeEvent('servicesValidationservicesValidatesmsotpPost', body).subscribe((data) => {

        if (!data.error) {
          thisClass.dataFlow.otpReport = 1;
        } else {
          thisClass.dataFlow.otpReport = 0;
        }

        if (thisClass.dataFlow.otpReport == 0 && thisClass.dataFlow.countOtpReport < 3) {
          if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'BAD_CODE') {
            thisClass.dataFlow.countOtpReport++;
            thisClass.noValidOtp(form);
          } else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'EXPIRED_CODE') {
            thisClass.expiroOtpPhone(form);
          }
        } else if (thisClass.dataFlow.countOtpReport == 3) {
          thisClass.countOtp(form);
        } else if (thisClass.dataFlow.otpReport == 1) {
          thisClass.nextTab();
        }
      });
    }
  }

  expiroOtpPhone() {
    this.dataFlow.otp == 0;
    this.dataFlow.countOtp == 0;
    this.dataFlow.otpReport == 0;
    this.dataFlow.countOtpReport == 0;
    this.showModalInfo('codigoExpiroPhone');
  }

  countOtp() {
    this.showModalInfo('errorCountOtp');
    this.dataFlow.countOtp = 0;
    this.dataFlow.countOtpReport == 0;
    // reset forms
    return false;
  }

  noValidOtp(form) {
    this.showModalCallback('modalOtpError', form, this.validatorPhone);
  }


  /**
   * Callback que devuelve el modal otp email
   * @param evento
   */
  validatorEmail(evento: EventModalClick) {
    /* console.log("seleccionado en boton header: " + evento.source.text); */
    //servicio validador de OTP email
    let codOtp = evento.modelo.dataModal.otpcodigo;
    let thisClass = evento.modelo.extraData.this;
    let form = evento.modelo.extraData.form;
    if (evento.modelo) {
      //llamar servicio otp email          this.observablesService.setLocalStorage('infoRegister', []);

      let body = {
        Channel: "NBOT04-C001",
        ClientID: "3999999999",
        Destination: {
          "ServiceName": "PartnerService",
          "ServiceOperation": "validateEmail",
          "ServiceRegion": "C001",
          "ServiceVersion": "1.0.0"
        },
        Body: {
          "validateEmailRQ": {
            "username": form.username,
            "confirmationCode": codOtp
          }
        }
      }

      thisClass.utilsService.consumeEvent('servicesValidationservicesValidateemailPost', body).subscribe((data) => {

        if (!data.error) {
          thisClass.dataFlow.otpEmail = 1;
          thisClass.clevertapService.sendEvent('Finalizacion_registro_negocios', {'Email_registro':form.email});
        } else {
          thisClass.dataFlow.otpEmail = 0;
        }

        if (thisClass.dataFlow.otpEmail == 0 && thisClass.dataFlow.countOtpEmail < 3) {
          if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'NOT_VALID_CODE' || data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'NOT_VALID_STATUS') {
            thisClass.dataFlow.countOtpEmail++;
            thisClass.noValidOtpEmail(form);
          } else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'EXPIRED_CODE') {
            thisClass.expiroOtpEmail(form);
          }
        } else if (thisClass.dataFlow.countOtpEmail == 3) {
          thisClass.countOtpEmail(form);
        } else if (thisClass.dataFlow.otpEmail == 1) {

          if (data.validateEmailRS.validated == "true") {
            thisClass.registerSuccess = true;
          } else {
            thisClass.registerSuccessConfirmation = true;
          }

          let dataFlow = {
            nequi: 1,
            ahorroApi: 1,

            otp: 0,
            countOtp: 0,

            otpReport: 0,
            countOtpReport: 0,

            otpEmail: 0,
            countOtpEmail: 0,

            otpNequi: 0,
            countOtpNequi: 0,
            tryOtpNequi: 0,

            bloqueo: 0,
            list: 0,
            comercio: {
              list: 0,
              name: 0
            },
            representante: {
              ccValid: 1,
              list: 0,
              dateEx: 1
            },
            cuenta: {
              valid: 1
            },

            location: {
              department: [],
              city: []
            },

            flow: []
          }

          this.observablesService.setLocalStorage('infoRegister', {});
          this.observablesService.setLocalStorage('infoValidate', dataFlow)


          window.scrollTo(0, 0);
        }

      });
    }
  }

  expiroOtpEmail(form) {
    let body = {
      Channel: "NegociosNequi",
      ClientID: "3999999999",
      Destination: {
        "ServiceName": "PartnerService",
        "ServiceOperation": "deletePartner",
        "ServiceRegion": "C001",
        "ServiceVersion": "1.0.0"
      },
      Body: {
        "deletePartnerRQ": {
          "username": form.username
        }
      }
    }

    this.utilsService.consumeEvent('servicesPartnerservicesDeletepartnerPost', body).subscribe((data) => {
      if (!data.error) {
        this.showModalInfo('codigoExpiro');
        this.dataFlow = {
          nequi: 1,
          ahorroApi: 1,

          otp: 0,
          countOtp: 0,

          otpReport: 0,
          countOtpReport: 0,

          otpEmail: 0,
          countOtpEmail: 0,

          otpNequi: 0,
          countOtpNequi: 0,
          tryOtpNequi: 0,

          bloqueo: 0,
          list: 0,
          comercio: {
            list: 0,
            name: 0
          },
          representante: {
            ccValid: 1,
            list: 0,
            dateEx: 1
          },
          cuenta: {
            valid: 1
          },

          location: {
            department: [],
            city: []
          },

          flow: []
        }

        /* this.forms[0].reset();
        this.forms[1].reset();
        this.forms[2].reset();
        this.forms[3].reset();


        if (this.forms.length === 5) {
          this.forms[4].reset();
        }
 */
        this.tabShow = 0;
        return false;
      } else {
        if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'USER_NOT_FOUND' || data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'NOT_VALID_STATUS') {
          this.showModalInfo('ErrorTecnico');
        }
      }
    });
  }


  countOtpEmail(form) {
    let body = {
      Channel: "NegociosNequi",
      ClientID: "3999999999",
      Destination: {
        "ServiceName": "PartnerService",
        "ServiceOperation": "deletePartner",
        "ServiceRegion": "C001",
        "ServiceVersion": "1.0.0"
      },
      Body: {
        "deletePartnerRQ": {
          "username": form.username
        }
      }
    }

    this.utilsService.consumeEvent('servicesPartnerservicesDeletepartnerPost', body).subscribe((data) => {
      if (!data.error) {
        this.showModalInfo('errorCountOtpEmail');
        this.dataFlow = {
          nequi: 1,
          ahorroApi: 1,

          otp: 0,
          countOtp: 0,

          otpReport: 0,
          countOtpReport: 0,

          otpEmail: 0,
          countOtpEmail: 0,

          otpNequi: 0,
          countOtpNequi: 0,
          tryOtpNequi: 0,

          bloqueo: 0,
          list: 0,
          comercio: {
            list: 0,
            name: 0
          },
          representante: {
            ccValid: 1,
            list: 0,
            dateEx: 1
          },
          cuenta: {
            valid: 1
          },

          location: {
            department: [],
            city: []
          },

          flow: []
        }

        /* this.forms[0].reset();
        this.forms[1].reset();
        this.forms[2].reset();
        this.forms[3].reset();


        if (this.forms.length === 5) {
          this.forms[4].reset();
        } */

        this.tabShow = 0;
        return false;
      } else {
        if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'USER_NOT_FOUND' || data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc == 'NOT_VALID_STATUS') {

          this.showModalInfo('ErrorTecnico');
        }
      }
    });
  }

  noValidOtpEmail(form) {
    this.showModalCallback('modalOtpErrorEmail', form, this.validatorEmail);
  }

  /**
   * Evento que finaliza el registro
   */
  async registerSend() {
    // map array forms

    let business: any = {};
    let schedule: any = [];
    let account: any = {};
    let notificationsAndReports: any = {};
    let product: any = {};
    let formOtp;

    if ((this.forms[1].value.commercialRegistry && this.forms[1].value.commercialRegistry === "1") || (this.register_type === 'armario')) {

      let is2d = (this.forms[1].value.expeditionDate.day.toString().match(/^\d{2}$/));
      let is2m = (this.forms[1].value.expeditionDate.month.toString().match(/^\d{2}$/));

      let category;

      if (!!this.forms[1].value.category) {
        category = this.forms[1].value.category;
      }


      business = {
        commercialRegistry: "true",
        socialReason: this.forms[1].value.socialReason,
        name: this.forms[1].value.name.trim(),
        category: category,
        nit: this.forms[1].value.nit,

        legalRepresentative: {
          name: this.forms[1].value.representativeName,
          documentType: this.forms[1].value.documentType,
          document: this.forms[1].value[`document${this.forms[1].value.documentType}`],
          expeditionDate: this.forms[1].value.expeditionDate.year + '-' + (is2m != null ? this.forms[1].value.expeditionDate.month : '0' + this.forms[1].value.expeditionDate.month) + '-' + (is2d != null ? this.forms[1].value.expeditionDate.day : '0' + this.forms[1].value.expeditionDate.day),
        },

        department: this.forms[1].value.department,
        city: this.forms[1].value.city,
        address: this.forms[1].value.d_tipo_direccion + ' ' + this.forms[1].value.d_direccion.trim() + ' # ' + this.forms[1].value.d_num_direccion_1.trim() + ' - ' + this.forms[1].value.d_num_direccion_2.trim() + ' ' + this.forms[1].value.info_extra.trim(),
        webPage: this.forms[1].value.webPage,

        physicalStore: (this.forms[1].value.physicalStore === '1') ? 'true' : 'false'
      }


      if (this.register_type != 'armario')
        business.legalRepresentative.name = this.forms[1].value.representativeName;

    } else if (this.forms[1].value.commercialRegistry && this.forms[1].value.commercialRegistry === "0") {

      let category;

      if (!!this.forms[1].value.category) {
        category = this.forms[1].value.category;
      }

      business = {
        commercialRegistry: "false",
        name: this.forms[1].value.name.trim(),
        category: category,
        department: this.forms[1].value.department,
        city: this.forms[1].value.city,
        address: this.forms[1].value.d_tipo_direccion + ' ' + this.forms[1].value.d_direccion.trim() + ' # ' + this.forms[1].value.d_num_direccion_1.trim() + ' - ' + this.forms[1].value.d_num_direccion_2.trim() + ' ' + this.forms[1].value.info_extra.trim(),

        physicalStore: (this.forms[1].value.physicalStore === '1') ? 'true' : 'false'
      }

      business.webPage = this.forms[1].value.webPage;


    }

    if (!!this.forms[1].value.physicalStore && this.forms[1].value.physicalStore === '1') {
      var days = { 'Lunes': 'MONDAY', 'Martes': 'TUESDAY', 'Miercoles': 'WEDNESDAY', 'Jueves': 'THRUSDAY', 'Viernes': 'FRIDAY', 'Sabado': 'SATURDAY', 'Domingo': 'SUNDAY', 'Festivos': 'HOLIDAYS' };
      for (var key in days) {
        if (this.forms[1].value[key] == '1') {
          let sche = {
            "day": days[key],
            "am": {
              "since": this.forms[1].value['desdeAm' + key],
              "to": this.forms[1].value['hastaAm' + key]
            },
            "pm": {
              "since": this.forms[1].value['desdePm' + key],
              "to": this.forms[1].value['hastaPm' + key]
            }
          }
          schedule.push(sche);
        }
      }
      if (schedule.length === 0) {
        schedule = [{}];
      }
      business.schedule = schedule;
    }


    switch (this.dataFlow.flow.join(' ')) {
      case "nequi registro_nit":
        account = {
          bank: "BANCOLOMBIA",
          type: this.forms[2].value.type_si_nequi,
          number: this.forms[2].value.number_si_nequi
        }
        break;
      case "no_nequi registro_nit":
        account = {
          bank: "BANCOLOMBIA",
          type: this.forms[2].value.type_si_nequi,
          number: this.forms[2].value.number_si_nequi
        }
        break;
      case "nequi no_registro_nit":

        if (this.forms[2].value.bank_or_nequi == 'NEQUI') {
          account = {
            bank: this.forms[2].value.bank_or_nequi,
            type: "37",
            number: this.forms[0].value.phoneNumber
          }
        } else {
          account = {
            bank: this.forms[2].value.bank_or_nequi,
            type: this.forms[2].value.type_nequi,
            number: this.forms[2].value.accountNum_nequi
          }
        }

        break;
      case "no_nequi no_registro_nit":
        account = {
          bank: "BANCOLOMBIA",
          type: this.forms[2].value.type_no_nequi,
          number: this.forms[2].value.number_no_nequi
        }
        break;
    }

    if (this.register_type === "qr") {

      let password = await this.utilsService.encryptString(this.forms[4].value.password);

      notificationsAndReports = {
        email: this.forms[4].value.email,
        username: this.forms[4].value.username,
        password,
        monthlyReports: "true"
      }

      if (this.forms[3].value.elegirCel == '0') {
        notificationsAndReports.phoneNumber = this.forms[3].value.phoneNumber;
      }

      product = {
        productName: this.forms[4].value.terminos_product.length > 0 ? this.forms[4].value.terminos_product[0] : 'APIS_DISPERSIONES'
      }

      formOtp = this.forms[4].value;
    } else {

      let password = await this.utilsService.encryptString(this.forms[3].value.password);

      notificationsAndReports = {
        email: this.forms[3].value.email,
        username: this.forms[3].value.username,
        password,
        dailyReports: ((!!this.forms[3].value.dailyReports && this.forms[3].value.dailyReports.length == 1) || (this.register_type == 'armario')) ? 'true' : 'false',
        monthlyReports: ((!!this.forms[3].value.monthlyReports && this.forms[3].value.monthlyReports.length == 1) || (this.register_type == 'armario')) ? 'true' : 'false'
      }

      if (this.forms[3].value.terminos_product.length > 0) {
        product['productName'] = this.forms[3].value.terminos_product[0]
      } else if (this.register_type === 'armario' || this.register_type === 'dispersiones') {
        product['productName'] = this.register_type === 'armario' ? 'ARMARIO' : 'APIS_DISPERSIONES'
      }

      formOtp = this.forms[3].value;
    }

    if (product.productName != 'APIS_DISPERSIONES') {
      product.contractId = await this.getIdContract(product.productName);
    } else {
      product.contractId = 'N/A';
    }

    let is2d = (this.forms[0].value.documentExpeditionDate.day.toString().match(/^\d{2}$/));
    let is2m = (this.forms[0].value.documentExpeditionDate.month.toString().match(/^\d{2}$/));

    if (product.productName === 'QR_ESTATICO' && !!this.externalPartner) {
      product.externalPartner = this.externalPartner;
    }

    let body = {
      Channel: "NegociosNequi",
      ClientID: "3999999999",
      Destination: {
        ServiceName: "PartnerService",
        ServiceOperation: "createPartner",
        ServiceRegion: "C001",
        ServiceVersion: "1.0.0"
      },
      Body: {
        createPartnerRQ: {
          personal: {
            name: this.forms[0].value.name.trim(),
            documentType: this.forms[0].value.documentType,
            documentNumber: this.forms[0].value.documentNumber,
            documentExpeditionDate: this.forms[0].value.documentExpeditionDate.year + '-' + (is2m != null ? this.forms[0].value.documentExpeditionDate.month : '0' + this.forms[0].value.documentExpeditionDate.month) + '-' + (is2d != null ? this.forms[0].value.documentExpeditionDate.day : '0' + this.forms[0].value.documentExpeditionDate.day),
            phoneNumber: this.forms[0].value.phoneNumber,
            acceptHabeasData: (this.forms[0].value.acceptHabeasData.length == 1) ? 'true' : 'false'
          },
          business,
          account,
          notificationsAndReports,
          product
        }
      }
    }


    this.utilsService.consumeEvent('servicesPartnerservicesCreatepartnerPost', body).subscribe((data) => {
      if (!data.error) {
        if (this.dataFlow.otpEmail === 0) {
          this.showModalCallback('modalOtpEmail', formOtp, this.validatorEmail);
        }
      } else {
        if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc === "USERNAME_ALREADY_EXISTS") {
          this.showModalInfo('errorUserExist');
        } else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusDesc === "EMAIL_ALREADY_EXISTS") {
          this.showModalInfo('errorEmailExist');
        } else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-08A') {
          if (this.typeAccount == "NIT") {
            this.showModalInfo('modalErrorAccountNit');
          } else {
            this.showModalInfo('modalErrorAccountCc');
          }
        } else if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode === '20-09A') {
          this.showModalInfo('modalErrorDataBad');
        } else {
          this.showModalInfo('ErrorTecnico');
        }
      }
    });
    /* this.registerSuccessConfirmation = true;
    this.registerSuccess = true; */

  }

  getIdContract(contract) {
    let body = {
      Channel: "NegociosNequi-Nequi",
      ClientID: "3999999999",
      Destination: {
        "ServiceName": "GeneralServices",
        "ServiceOperation": "getContractInfoByProduct",
        "ServiceRegion": "C001",
        "ServiceVersion": "1.0.0"
      },
      Body: {
        "getContractInfoByProductRQ": {
          "product": contract
        }
      }
    }

    return this.utilsService.consumeEvent('servicesGeneralservicesGetcontractinfobyproductPost', body).toPromise().then((data) => {
      return data.getContractInfoByProductRS[contract].id;
    });
  }

  /**
   * modal de solo informacion
   * @param modelo
   */
  showModalInfo(modelo) {
    this.observablesService.showModal(
      {
        modelo,
        extraData: {
        },
      }
    );
  }

  /**
   * modal que retorna callback
   * @param modelo nombre del tipo de modal
   * @param form datos del tab (registro) de donde salio el modal
   * @param fn callback que retorna el modal
   */
  showModalCallback(modelo, form, fn) {
    this.observablesService.showModal(
      {
        modelo,
        extraData: {
          form: form,
          this: this
        },
        clicFunc: fn
      }
    );
  }


  eventSoyNequi() {
    if (this.register_type == 'api') {
      this.router.navigate(['/privado/agregar-producto/' + this.type_api]);
    } else if (this.register_type != 'armario') {
      this.router.navigate(['/privado/agregar-producto/' + this.register_type]);
    } else {
      this.router.navigate(['/privado/perfil']);
    }
  }

  /**
   * show TyC for checkbox
   * @param option
   */
  showModalTyc(option) {

    //servicio contrato
    if (option.option.pdf === 'TERMS_CONDITIONS') {
      window.open(RegistryConfig.clauseURL, '_blank');
    } else {
      let body = {
        Channel: "NegociosNequi-Nequi",
        ClientID: "3999999999",
        Destination: {
          "ServiceName": "GeneralServices",
          "ServiceOperation": "getContractInfoByProduct",
          "ServiceRegion": "C001",
          "ServiceVersion": "1.0.0"
        },
        Body: {
          "getContractInfoByProductRQ": {
            "product": option.option.pdf
          }
        }
      }

      this.utilsService.consumeEvent('servicesGeneralservicesGetcontractinfobyproductPost', body).subscribe((data) => {
        this.dataPdf = data.getContractInfoByProductRS[option.option.pdf].url;
        window.open(this.dataPdf, "_blank", "noopener,noreferrer")
        /* this.modalService.open(option.content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'modalTyc' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }); */
      });
    }

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  /* selectDefault(data, input){
    if(data && !input){
      return true;
    }
  } */
}
