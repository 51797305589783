import { Component, OnInit, Input } from '@angular/core';
import { JsonProvider } from '../../services';

@Component({
  selector: 'app-iconos-info',
  templateUrl: './iconos-info.component.html',
  styleUrls: [
    './styles/iconos-info.component.scss',
    './styles/iconos-info.media.960.scss'
  ]
})

export class IconosInfoComponent implements OnInit {

  @Input() public product: any;

  public messages: any;

  constructor(private JsonProvider: JsonProvider) {
    this.JsonProvider.getMessages('iconos-info').subscribe(
      (data) => {
        this.messages = data[this.product];
      })
  }

  ngOnInit() {
  }

  scroll(src){
    document.getElementById(src).scrollIntoView({behavior:"smooth", block: "start"});
    /* section-sim */
  }

}
