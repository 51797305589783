<section>
  <owl-carousel [options]="options" [items]="items" [carouselClasses]="['owl-theme', 'sliding']">
      <div class="item" *ngFor="let items of items; let i = index" [routerLink]="[items?.src]" (click)="sendEvent('Intencion_registro_negocios', {'Nombre_producto':items?.id_clevertap})">
        <div class="content-item" >
          <h3>{{items?.title}}</h3>
          <div>
            <img src="{{items?.img}}" alt="{{items?.title}}">
          </div>
          <p>{{items?.body}}</p>

          <div class="hover-color"></div>
        </div>
      </div>
  </owl-carousel>
</section>
