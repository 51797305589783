/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./styles/shapes.component.scss.shim.ngstyle";
import * as i1 from "./styles/shapes.media.576.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/router";
import * as i4 from "../../../../../../node_modules/ngx-owl-carousel/src/owl-carousel.component.ngfactory";
import * as i5 from "ngx-owl-carousel/src/owl-carousel.component";
import * as i6 from "@angular/common";
import * as i7 from "./shapes.component";
import * as i8 from "../../../../services/providers/json.provider";
import * as i9 from "../../../../services/providers/clevertap.service";
var styles_ShapesComponent = [i0.styles, i1.styles];
var RenderType_ShapesComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_ShapesComponent, data: {} });
export { RenderType_ShapesComponent as RenderType_ShapesComponent };
function View_ShapesComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 10, "div", [["class", "item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i2.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.sendEvent("Intencion_registro_negocios", { "Nombre_producto": ((_v.context.$implicit == null) ? null : _v.context.$implicit.id_clevertap) }) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i2.ɵdid(1, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i2.Renderer2, i2.ElementRef], { routerLink: [0, "routerLink"] }, null), i2.ɵpad(2, 1), (_l()(), i2.ɵeld(3, 0, null, null, 7, "div", [["class", "content-item"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i2.ɵted(5, null, ["", ""])), (_l()(), i2.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i2.ɵted(9, null, ["", ""])), (_l()(), i2.ɵeld(10, 0, null, null, 0, "div", [["class", "hover-color"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, ((_v.context.$implicit == null) ? null : _v.context.$implicit.src)); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.title); _ck(_v, 5, 0, currVal_1); var currVal_2 = i2.ɵinlineInterpolate(1, "", ((_v.context.$implicit == null) ? null : _v.context.$implicit.img), ""); var currVal_3 = i2.ɵinlineInterpolate(1, "", ((_v.context.$implicit == null) ? null : _v.context.$implicit.title), ""); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.body); _ck(_v, 9, 0, currVal_4); }); }
export function View_ShapesComponent_0(_l) { return i2.ɵvid(0, [i2.ɵqud(402653184, 1, { owlElement: 0 }), (_l()(), i2.ɵeld(1, 0, null, null, 5, "section", [], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 4, "owl-carousel", [], null, null, null, i4.View_OwlCarousel_0, i4.RenderType_OwlCarousel)), i2.ɵdid(3, 311296, null, 0, i5.OwlCarousel, [i2.IterableDiffers], { carouselClasses: [0, "carouselClasses"], options: [1, "options"], items: [2, "items"] }, null), i2.ɵpad(4, 2), (_l()(), i2.ɵand(16777216, null, 0, 1, null, View_ShapesComponent_1)), i2.ɵdid(6, 278528, null, 0, i6.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, "owl-theme", "sliding"); var currVal_1 = _co.options; var currVal_2 = _co.items; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.items; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_ShapesComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-shapes", [], null, null, null, View_ShapesComponent_0, RenderType_ShapesComponent)), i2.ɵdid(1, 114688, null, 0, i7.ShapesComponent, [i8.JsonProvider, i9.clevertapService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShapesComponentNgFactory = i2.ɵccf("app-shapes", i7.ShapesComponent, View_ShapesComponent_Host_0, {}, {}, []);
export { ShapesComponentNgFactory as ShapesComponentNgFactory };
