import { Component, OnInit, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule, FormArray } from '@angular/forms';
import { ObservablesService } from '../../services/providers/observables.service';
import { JsonProvider } from '../../services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-component',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  eventModalClick: any;
  modelo: any;
  closeResult: string;
  form: any;
  second: any = 30;
  
  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) {
    
  }

  openURL(url: string) {
    window.open(url);
  }

  clickButton(boton) {
    if(!!this.modelo && this.modelo.extraData.typeModal === 'modalOtp' && boton == 'Pedir otro codigo'){
      this.second = 30;
      this.secondCode();
    }else{
       this.activeModal.close('Close click');
    }
     
    let data = {
      dataModal: this.form.value,
      extraData: this.modelo.extraData
    }
    var evento = new EventModalClick(data, boton);

    if (!this.eventModalClick) {
      /* console.warn("Modal informativo"); */
    } else {
      this.eventModalClick(evento);
    }

    
  }

  ngOnInit() {

    if(!!this.modelo && this.modelo.extraData.typeModal === 'modalOtp') {
      this.modelo.textos[0].text = this.modelo.textos[0].text + `<b> ${this.modelo.extraData.form.phoneNumber}</b>`;
      this.secondCode();
    }

    if(!!this.modelo.extraData && !!this.modelo.extraData.type){
      if (this.modelo.extraData.typeModal === 'modalUpdateProfile') {
        const textos = this.modelo.textos;
        this.modelo.textos[0].text = this.replaceTextWithURL(textos);
      } else {
        let res = "<span>"+ this.modelo.extraData.type + "</span>";
        let newBody = this.modelo.textos[0].text.replace(/<span>.*<\/span>/, res);
        this.modelo.textos[0].text = newBody;
      }
    }

    document.querySelector('.modal-content').setAttribute("class", "modal-nequi-content");
    let floorElements = document.getElementsByClassName("modal-nequi-content") as HTMLCollectionOf<HTMLElement>;
    for (let index = 0; index < floorElements.length; index++) {
      floorElements.item(index).style.pointerEvents = "all";
    }

    this.form = this.formBuilder.group({});
    if (this.modelo.inputs) {
      this.modelo.inputs.map((value) => {
        this.form.addControl(value.name, new FormControl(''));
        this.form.get(value.name).setValidators(this.createValidators(value));
        this.form.get(value.name).updateValueAndValidity();
      });
    }

  }

  private replaceTextWithURL(textos: any[]) {
    let body = ''
    textos.forEach((texto) => {
      body+= this.replace(texto) + '<br>';
    })
    return body;
  }

  private replace(texto) {
    if (texto && texto.url) {
      return texto.text.replace('{0}', texto.url);
    }
  }

  secondCode(){
    let second = setInterval(()=>{
      if(this.second > 0)
        if(this.second > 10){
          this.second = this.second - 1
        }else{
          this.second = this.second - 1
          this.second = `0${this.second}`;
        }
    }, 1000);
  }

  createValidators(element) {/*Create array of validators, depending on the element sent*/
    let validator = [];
    if ('required' in element && !!element.required) {
      validator.push(Validators.required);
    }
    if ("email" == element.type) {
      let regex = /^(([^<>()[\]\.,;:\s@\']+(\.[^<>()[\]\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
      validator.push(Validators.pattern(regex));
    }
    if ('max' in element) {
      validator.push(Validators.maxLength(element.max));
    }
    if ('min' in element) {
      validator.push(Validators.minLength(element.min));
    }
    if ('pattern' in element) {
      let regex = element.pattern;
      validator.push(Validators.pattern(element.pattern));
    }
    return validator;
  }

  isTextArea(input) {
    return input.type == "textarea";
  }

}

export class EventModalClick {
  modelo: any;
  source: any;
  constructor(modelo: any, source: any) {
    this.modelo = modelo;
    this.source = source;
  }

}
