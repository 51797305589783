<section>
  <div class="" *ngIf="messages && forms && !registerSuccess && !registerSuccessConfirmation">

    <div class="tabs">
      <div class="tab" *ngFor="let tab of messages; let iTab = index" [ngClass]="{
                        'hover-tab' : tabShow === iTab || (register_type === 'qr' && tabShow + 1 === iTab && iTab > 2),
                        'noneTab': iTab === 3 && register_type === 'qr'
                      }">
        <div class="img" *ngIf="(forms[iTab].valid && iTab != 4)"></div>
        <div class="img" *ngIf="(iTab > 2 && register_type === 'qr' && forms[3].valid && forms[4].valid )"></div>
        <span>{{tab.name}}</span>
      </div>
    </div>

    <div class="sectionForms" *ngFor="let form of messages; let i = index">
      <form [formGroup]="forms[i]" *ngIf="tabShow === i" class="content-tabs">
        <div *ngFor="let input of form.inputs" class="{{input.name}} input-container "
          [ngClass]="{'help-container': input?.help || input?.toast, 'toast-container': input?.toast}">
          <!-- Separadores o titulos -->
          <section *ngIf="input.display != false">

            <div class="body-form" *ngIf="input.type == 'html' " [innerHtml]="input.body"></div>

            <div *ngIf="input.type !== 'html'" class="">
              <!-- INPUT -->
              <input *ngIf="
                (input.type=='text'
                || input.type=='password'
                || input.type=='number'
                || input.type=='email')
                " [formControlName]="input.name" [id]="input.name"
                [ngClass]="{'bad-border': forms[i].controls[input.name].invalid && forms[i].controls[input.name].touched}"
                class=" input-style " [type]="input.type" [value]="forms[i].value[input.name]"
                [placeholder]="input.label" pattern="{{input.type == 'number' ? '\\d*':''}}">

              <!-- AUTOCOMPLETE -->

              <select *ngIf="
                (input.type=='autocomplete')
                " class="input-style" [formControlName]="input.name" (change)="eventLocation($event)" [id]="input.name"
                [ngClass]="{'bad-border': forms[i].controls[input.name].invalid && forms[i].controls[input.name].touched}"
                [value]="forms[i].value[input.name]">
                <option [value]="" [selected]="dataFlow.location[input.name]" disabled>{{!!forms[i].value[input.name] ?
                  forms[i].value[input.name] : input.label}}</option>
                <option *ngFor="let option of dataFlow.location[input.name]"
                  [selected]="forms[i].value[input.name] === option.name" [value]="option.name">
                  {{option.name}}
                </option>
              </select>
              <!-- TIME -->

              <input *ngIf="(input.type=='time')" [dropSpecialCharacters]="false" mask="00:00"
                [formControlName]="input.name"
                [ngClass]="{'bad-border': forms[i].controls[input.name].invalid && forms[i].controls[input.name].touched}"
                class=" input-style " type="text" [value]="forms[i].value[input.name]" [placeholder]="input.label">


              <!-- <ngb-timepicker *ngIf="input.type=='time'" [formControlName]="input.name" meridian="true" size="small" [spinners]="false"></ngb-timepicker>
 -->
              <!-- DATE -->
              <div class="input-group" *ngIf="input.type=='date'">
                <input class="form-control " [id]="input.name" [placeholder]="input.label" name="dp"
                  [ngClass]="{'bad-border': forms[i].controls[input.name].invalid && forms[i].controls[input.name].touched}"
                  [formControlName]="input.name" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-light" (click)="d.toggle()" type="button">
                    <img src="assets/img/calendar-icon.png" style="width: 1.5rem; height: 1.5rem; cursor: pointer;" />
                  </button>
                </div>
              </div>
              <!-- SELECT -->
              <select *ngIf="(input.type=='select')" [id]="input.name" class="input-style"
                [formControlName]="input.name"
                [ngClass]="{'bad-border': forms[i].controls[input.name].invalid && forms[i].controls[input.name].touched}"
                [value]="forms[i].value[input.name]">
                <option [value]="" disabled>{{input.label}}</option>
                <option *ngFor="let option of input.options" [value]="option.value">{{option.label}}</option>
              </select>
              <!-- RADIO  -->
              <h3 *ngIf="(input.type=='radio')
              " [innerHtml]="input.label"></h3>
              <div class="{{input.type}}-container" *ngIf="(input.type=='radio') ">
                <div class="content-radio" *ngFor="let option of input.options">
                  <input [type]="input.type" id="{{input.name}}-{{option.value}}" class="input-radio "
                    [ngClass]="{'bad-border': forms[i].controls[input.name].invalid && forms[i].controls[input.name].touched, 'css-checkbox': input.type === 'checkbox'}"
                    [formControlName]="input.name" [checked]="forms[i].value[input.name] === option.value"
                    [name]="input.name" [value]="option.value"
                    (change)="onRadioChange(forms[i],input.name, option.value)">
                  <label for="{{input.name}}-{{option.value}}" [ngClass]="{'css-label': input.type === 'checkbox' }">
                    <span [innerHtml]="option.label"></span></label>
                </div>
              </div>
              <!-- CHECK GROUP-->
              <label *ngIf="input.type=='checkbox' " [innerHtml]="input.label"></label>
              <div class="checkbox-container style-x-{{input.name}}" *ngIf="(input.type=='checkbox')">
                <div *ngFor="let option of input.options">

                  <div *ngIf="!option.pdf">
                    <input id="{{input.name}}-{{option.value}}" type="checkbox" class="css-checkbox" [name]="input.name"
                      [value]="option.value" [checked]="option.checked"
                      (change)="onCheckChange($event,forms[i],input.name); option.checked = !option.checked">
                    <label class="css-label" for="{{input.name}}-{{option.value}}" [innerHtml]="option.label"></label>
                  </div>

                  <div *ngIf="option.pdf" [ngClass]="{ hidden: input.hidden }">
                    <input id="{{input.name}}-{{option.value}}" type="checkbox" class="css-checkbox" [name]="input.name"
                      [value]="option.value" [checked]="option.checked"
                      (change)="onCheckChange($event,forms[i],input.name,{'option': option, 'content': content}); option.checked = !option.checked">
                    <label class="css-label" for="{{input.name}}-{{option.value}}" [innerHtml]="option.label"></label>
                  </div>

                </div>
              </div>

              <!-- tipo help -->

              <div *ngIf="input?.help" class="help" placement="left" [ngbTooltip]="input.help">
                <img src="assets/img/iconHelp.png" alt="">
              </div>

              <div *ngIf="input?.toast" class="help " [ngClass]="{'toastRegister': !!input?.toast.view }"
                (click)="input.toast.view = !input.toast.view">
                <img src="assets/img/iconHelp.png" alt="">
              </div>

              <div *ngIf="
                (input.type=='text'
                || input.type=='password'
                || input.type=='number'
                || input.type=='email'
                || input.type=='autocomplete') && input.required" class="pushError"
                [ngClass]="{'pushErrorShow': forms[i].controls[input.name].invalid && forms[i].controls[input.name].touched}">
                <span>{{input.error}}</span>
              </div>

              <div *ngIf="input?.toast && !!input?.toast.view" class="toast-msg">
                {{input?.toast.body}}
              </div>

            </div>
          </section>
          <!-- <div>{{forms[i].value[input.name] | json}}</div> -->
        </div>
        <re-captcha size="invisible" (resolved)="$event && onSubmit(forms[i], i, $event)"
          [formControlName]="'recaptchaReactive'+i" #captchaRef="reCaptcha" class="reCaptcha"></re-captcha>
        <div class="control-btns">
          <button *ngIf="i!=0" type="button" (click)="backTab()" class="blue-border-button left-btn">Anterior</button>
          <button type="submit" (click)="form.hasCaptcha ? captchaRef.execute() : onSubmit(forms[i], i)"
            [disabled]="forms[i].invalid || btnNextDisabled" class="green-button rigth-btn">Siguiente</button>
        </div>

        <button type="button" *ngIf="tabShow == 0" class="body-form green-border-button ya-soy-nequi"
          (click)="eventSoyNequi()">Ya soy un negocio Nequi</button>
      </form>
    </div>

  </div>

  <div class="welcome-register" *ngIf="registerSuccess">
    <div class="welcome-container">
      <div class="welcome-animation">
        <img class="img-pig tossing" src="/assets/img/pig.png">
        <img class="img-wing tossing" src="/assets/img/wing.png">
      </div>
      <div class="welcome-content">
        <h1>{{welcomeMessages?.title}}</h1>
        <p [innerHtml]="welcomeMessages?.body[register_type]"></p>
        <div class="container-buttons">
          <button class="white-border-button right-button"
            [routerLink]="[welcomeMessages?.button2.src]">{{welcomeMessages?.button2.title}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="welcome-register" *ngIf="registerSuccessConfirmation">
    <div class="welcome-container">
      <div class="welcome-animation">
        <img class="img-pig tossing" src="">
      </div>
      <div class="welcome-content  welcome-content-confirmation">
        <h1><span>{{welcomeMessagesConfirmation?.title}}</span></h1>
        <p [innerHtml]="welcomeMessagesConfirmation?.body.text"></p>
      </div>
    </div>
  </div>

  <!-- <ng-template #content let-c="close" let-d="dismiss" class="modalTyc">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <img class="spinnerIframe" src="/assets/img/spinner.gif" alt="">

      <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(dataPdf)" width="100%" height="100%" webkitallowfullscreen mozallowfullscreen
        allowfullscreen frameborder="0" referrerpolicy="no-referrer-when-downgrade">
      </iframe>
    </div>
  </ng-template> -->

</section>
