<div class="container-footer">
  <div class="inner-footer">
      <div class="first-block">
          <div class="logo-footer">
              <img src="/assets/img/nequi-logo-footer.svg" />
          </div>
      </div>
      <div class="second-block">
          <img src="assets/img/logo-vigilado.svg" class="logo-vigilado" />
      </div>
  </div>
</div>