import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import {  JsonProvider,
          UtilsService,
          AuthService,
          ObservablesService,
          GoogleAnalyticsService } from './services';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './public/dashboard/dashboard.component';
import { MatGridListModule, MatCardModule, MatMenuModule, MatIconModule, MatButtonModule } from '@angular/material';
import { FooterComponent } from './components/footer/footer.component';
import { IconosInfoComponent } from './components/iconos-info/iconos-info.component';
import { ShapesComponent } from './public/dashboard/components/shapes/shapes.component';
import { OwlModule } from 'ngx-owl-carousel';
import { CompareShapesComponent } from './public/dashboard/components/compare-shapes/compare-shapes.component';
import { CobrosComponent } from './public/cobros/cobros.component';
import { ModalComponent } from './components/modal/modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BotonComponent } from './public/boton/boton.component';
import { OptionsComponent } from './public/boton/components/options/options.component';
import { CarouselCobrosComponent } from './public/cobros/components/carousel-cobros/carousel-cobros.component';
import { BannerLandingsComponent } from './components/banner-landings/banner-landings.component';
import { DetalleApiComponent } from './public/boton/detalle-api/detalle-api.component';
import { RegistroComponent } from './public/registro/registro.component';
import { StickerComponent } from './public/sticker/sticker.component';
import { CurrencyMaskModule } from "ngx-currency-mask";
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ngx-currency-mask/src/currency-mask.config";
import { SimulatorComponent } from './components/simulator/simulator.component';
import { NgxMaskModule } from 'ngx-mask';
import { LoginComponent } from './public/login/login.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { ForgetPassComponent } from './public/forget-pass/forget-pass.component';
import { SafePipe } from './components/safe-pipe/safe-pipe';
import { ChangePasswordComponent } from './private/change-password/change-password.component';
import { ArmarioComponent } from './public/armario/armario.component';
import { SpinnerComponent } from './components/spinner/spinner.component';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 0,
  prefix: "$ ",
  suffix: "",
  thousands: ","
};

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    OwlModule,
    CurrencyMaskModule,
    NgbModule.forRoot(),
    NgxMaskModule.forRoot(),
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    FooterComponent,
    IconosInfoComponent,
    ShapesComponent,
    CompareShapesComponent,
    CobrosComponent,
    ModalComponent,
    BotonComponent,
    OptionsComponent,
    CarouselCobrosComponent,
    BannerLandingsComponent,
    DetalleApiComponent,
    RegistroComponent,
    StickerComponent,
    SimulatorComponent,
    LoginComponent,
    ForgetPassComponent,
    SafePipe,
    ChangePasswordComponent,
    ArmarioComponent,
    SpinnerComponent
  ],
  providers: [
    JsonProvider,
    UtilsService,
    AuthService,
    ObservablesService,
    GoogleAnalyticsService,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
  ],
  exports: [
    ReactiveFormsModule
  ],
  entryComponents: [
    ModalComponent,
    ChangePasswordComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
