/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./armario.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./armario.component";
import * as i3 from "../../services/providers/json.provider";
import * as i4 from "@angular/router";
var styles_ArmarioComponent = [i0.styles];
var RenderType_ArmarioComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ArmarioComponent, data: {} });
export { RenderType_ArmarioComponent as RenderType_ArmarioComponent };
export function View_ArmarioComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "contain-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "font-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "green-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.eventClick(((_co.messages == null) ? null : _co.messages.button.src)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.eventClick(((_co.messages == null) ? null : _co.messages.link.href)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.messages == null) ? null : _co.messages.title); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.messages == null) ? null : _co.messages.body); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.messages == null) ? null : _co.messages.button.text); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.messages == null) ? null : _co.messages.link.text); _ck(_v, 8, 0, currVal_3); }); }
export function View_ArmarioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-armario", [], null, null, null, View_ArmarioComponent_0, RenderType_ArmarioComponent)), i1.ɵdid(1, 114688, null, 0, i2.ArmarioComponent, [i3.JsonProvider, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ArmarioComponentNgFactory = i1.ɵccf("app-armario", i2.ArmarioComponent, View_ArmarioComponent_Host_0, {}, {}, []);
export { ArmarioComponentNgFactory as ArmarioComponentNgFactory };
