<section>

  <div class="form" *ngIf="form">
    <div class="title">
      <h1 class="font-bold">
        {{dataLogin?.title.text}}
      </h1>
    </div>
    <form [formGroup]="form" (keyup.enter)="captchaRef.execute()">
      <input formControlName="email" [ngClass]="{'bad-border': form.controls.email.invalid && form.controls.email.touched}" class=" input-style "
        type="email" [placeholder]="dataLogin?.form.email">
      <br>

      <input formControlName="password" [ngClass]="{'bad-border': form.controls.password.invalid && form.controls.password.touched}"
        class=" input-style " type="password" [placeholder]="dataLogin?.form.pass">
      <div class="captcha">
        <re-captcha size="invisible" (resolved)="$event && onSubmit($event)" formControlName="recaptchaReactive" #captchaRef="reCaptcha" class="reCaptcha"></re-captcha>
      </div>


      <button type="submit" (click)="captchaRef.execute()" [disabled]="form.invalid" class="green-button"> {{dataLogin?.button}} </button>

      <p><a href="{{dataLogin?.forget.src}}">{{dataLogin?.forget.text}}</a></p>

      <p><a href="{{dataLogin?.payment.src}}">{{dataLogin?.payment.text}}</a></p>


    </form>
  </div>
</section>