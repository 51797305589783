<div class="header-container" *ngIf="messages">
    <div class="row landing-header container">
        <div class="col-xs-10 col-sm-10 col-md-2">
            <div class="logo">
                <a routerLink="/">
                    <img alt="nequi" src="/assets/img/nequi-logo.svg" height="34" width="131">
                </a>
            </div>
        </div>
        <div class="menu col-md-10">
            <div class="row" *ngIf="showHeader">
                <div class="col-md-9">
                    <nav class="menu-navigation">
                        <ul>
                            <li>
                                <a href="{{messages.opc1.src}}">{{messages.opc1.title}}</a>
                            </li>

                            <li>
                                <a (click)="eventClick(messages.opc5.title)" href="{{messages.opc5.src}}">{{messages.opc5.title}}</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="mobile-menu col-xs-12 col-sm-7 col-md-5" [ngClass]="{'menu-open': isOpen}">
            <nav class="menu-navigation">
                <ul>
                    <a href="{{messages.opc1.src}}">
                        <li>{{messages.opc1.title}}</li>
                    </a>
                    <a href="{{messages.opc5.src}}">
                        <li>{{messages.opc5.title}}</li>
                    </a>
                    <a (click)="changePassword()" *ngIf="this.observablesService.getCookie('parameters') && this.observablesService.getLocalStorage('currentUser')">
                        <li>{{messages.opc6.title}}</li>
                    </a>
                    <a [routerLink]="['']" (click)="exit()" *ngIf="this.observablesService.getCookie('parameters') && this.observablesService.getLocalStorage('currentUser')">
                        <li>{{messages.opc7.title}}</li>
                    </a>
                </ul>
            </nav>
        </div>
        <div class="btn-menu-hamburguer">
            <button class="hamburger hamburger--elastic" [ngClass]="{'is-active': isOpen, 'open-menu': isOpen}" type="button" (click)="toogleMenu()">
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
    </div>
</div>
