import { Component, OnInit} from '@angular/core';
import { JsonProvider, ObservablesService, GoogleAnalyticsService, UtilsService } from '../../services';
import { EventModalClick } from '../../components/modal/modal.component';
import { NgbModal, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from '../../private/change-password/change-password.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [
    './styles/header.component.scss',
    './styles/header.media.990.scss', 
    './styles/header.media.767.scss'    
  ]
})
export class HeaderComponent implements OnInit {

  public messages: any;
  public isOpen: boolean;
  public url: string;
  public closeResult:any;
  public login: boolean;
  public showHeader: boolean = true;

  constructor(private jsonProvider: JsonProvider, 
              public observablesService: ObservablesService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private modalService: NgbModal,
              private utilsService: UtilsService,
              private router: Router) { 
                
    this.jsonProvider.getMessages('header').subscribe(data => this.messages = data);
    this.observablesService.logoGoTo$.subscribe(backTo => {
      this.url = backTo;
    });

    this.router.events.subscribe((event: any) => {
      window.scrollTo(0, 0);
      if(event.url == '/armario' || event.url == '/registro/armario')
        this.showHeader = false;
    });
  }

  ngOnInit() {
    if(!this.url) {
      this.url = '/';
    }

    if(this.observablesService.getCookie('parameters') && this.observablesService.getLocalStorage('currentUser')){
      this.login = true;
    }else{
      this.login = false;
    }
    
  }
  
  toogleMenu(){
    this.isOpen = !this.isOpen;
  }

  modalClick(evento:EventModalClick){
  }

  eventClick(text){
    let action = 'Clic ' + text.toLowerCase();
    this.googleAnalyticsService.emitEvent('Home', action);
  }
  
  open(){   
    this.observablesService.showModal(
      { modelo:'modalOtp', 
        clicFunc:this.modalClick
      }
    );
    /* this.jsonProvider.getMessages('modalOtp').subscribe(data =>   this.ObservablesService.showModal({modelo:data,clicFunc:this.modalClick}));    */
  }
  open2(){   
    this.observablesService.showModal({modelo:'modalErroCifin',clicFunc:this.modalClick})
    /* this.jsonProvider.getMessages('modalErroCifin').subscribe(data =>   this.ObservablesService.showModal({modelo:data,clicFunc:this.modalClick}));    */
  }


  exit() {
    this.utilsService.logout();
  }

  changePassword(){
    this.modalService.open(ChangePasswordComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'modal-change-password' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
