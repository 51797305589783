import { Component, ViewEncapsulation } from '@angular/core';
import { ObservablesService } from './services';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from './services'
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public welcomeMessages: any;
  public showSpinner: boolean = false;
  public showHeader: boolean = true;
  /* @ViewChild('widgetParentDiv') parentDiv:ElementRef;

  @HostListener('window:resize') onResize() {
    // guard against resize before view is rendered
    if(this.parentDiv) {
       this.showSpinner = false;
    }
  }
 */
  

  constructor(
    private router: Router,
    private observablesService: ObservablesService,
    private googleAnalyticsService: GoogleAnalyticsService) {


    /* navigator.serviceWorker.getRegistrations(); */

    this.router.events.subscribe((event: any) => {
      window.scrollTo(0, 0);
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.emitPage(event.urlAfterRedirects);
      }
    });

    this.observablesService.observableSpinner$.subscribe((spinner) => {
      setTimeout(() => this.showSpinner = spinner, 0);      
    });

    this.appendGaTrackingCode();

  }

  private appendGaTrackingCode() {
    try {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', '` + environment.googleAnalyticsKey + `', 'auto');
      `;
      document.head.appendChild(script);
    } catch (ex) {
      //console.error('Error appending google analytics');
      //console.error(ex);
    }
  }

}
