<div class="landing-iconos-info container product-{{product}}" *ngIf="messages" >
  <div class="row">
      <div class="col-xs-12 col-sm-12">
        <div class="icon-info-container" *ngFor="let info of messages">
          <img class="icon-info-image" src="{{info.image}}" alt="Imagen alusiva al paso {{info.title}}"/>
          <div class="icon-info-title">{{info.title}}</div>
          <div class="icon-info-body" [innerHTML]="info.body"></div>
          <a *ngIf="0 != info.hasLink" (click)="scroll(info.link)" class="icon-info-link" >{{info.lintText}}</a>
        </div>
      </div>
  </div>
</div>