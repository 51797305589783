import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { ObservablesService } from '../providers/observables.service';
import { UtilsService } from '../providers/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  constructor(private router: Router, private observablesService: ObservablesService, private utilsService: UtilsService ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {  
    
    if (this.observablesService.getLocalStorage('currentUser') && this.observablesService.getCookie('parameters')) {
        // logged in so return true
        await this.utilsService.keyLoginInterval();
        return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
          
  }
}
