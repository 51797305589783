import { Component, OnInit } from '@angular/core';
import { JsonProvider } from '../../../../services';


@Component({
  selector: 'app-compare-shapes',
  templateUrl: './compare-shapes.component.html',
  styleUrls: [
              './styles/compare-shapes.component.scss',
              './styles/compare-shapes.media.690.scss',
              './styles/compare-shapes.media.576.scss'
            ]
})
export class CompareShapesComponent implements OnInit {

  public items: any;
  public optionActive: any;

  constructor(private jsonProvider: JsonProvider) { 

    this.jsonProvider.getMessages('compare-shapes').subscribe((data) => {this.items = data;});
  }

  ngOnInit() {
  }

  toggle(i){
    if(this.optionActive === i) {
      this.optionActive = -1 
    }else{
      this.optionActive = i
    }
  }

}
