/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./spinner.component";
var styles_SpinnerComponent = [i0.styles];
var RenderType_SpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpinnerComponent, data: {} });
export { RenderType_SpinnerComponent as RenderType_SpinnerComponent };
export function View_SpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, ":svg:svg", [["class", "loading__spinner"], ["fill", "none"], ["height", "30"], ["viewBox", "0 0 59 54"], ["width", "30"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:path", [["class", "layer-down"], ["d", "M27.2746 25H4.72539C2.11563 25 0 27.1301 0 29.7578V49.0393C0 51.667 2.11563 53.7971 4.72539 53.7971H27.2746C29.8844 53.7971 32 51.667 32 49.0393V29.7578C32 27.1301 29.8844 25 27.2746 25Z"], ["fill", "#5A9EFB"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:path", [["class", "layer-up"], ["d", "M54.2746 1H31.7254C29.1156 1 27 3.13013 27 5.75778V25.0393C27 27.667 29.1156 29.7971 31.7254 29.7971H54.2746C56.8844 29.7971 59 27.667 59 25.0393V5.75778C59 3.13013 56.8844 1 54.2746 1Z"], ["fill", "#5A9EFB"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "loading__feedback"]], null, null, null, null, null))], null, null); }
export function View_SpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [], null, null, null, View_SpinnerComponent_0, RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i2.SpinnerComponent, [], null, null)], null, null); }
var SpinnerComponentNgFactory = i1.ɵccf("app-spinner", i2.SpinnerComponent, View_SpinnerComponent_Host_0, {}, {}, []);
export { SpinnerComponentNgFactory as SpinnerComponentNgFactory };
