import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { JsonProvider, AuthService, UtilsService, ObservablesService } from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { EventModalClick } from '../../components/modal/modal.component';
import { NgbDatepickerI18n, NgbDateStruct, NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-forget-pass',
  templateUrl: './forget-pass.component.html',
  styleUrls: ['./forget-pass.component.scss'],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.googleCaptchaKey,
      } as RecaptchaSettings,
    }
  ]
})

export class ForgetPassComponent implements OnInit {

  resolved(captchaResponse: string) {
  }

  public form: FormGroup;
  public formEmail: FormGroup;
  public sendService: boolean = false;


  public dataLogin: any;
  public submitted = false;
  public returnUrl: string;
  public dataFlow = {
    emailExist: false,
    success: false,
    emailNotExist: true
  }

  constructor(
    private jsonProvider: JsonProvider,
    private formBuilder: FormBuilder,
    private requestAuth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private observablesService: ObservablesService
  ) {

    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      this.router.navigate(['/']);
    }

    this.jsonProvider.getMessages('forgetPass').subscribe(data => this.dataLogin = data);
  }

  async ngOnInit() {


    await this.utilsService.getParams();

    let data: any = this.observablesService.getLocalStorage('parameters');

    this.utilsService.setCognitoTemporalKeys(data.getParametersRS).subscribe((creds: any) => {
      this.utilsService.createNewClient({
        accessKey: creds.accessKeyId,
        secretKey: creds.secretAccessKey,
        sessionToken: creds.sessionToken,
        apiKey: data.getParametersRS.apiKey
      });

    }, (error) => {
    });

    this.formEmail = this.formBuilder.group({
      email: [
        '',
        [Validators.required, Validators.email]
      ],
      recaptchaReactive: []
    });

    this.form = this.formBuilder.group({
      code: [
        '',
        [Validators.required]
      ],
      password: [
        '',
        [Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}')]
      ],
      passwordConfirm: [
        '',
        [Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}')]
      ],
      recaptchaReactive: []
    });

    // reset login status

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }

  onSubmitEmail(captchaResponse: string) {
    if (this.formEmail.valid) {
      let body = {
        Channel: "NegociosNequi",
        ClientID: "3999999999",
        Destination: {
          "ServiceName": "PartnerService",
          "ServiceOperation": "forgotPassword",
          "ServiceRegion": "C001",
          "ServiceVersion": "1.0.0"
        },
        Body: {
          "forgotPasswordRQ": {
            "email": this.formEmail.value.email,
            "captchaResponse": this.formEmail.value.recaptchaReactive
          }
        }
      }


      this.utilsService.consumeEvent('servicesUserservicesForgotpasswordPost', body).subscribe((data) => {

        if (!data.error) {
          this.dataFlow.emailExist = true;
        } else {
          this.formEmail.reset();
          if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode == "G-07A") {
            this.showModalInfo('CaptchaError');
          } else {
            this.showModalInfo('ErrorTecnico');
          }
        }
      })
    }
  }

  async onSubmit(captchaResponse: string) {
    this.sendService = true;
    if (this.form.valid) {
      let body = {
        Channel: "NegociosNequi",
        ClientID: "3999999999",
        Destination: {
          "ServiceName": "PartnerService",
          "ServiceOperation": "resetPassword",
          "ServiceRegion": "C001",
          "ServiceVersion": "1.0.0"
        },
        Body: {
          "resetPasswordRQ": {
            "email": this.formEmail.value.email,
            "confirmationCode": this.form.value.code,
            "newPassword": await this.utilsService.encryptString(this.form.value.password),
            "captchaResponse": this.form.value.recaptchaReactive
          }
        }
      }


      this.utilsService.consumeEvent('servicesUserservicesResetpasswordPost', body).subscribe((data) => {
        console.log("TCL: ForgetPassComponent -> onSubmit -> data", data)
        if (!data.error) {
          this.dataFlow.success = true;
          this.sendService = false;
        } else {
          this.sendService = false;
          this.form.reset();
          if (data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode == "G-07A") {
            this.showModalInfo('CaptchaError');
          } else {
            this.showModalInfo('modalOtpErrorForgetPass');
          }
        }
      })
    } else {
      this.sendService = false;
    }
  }

  /**
   * modal de solo informacion
   * @param modelo
   */
  showModalInfo(modelo) {
    this.observablesService.showModal(
      {
        modelo,
        extraData: {
        },
      }
    );
  }

}
