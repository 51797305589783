import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http'
@Injectable()
export class JsonProvider {
    /**
     * @description Variable que guarda los mensajes de la aplicación
     *              para ser usados de manera transversal
     */
    public messages: any;

    public urlJson = 'assets/resources/messages.json';

    constructor(
        public http: HttpClient
    ) { }
    /**
     * @description Método que hace la petición para obtener los 
     *              mensajes de la aplicación
     * @method getMessages
     */
    public getMessages(section): any {
        if(section != ''){
            return this.http.get(this.urlJson)
            .pipe(
                map(data => data[section])                                 
            );
        }else{
            return this.http.get(this.urlJson)
        }           
    }
}