import { apigClientPartners } from "./client/apigClientPartners.prod";
import { apigClientDispersions } from "./clientDispersions/apigClientDispersions.prod";

export const environment = {
  production: true,
  googleAnalyticsKey: "UA-81490020-4",
  apigClientPartners: apigClientPartners,
  apigClientDispersions: apigClientDispersions,
  googleCaptchaKey: "6LfP8QIlAAAAADNUHknj4M0HpyeCV8GPOag8CUZS",
  clevertapId: "8W8-ZKR-695Z",
};
