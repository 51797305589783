import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../components/modal/modal.component';
import { JsonProvider } from './json.provider';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap/modal/modal";
import * as i2 from "./json.provider";
var ObservablesService = /** @class */ (function () {
    function ObservablesService(modalService, jsonProvider) {
        var _this = this;
        this.modalService = modalService;
        this.jsonProvider = jsonProvider;
        this.goTo = new Subject();
        this.conditions = new Subject();
        this.modals = new Subject();
        this.Sregistro = new Subject();
        this.spinner = new Subject();
        this.logoGoTo$ = this.goTo.asObservable();
        this.footerConditios$ = this.conditions.asObservable();
        this.showModal$ = this.modals.asObservable();
        this.observableRegistro$ = this.Sregistro.asObservable();
        this.observableSpinner$ = this.spinner.asObservable();
        this.showModal$.subscribe(function (model) {
            _this.showModal(model);
        });
    }
    ObservablesService.prototype.logoGoTo = function (item) {
        this.goTo.next(item);
    };
    ObservablesService.prototype.observableRegistro = function (item) {
        this.Sregistro.next(item);
    };
    ObservablesService.prototype.showSpinner = function (item) {
        this.spinner.next(item);
    };
    ObservablesService.prototype.footerConditios = function (item) {
        this.conditions.next(item);
    };
    ObservablesService.prototype.showModal = function (modal) {
        var _this = this;
        var options = {
            backdrop: 'static',
            centered: true
        };
        if (modal.extraData) {
            if (modal.extraData.type) {
                options.size = 'lg';
                options.windowClass = 'modal-size-lg';
            }
        }
        this.jsonProvider.getMessages(modal.modelo).subscribe(function (data) {
            var modalRef = _this.modalService.open(ModalComponent, options);
            modal.extraData.typeModal = modal.modelo;
            data.extraData = modal.extraData;
            modalRef.componentInstance.modelo = data;
            modalRef.componentInstance.eventModalClick = modal.clicFunc;
        });
    };
    ObservablesService.prototype.setLocalStorage = function (key, object) {
        var cadena = JSON.stringify(object);
        var keyBase64 = btoa(key);
        cadena = btoa(cadena);
        localStorage.setItem(keyBase64, cadena);
    };
    ObservablesService.prototype.getLocalStorage = function (key) {
        var keyBase64 = btoa(key);
        if (localStorage.getItem(keyBase64)) {
            var object = atob(localStorage.getItem(keyBase64));
            object = JSON.parse(object);
            return object;
        }
        else {
            return false;
        }
    };
    ObservablesService.prototype.removeItem = function (key) {
        var keyBase64 = btoa(key);
        localStorage.removeItem(keyBase64);
    };
    ObservablesService.prototype.getCookie = function (cname) {
        var name = btoa(cname) + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                var request = atob(c.substring(name.length, c.length));
                request = JSON.parse(request);
                return request;
            }
        }
        return "";
    };
    ObservablesService.prototype.setCookie = function (cname, cvalue, exHour, withDomain) {
        if (withDomain === void 0) { withDomain = false; }
        var d = new Date();
        var name = btoa(cname);
        var value = JSON.stringify(cvalue);
        value = btoa(value);
        d.setTime(d.getTime() + (exHour * 60 * 1000));
        var expires = "expires=" + d.toUTCString() + ";";
        var domain = '';
        if (withDomain) {
            var parts = location.hostname.split('.');
            var subdomain = parts.shift();
            var upperleveldomain = parts.join('.');
            var sndleveldomain = "";
            if (parts.length > 2) {
                sndleveldomain = parts.slice(-3).join('.');
            }
            else if (parts.length == 2) {
                sndleveldomain = parts.slice(-2).join('.');
            }
            if (sndleveldomain != "") {
                domain = "domain=." + sndleveldomain + ";";
            }
            else {
                domain = "domain=" + subdomain + ";";
            }
        }
        document.cookie = name + "=" + value + ";" + expires + domain + "path=/";
    };
    ObservablesService.ngInjectableDef = i0.defineInjectable({ factory: function ObservablesService_Factory() { return new ObservablesService(i0.inject(i1.NgbModal), i0.inject(i2.JsonProvider)); }, token: ObservablesService, providedIn: "root" });
    return ObservablesService;
}());
export { ObservablesService };
