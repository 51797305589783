import { Component, OnInit} from '@angular/core';
import { JsonProvider, GoogleAnalyticsService } from '../../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-detalle-api',
  templateUrl: './detalle-api.component.html',
  styleUrls: [
    './styles/detalle-api.component.scss',
    './styles/detalle-api.media.960.scss'
  ]
})
export class DetalleApiComponent implements OnInit {

  public messages: any;

  constructor(private JsonProvider: JsonProvider, 
              private router: Router,
              private googleAnalyticsService: GoogleAnalyticsService) {
    let product = localStorage.getItem("apiName");
    this.JsonProvider.getMessages('DetalleApis').subscribe(
      (data) => {
        this.messages = data[product];
        window.location.href = data.redirect
        this.eventClick();
      }
    )
   
  }

  ngOnInit() {
    
  }

  eventRegister(url){
    localStorage.setItem("registro", 'api');
    this.router.navigate([url]);
  }

  eventClick(){
    this.googleAnalyticsService.emitPage(this.messages.title.toLowerCase());
  }


}
