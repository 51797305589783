import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonProvider, UtilsService, ObservablesService } from '../../services'; 
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public form: FormGroup;
  public dataPerfil: any;

  constructor(private formBuilder: FormBuilder, 
              private jsonProvider: JsonProvider, 
              public activeModal: NgbActiveModal,
              public utilsService: UtilsService,
              public observablesService: ObservablesService) {
    this.jsonProvider.getMessages('changePassword').subscribe(data => { this.dataPerfil = data });
  }

  ngOnInit() {
    //console.log( this.dataPerfil);
    this.form = this.formBuilder.group({
      passwordActual: [
        '',
        [Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}')]
      ],
      password: [
        '',
        [Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}')]
      ],
      passwordConfirm: [
        '',
        [Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}')]
      ]
    });
  }

  async onSubmit(){
    let dataLogin: any = this.observablesService.getCookie('parameters');
    let oldPassword = await this.utilsService.encryptString(this.form.value.passwordActual);
    let newPassword = await this.utilsService.encryptString(this.form.value.password);
    if(this.form.valid){
      let body = {
        Channel: "NegociosNequi",
        ClientID: "3999999999",
        Destination: {
          "ServiceName": "PartnerService",
          "ServiceOperation": "changePassword",
          "ServiceRegion": "C001",
          "ServiceVersion": "1.0.0"
        },
        Body: {
          "changePasswordRQ": {
            "username": dataLogin.username,
            "oldPassword": oldPassword,
            "newPassword": newPassword,
            "accessToken":  dataLogin.accessToken
          }
        }
      }

      this.utilsService.consumeEvent('servicesUserservicesChangepasswordPost', body).subscribe((data)=>{
        if(!data.error){
          //console.log('TCL: ChangePasswordComponent -> asynconSubmit -> data', data);
          this.activeModal.close('Close click');
        }else{
          if(data.res.data.ResponseMessage.ResponseHeader.Status.StatusCode  === "20-09A"){
            this.showModalInfo('ErrorTecnico');
          }
        }
      })
    }
  }

  /**
   * modal de solo informacion
   * @param modelo 
   */
  showModalInfo(modelo) {
    this.observablesService.showModal(
      {
        modelo
      }
    );
  }


}
